import { get, post, vdbsApi } from '../utilities/apiUtilities';

export const getEmployee = (paymentCode: string) => get({
  url: `${vdbsApi}/pdirekt/employees/${paymentCode}`,
});

export const search = (query: any) => post({
  url: `${vdbsApi}/pdirekt/search`,
  data: query,
});

export const searchByPaymentId = (paymentId, query) => post({
  url: `${vdbsApi}/pdirekt/search/${paymentId}`,
  data: query,
});

export const sendPayments = (period) => post({
  url: `${vdbsApi}/pdirekt/send-payments/${period}`,
});

export const sendReimbursementPayments = (reimbursementPaymentId) => post({
  url: `${vdbsApi}/pdirekt/send-reimbursementpayments/${reimbursementPaymentId}`,
});

export const getPaymentProgress = (period) => get({
  url: `${vdbsApi}/pdirekt/send-payments/${period}/status`,
});

export const getReimbursementPaymentProgress = (reimbursementPaymentId) => get({
  url: `${vdbsApi}/pdirekt/send-reimbursementpayments/${reimbursementPaymentId}/status`,
});
