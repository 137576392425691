import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import entities from './entityReducer';
import { reducer as expression } from '../expressionbuilder';
import collection from './collectionReducer';
import authentication from './authenticationReducer';
import studio from './studioReducer';
import serviceWorker from './serviceWorkerReducer';
import service from './serviceReducer';
import listView from './listViewReducer';
import settings from './settingsReducer';

const reducers = combineReducers({
  authentication,
  collection,
  entities,
  expression,
  form,
  studio,
  serviceWorker,
  service,
  listView,
  settings
});

export default reducers;
