import { createSelector } from 'reselect';

export const getSelectedListViewId = (state, collection) => state.collection[collection].listViewId;

export const getListViews = (state) => state.listView.items;

export const getListViewsForCollection = createSelector(
  getListViews,
  (_state, collection) => collection,
  (listViews, collection) => listViews.filter((s) => s && s.screenType.toLowerCase() === collection)
    .filter((item, position, self) => self.map((i) => i.id).indexOf(item.id) === position)
);

const mapToOption = (listView) => ({
  value: listView.id,
  label: listView.name,
  listView,
});

export const getListViewOptions = createSelector(
  getListViewsForCollection,
  (listViews) => listViews.map((listView) => mapToOption(listView))
);

export const getSelectedListViewOption = createSelector(
  getSelectedListViewId,
  getListViewsForCollection,
  (id, listViews) => {
    const listView = listViews.find((s) => s.id === id);

    return (listView && mapToOption(listView)) || null;
  }
);
