import React from 'react'
import { message } from 'react-toastify-redux'
import { FormattedMessage } from 'react-intl'
import { post, repositoryApi, USER_TOKEN_KEY } from '../utilities/apiUtilities'
import { LOGIN_PENDING, LOGIN_SUCCESSFUL, LOGOUT, SET_USER } from '../actionTypes'
import { errorToast, handleError } from './toastActions'
import { getCurrentUser } from '../api/userApi'
import { ACTION_LOGIN } from '../constants/auth'
import { ROLE_ANONYMOUS } from '../constants/roles'
import { fetchModels } from './modelActions'

export const fetchUser = () => (dispatch) => getCurrentUser()
  .then(({ data }) => {
    // We fetch all models right after we received the current user successfully if the user isn't an anonymous candidate
    if (data.roles && data.roles[0] !== ROLE_ANONYMOUS) {
      dispatch(fetchModels())
    }

    dispatch({
      type: SET_USER,
      payload: data
    })

    return data;
  })
  .catch((err) => dispatch(handleError(err)))

export const authenticate = ({ username, password }) => (dispatch) => {
  dispatch(message('toast.login_wait', { LOGIN_PENDING }))
  return post({
    url: `${repositoryApi}/auth/login`,
    data: { username, password },
  })
    .then((response) => {
      localStorage.setItem(USER_TOKEN_KEY, response.data.token)
      // TODO: Avoid this call by retrieving the authenticated user from the login call response.
      // This is currently not possible, since only the token is returned.
      dispatch(fetchUser())

      return dispatch({ type: LOGIN_SUCCESSFUL })
    })
    .catch((err) => {
      throw errorToast(err, 'toast.login_failed_reason', <FormattedMessage id="message.wrong_password" />)
    })
}

export const logout = (action = ACTION_LOGIN) => (dispatch) => {
  localStorage.removeItem(USER_TOKEN_KEY)
  return dispatch({ type: LOGOUT, payload: { action } })
}

export const tokenReceived = (token) => (dispatch) => {
  localStorage.setItem(USER_TOKEN_KEY, token)
  dispatch({ type: LOGIN_SUCCESSFUL })

  return dispatch(fetchUser())
}
