import { createSelector } from 'reselect';
import { userHasRole } from '../utilities/userUtilities';

export const isAuthenticated = (state) => state.authentication.isAuthenticated || false;
export const getCurrentUser = (state) => state.authentication.user;

export const hasRole = createSelector(
  isAuthenticated,
  getCurrentUser,
  (_state, ...roles) => roles,
  (isAuthenticated, user, roles) => (isAuthenticated ? userHasRole(user, ...roles) : false)
);
