import React, { Component } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ModelType from './ModelType';
import { getModel } from '../../selectors/modelSelectors';
import { getAttributeFromModel } from '../../utilities/modelUtilities';

const required = (attribute, formatMessage) => (value) => {
  const { label } = attribute;
  return (value ? undefined : formatMessage({ id: 'validation.field_required' }, { field: label }));
};

class ModelField extends Component {
  shouldComponentUpdate = (nextProps) => {
    const { model, name, disabled, error, required } = this.props;
    // This check is necessary to avoid rerenders due to infinite validator checks
    if (model !== nextProps.model || name !== nextProps.name || disabled !== nextProps.disabled || error !== nextProps.error || required !== nextProps.required) return true;
    return false;
  }

  getValidators() {
    const { model, name, validators, intl: { formatMessage } } = this.props;
    if (!model) return [];

    const attribute = getAttributeFromModel(model, name);

    if (attribute && attribute.required) {
      return [...validators, required(attribute, formatMessage)];
    }

    return validators;
  }

  render() {
    const { model, disabled, name, required, ...restProps } = this.props;
    if (model === undefined) return null;
    const attribute = getAttributeFromModel(model, name);
    //  Allow custom 'required' attribute value (type boolean)
    if (required !== undefined) attribute.required = required;

    if (!attribute && process.env.NODE_ENV !== 'production') {
      return null;
      // throw new Error(`Field "${name}" does not match a field on the model.`);
    }

    return (
      <Field
        name={name}
        component={ModelType}
        attribute={attribute}
        disabled={disabled}
        {...restProps}
        validate={this.getValidators()}
      />
    );
  }
}

ModelField.defaultProps = {
  validators: []
};

ModelField.propTypes = {
  name: PropTypes.string.isRequired,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  validators: PropTypes.array,
};

const mapState = (state, props) => ({
  model: getModel(state, props.id)
});

export default connect(mapState)(injectIntl(ModelField));
