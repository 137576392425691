import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import { IconButton } from '../layout/Buttons'
import { getAggregateParam } from '../../selectors/collectionSelectors'
import { setParam } from '../../actions/collectionActions'

// TODO: Add a tooltip to this button
const AggregationToggle = (props) => {
  const { onClick, collection } = props
  const isActive = useSelector((state) => getAggregateParam(state, collection)) || false
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const toggle = (value) => dispatch(setParam(collection, 'aggregate', value))

  return (
    <IconButton
      iconName="Org"
      color={isActive ? 'lightgray' : 'secondary'}
      title={formatMessage({ id: 'button.aggregate_on' })}
      onClick={() => {
        toggle(!isActive)
        return onClick(!isActive)
      }}
    >
      <FormattedMessage id="button.versions_all" />
    </IconButton>
  )
}

export default AggregationToggle
