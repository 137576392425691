export const ICON_ACCOUNT = 'GridViewSmall';
export const ICON_ADD = 'Add';
export const ICON_ADVANCE = 'ImportMirrored';
export const ICON_ALLOWANCE = 'Questionnaire';
export const ICON_APPROVED = 'Accept';
export const ICON_ASSIGN = 'Assign';
export const ICON_AUDIT = 'EntryView';
export const ICON_CIRCLE_ADD = 'CircleAddition';
export const ICON_CLAIM = 'ReceiptCheck';
export const ICON_CLEAR = 'Clear';
export const ICON_CLOSE = 'ChromeClose';
export const ICON_CONFLICT = 'Error';
export const ICON_CORRECTION = 'DisableUpdates';
export const ICON_CREATE = 'NewFolder';
export const ICON_DATATABLE = 'Table';
export const ICON_DRAFT = 'More';
export const ICON_EDIT = 'Edit';
export const ICON_EMPLOYEE = 'People';
export const ICON_FAV = 'FavoriteStar';
export const ICON_FILLED_FAV = 'FavoriteStarFill';
export const ICON_FLOW = 'VisioDiagram';
export const ICON_GLASSES = 'Glasses';
export const ICON_HISTORY = 'FullHistory';
export const ICON_INFO = 'Info';
export const ICON_LOGOUT = 'Signout';
export const ICON_MAIL_ATT = 'MailAttached';
export const ICON_MODEL = 'EngineeringGroup';
export const ICON_PAYMENT = 'Money';
export const ICON_PROFORMA = 'Calculator';
export const ICON_REIMBURSEMENT = 'TaskGroup';
export const ICON_REJECTED = 'ErrorBadge';
export const ICON_REVIEW = 'Glasses';
export const ICON_TRASHBIN = 'Delete';
export const ICON_UNDO = 'Undo';
export const ICON_USER = 'AccountManagement';
