import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { withContext } from './withContext';
import { Button as BaseButton } from '../../components/layout';
import { getExpressionTree } from '../selectors/expressionSelectors';

const Button = styled(BaseButton)`
  margin-top: 10px;
`;

const ExpressionEvent = ({ tree, context: { onSubmit } }) => (
  <Button type="button" onClick={() => onSubmit(tree)}>
    <FormattedMessage id="button.apply" />
  </Button>
);

ExpressionEvent.propTypes = {
  context: PropTypes.object.isRequired,
  tree: PropTypes.array,
};

const mapState = (state, ownProps) => ({
  tree: getExpressionTree(state, ownProps.context.name)
});

export default withContext(connect(mapState)(ExpressionEvent));
