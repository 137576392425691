import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { Col } from 'styled-bootstrap-grid';
import { object, string } from 'yup';
import { authenticate } from '../actions/authenticationActions';
import {
  FormGroup,
  ButtonGroup,
  Button,
  Heading,
} from '../components/layout';
import background from '../assets/images/MinBuZaBackground.jpg';
import Logo from '../assets/logo.svg';
import Toast from '../components/layout/Toast';
import packageInfo from '../../package.json';
import TextField from '../components/_form/TextField';

const ANONYMOUS_EMAIL = 'anonymous@minbuza.nl';

const initialValues = { username: '', password: '' };

const loginValidation = object().shape({
  password: string()
    .min(6, 'validation.password_min_6')
    .required('message.required'),
});

const LoginPanel = styled('div')`
  background-color: ${(props) => props.theme.backgroundColors.default};
  padding: 50px;
  box-shadow: ${(props) => props.theme.shadows.large};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderRadius.default};
  flex-direction: column;
  width: 400px;
  margin-top: 150px;
`;

const ContainerWrapper = styled('div')`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-size: cover;
  background-image: url('${background}');
`;

const MetaData = styled('div')`
  text-align: center;
  color: ${(props) => props.theme.textColors.lightgray};
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false
    };
  }

  handleSubmit = ({ password }) => this.props.authenticate({
    username: ANONYMOUS_EMAIL,
    password
  }).then(() => this.setState({ redirectToReferrer: true }));

  render = () => {
    const { intl: { formatMessage }, submitting, redirect } = this.props;
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Navigate to={redirect || '/'} />;

    return (
      <ContainerWrapper>
        <Toast />
        <img src={Logo} alt="VDBS" />
        <LoginPanel>
          <Heading size={1}>{formatMessage({ id: 'title.login' })}</Heading>
          <Formik
            initialValues={initialValues}
            validateOnMount
            validationSchema={loginValidation}
            onSubmit={this.handleSubmit}
          >
            {({ isValid, isSubmitting }) => (
              <Form>
                <FormGroup row>
                  <Col xs={6} lg={12}>
                    <Field
                      component={TextField}
                      type="password"
                      label="label.password"
                      name="password"
                      disabled={submitting}
                      required
                    />
                  </Col>
                </FormGroup>
                <ButtonGroup>
                  <Button type="submit" disabled={!isValid || isSubmitting}>
                    <FormattedMessage id="button.login" />
                  </Button>
                </ButtonGroup>
                <MetaData>{`v${packageInfo.version}`}</MetaData>
              </Form>
            )}
          </Formik>
        </LoginPanel>
      </ContainerWrapper>
    );
  }
}

Login.propTypes = {
  authenticate: PropTypes.func,
  submitting: PropTypes.bool,
  redirect: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

const mapDispatchToProps = (dispatch) => ({
  authenticate: (credentials) => dispatch(authenticate(credentials))
});

export default connect(null, mapDispatchToProps)(injectIntl(reduxForm({
  enableReinitialize: true,
  form: 'login',
  touchOnChange: true,
})(Login)));
