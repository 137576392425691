import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { OPERATOR_EXISTS, OPERATOR_NOTEXISTS } from '../../constants/operators';
import { removeCondition, updateCondition } from '../../actions/expressionActions';
import { withContext } from '../withContext';
import { conditionShape } from '../../shapes';
import Select from '../../../components/form/Select';
import Label from '../../../components/form/Label';
import { ButtonGroup } from '../../../components/layout/Buttons';
import RemoveButton from '../../../components/layout/RemoveButton';
import ConditionItem from '../../layout/ConditionItem';
import LabelSegment from '../../layout/LabelSegment';
import OperatorSegment from '../../layout/OperatorSegment';

class ObjectCondition extends Component {
  onOperatorChange = ({ value }) => this.props.update({ ...this.props.condition, operator: value })

  onValueChange = (event) => this.props.update({ ...this.props.condition, value: event.target.value })

  getOperators = () => [
    { value: OPERATOR_EXISTS, label: <FormattedMessage id="label.operator_exists" /> },
    { value: OPERATOR_NOTEXISTS, label: <FormattedMessage id="label.operator_notexists" /> },
  ]

  render = () => {
    const { condition, field, remove } = this.props;

    return (
      <ConditionItem>
        <LabelSegment>
          <Label><FormattedMessage id={field.label} /></Label>
        </LabelSegment>
        <OperatorSegment>
          <Select
            value={this.getOperators().find((op) => op.value === (condition.operator || OPERATOR_EXISTS))}
            onChange={this.onOperatorChange}
            options={this.getOperators(condition.field)}
          />
        </OperatorSegment>
        <ButtonGroup>
          <RemoveButton onClick={() => remove(condition)} />
        </ButtonGroup>
      </ConditionItem>
    );
  }
}

ObjectCondition.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  context: PropTypes.object.isRequired,
  condition: PropTypes.shape(conditionShape),
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  parent: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch, props) => ({
  remove: (condition) => dispatch(removeCondition(props.context.name, condition, props.parent, props.context.onSubmit)),
  update: (condition) => dispatch(updateCondition(props.context.name, condition)),
});

export default withContext(connect(null, mapDispatch)(ObjectCondition));
