import React from 'react';
import { components } from 'react-select';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Icon from '../../layout/Icon';

const OptionCard = styled('div')`
  align-items: center;
  color: ${(props) => (props.textColor ? props.textColor : props.theme.textColors.default)};
  display: flex;
  justify-content: space-between;
`;

const EmployeeOption = (props) => {
  const {
    label,
    data: { paymentCode, link }
  } = props;

  return (
    <components.Option {...props}>
      <OptionCard>
        {`${label} - ${paymentCode || ''}`}
        {link && <Link to={link} target="_blank"><Icon name="OpenInNewWindow" /></Link>}
      </OptionCard>
    </components.Option>
  );
};

EmployeeOption.propTypes = {
  data: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    dateOfBirth: PropTypes.string,
    link: PropTypes.string,
  })
};

export default EmployeeOption;
