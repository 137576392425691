import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input, Label, FormFeedback, FormText, FormError } from '../form'

const TextField = (props) => {
  const {
    feedback,
    field,
    form: { touched, errors, submitCount },
    helptext,
    label,
    pattern,
    placeholder,
    required,
    type,
    ...restProps
  } = props
  const intl = useIntl()

  // Workaround to handle form submission errors
  // as defined in https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600
  const isTouched = touched[field.name] || submitCount > 0

  return (
    <>
      {label && (
        <Label htmlFor={field && field.name} required={required}>
          <FormattedMessage id={label} defaultMessage={label} />
        </Label>
      )}
      <Input
        {...field}
        {...restProps}
        value={field.value || ''}
        type={type || 'text'}
        pattern={pattern}
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : null}
        error={errors[field.name]}
        touched={isTouched}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {isTouched && errors[field.name] && (
        <FormError data-testid="text-field-error">
          <FormattedMessage id={errors[field.name]} values={{ field: label }} />
        </FormError>
      )}
    </>
  )
}

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    submitCount: PropTypes.number,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
}

export default TextField
