import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Heading, Button } from '../components/layout';
import Logo from '../assets/logo.svg';
import { authUrl } from '../utilities/apiUtilities';
import { ACTION_LOGOUT } from '../constants/auth';
import BackgroundImage from '../components/layout/BackgroundImage';
import CenteredPanel from '../components/layout/CenteredPanel';
import { logout } from '../actions/authenticationActions';
import RoleSelector from '../selectors/hasRoleSelectors';

class Logout extends Component {
  componentDidMount = () => {
    this.props.logout();
  }

  render = () => {
    if (this.props.canViewProforma) {
      return <Navigate to="/login" />
    }
    return (
      <BackgroundImage>
        <img src={Logo} alt="VDBS" />
        <CenteredPanel>
          <Heading size={1}>
            <FormattedMessage id="title.logged_out" />
          </Heading>
          <p>
            <FormattedMessage id="description.logged_out" />
          </p>
          <Button onClick={() => { window.location = authUrl; }}>
            <FormattedMessage id="button.reauthenticate" />
          </Button>
        </CenteredPanel>
      </BackgroundImage>
    );
  }
}

Logout.propTypes = {
  logout: PropTypes.func,
  canViewProforma: PropTypes.bool
};

const mapDispatch = (dispatch) => ({
  logout: () => dispatch(logout(ACTION_LOGOUT)),
});

const mapState = (state) => ({
  canViewProforma: RoleSelector.canViewAnonymousProforma(state),
});

export default connect(mapState, mapDispatch)(Logout);
