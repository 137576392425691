import React from 'react';
import PropTypes from 'prop-types';
import Condition from './Condition';
import ConditionPicker from '../ConditionPicker';
import ConditionSetItem from '../../layout/ConditionSetItem';

const ConditionSet = (props) => (
  <ConditionSetItem>
    {props.conditions.map((conditionId) => <Condition parent={props.parent} key={conditionId} id={conditionId} />)}
    <ConditionPicker parent={props.parent && props.parent} />
  </ConditionSetItem>
);

ConditionSet.propTypes = {
  parent: PropTypes.string.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.string),
};

export default ConditionSet;
