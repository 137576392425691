import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const ExternalLink = styled.a`
  color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 28px;
  z-index: 10;
`;

export const InternalLink = styled(Link)`
  color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  position: absolute;
  right: 60px;
  top: 28px;
  z-index: 10;
`;

InternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired
};

InternalLink.defaultProps = {
  target: '_blank'
};

ExternalLink.defaultProps = {
  target: '_blank'
};
