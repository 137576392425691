import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'
import Head from '../components/layout/Head'
import Toast from '../components/layout/Toast'
import EnvironmentBanner from '../components/layout/EnvironmentBanner'
import Header from '../components/layout/Header'

const Page = styled('div')`
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
`

const Content = styled('main')`
  flex-grow: 1;
  width: calc(100% - 324px);
`

const Layout = () => (
  <>
    <Head />
    <Toast />
    <Page>
      <Header collapsed />
      <Content>
        <EnvironmentBanner />
        <Suspense fallback={<div>Aan het laden...</div>}>
          <Outlet />
        </Suspense>
      </Content>
    </Page>
  </>
)

export default Layout
