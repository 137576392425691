import {
  RESET_COLLECTION,
  SET_COLLECTION,
  UPDATE_COLLECTION,
  SET_COLUMNS,
  SET_PARAM,
  SET_LISTVIEW,
  SET_FILTER_COLLAPSED,
  SET_LOADING,
} from '../actionTypes';

export const setCollection = (name, ids, meta = {}) => ({
  type: SET_COLLECTION,
  payload: {
    name,
    ids
  },
  meta
});

export const resetCollection = (name) => ({
  type: RESET_COLLECTION,
  payload: {
    name
  }
});

export const updateCollection = (name, add, remove) => ({
  type: UPDATE_COLLECTION,
  payload: {
    name,
    add: add || [],
    remove: remove || []
  }
});

export const setColumns = (name, columns) => ({
  type: SET_COLUMNS,
  payload: {
    name,
    columns
  }
});

export const setParam = (name, key, value) => ({
  type: SET_PARAM,
  payload: {
    name,
    key,
    value
  }
});

export const setListView = (name, id) => ({
  type: SET_LISTVIEW,
  payload: {
    name,
    id
  }
});

export const setFilterCollapsed = (name, value) => ({
  type: SET_FILTER_COLLAPSED,
  payload: {
    name,
    value
  }
});

export const setLoading = (name, value) => ({
  type: SET_LOADING,
  payload: {
    name,
    value
  }
});
