import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components/macro'
import Icon from '../layout/Icon'
import { ICON_INFO } from '../../constants/icons'
import Tooltip from './Tooltip'

export const TooltipContainer = styled('div')`
  position: absolute;
  right: ${({ right }) => right || '15px'};
  top: 0;
  min-width: ${({ minWidth }) => minWidth || 'auto'};
`

export const IconWrapper = styled('div')`
  cursor: pointer;
  display: inline-block;
  position: relative;
`

const FieldTooltip = ({ content, iconColor, iconSize, placement, right, minWidth }) => (
  <TooltipContainer right={right} minWidth={minWidth}>
    <Tooltip maxWidth={1000} a11y={false} placement={placement} content={<ReactMarkdown linkTarget="_blank">{content}</ReactMarkdown>} interactive allowHTML>
      <IconWrapper>
        <Icon name={ICON_INFO} size={iconSize || 'sm'} color={iconColor || 'gray'} />
      </IconWrapper>
    </Tooltip>
  </TooltipContainer>
)

FieldTooltip.propTypes = {
  content: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  placement: PropTypes.string.isRequired,
}

export default FieldTooltip
