import { v4 as uuidv4 } from 'uuid';
import { denormalize, normalize, schema } from 'normalizr';

const empty = []
const conditionSchema = new schema.Entity('conditions', {}, { idAttribute: 'id' });
conditionSchema.define({ children: [conditionSchema] });

export const generateId = () => uuidv4();
export const denormalizeExpression = (rootConditions, conditions) => denormalize(rootConditions, [conditionSchema], { conditions });
export const normalizeExpression = (expression) => normalize(expression || empty, [conditionSchema]);

export const condition = (type, field, operator, value) => ({
  type,
  field,
  operator,
  value
})

export const textCondition = (field, operator, value) => condition('text', field, operator, value)
