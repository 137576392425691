import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchDataTableAsofDate } from '../../actions/dataTableActions';
import Select from './Select';
import { handleError } from '../../actions/toastActions';
import { sortByProperyAsc } from '../../utilities/arrayUtilities';

class DataTableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
  }

  componentDidMount = () => {
    if (this.props.referenceDate) this.fetchOptions();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.referenceDate !== prevProps.referenceDate) this.fetchOptions();
  }

  fetchOptions = () => {
    const { referenceDate, valueColumn, labelColumn, tableId, fetchDataTableAsofDate } = this.props;

    return fetchDataTableAsofDate(tableId, referenceDate)
      .then((result) => this.setState({
        options: result.records
          .map((record) => ({ value: record[valueColumn], label: record[labelColumn], record }))
          .sort((a, b) => sortByProperyAsc(a, b, 'label'))
      }))
      .catch((err) => handleError(err));
  }

  render = () => (
    <Select
      {...this.props}
      options={this.state.options}
      value={this.state.options.find((val) => val.value === this.props.value)}
    />
  );
}

DataTableSelect.propTypes = {
  fetchDataTableAsofDate: PropTypes.func.isRequired,
  labelColumn: PropTypes.string.isRequired,
  referenceDate: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
  valueColumn: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchDataTableAsofDate: (tableId, date) => dispatch(fetchDataTableAsofDate(tableId, date))
});

export default connect(null, mapDispatchToProps)(DataTableSelect);
