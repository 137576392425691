import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AsyncSelect } from './Select';
import { postEmployeesSearch } from '../../api/employeeApi';
import EmployeeOption from './select/EmployeeOption';
import EmployeeLabel from './select/EmployeeLabel';
import { sortByProperyAsc, uniqueBy } from '../../utilities/arrayUtilities';
import { employeeVersionsRoute } from '../../utilities/routeUtilities';
import Icon from '../layout/Icon';
import { InternalLink } from '../links';
import { getEmployeeByEntityId } from '../../selectors/employeeSelectors';
import { fetchEmployeeApproved } from '../../actions/employeeActions';

const mapToValue = (employee) => ({
  ...employee,
  label: employee.displayName,
  value: employee._entityId,
  link: employeeVersionsRoute(employee._entityId)
});

class EmployeeSelect extends Component {
  componentDidMount = () => {
    const { employee, value } = this.props;
    if (!employee && value) {
      const { fetchEmployee, value, onLoad } = this.props;
      fetchEmployee(value).then((employee) => onLoad && onLoad(mapToValue(employee)));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.value && prevProps.value !== this.props.value) {
      const { fetchEmployee, value, onLoad } = this.props;
      fetchEmployee(value).then((employee) => onLoad && onLoad(mapToValue(employee)));
    }
  }

  search = (inputValue) => postEmployeesSearch({
    aggregate: true,
    limit: 25,
    offset: 0,
    sort: 'initials',
    // direction: 'asc',
    keywords: inputValue !== '' ? inputValue : undefined,
    referenceDate: this.props.referenceDate || undefined
  })
    .then((response) => response.data._embedded.employeeExtendedViews)
    .then((results) => results
      .filter(uniqueBy('_entityId'))
      .map((employee) => mapToValue(employee))
      .sort((a, b) => sortByProperyAsc(a, b, 'label')));

  render = () => {
    // eslint-disable-next-line no-unused-vars
    const { disabled, employee, field, ...restProps } = this.props;

    return (
      <>
        {employee && employee._entityId && (
          <InternalLink to={employeeVersionsRoute(employee._entityId)} target="_blank">
            <Icon name="OpenInNewWindow" />
          </InternalLink>
        )}
        <AsyncSelect
          components={{
            Option: EmployeeOption,
            SingleValue: EmployeeLabel
          }}
          isDisabled={disabled}
          cacheOptions
          defaultOptions
          loadOptions={this.search}
          {...restProps}
          value={employee ? mapToValue(employee) : ''}
        />
      </>
    );
  }
}

const mapState = (state, { value }) => ({
  employee: getEmployeeByEntityId(state, value),
});

const mapDispatch = (dispatch) => ({
  fetchEmployee: (entityId) => dispatch(fetchEmployeeApproved(entityId)),
});

EmployeeSelect.propTypes = {
  field: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  employee: PropTypes.object,
  referenceDate: PropTypes.string,
};

export default connect(mapState, mapDispatch)(EmployeeSelect);
