import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { INTERNAL_DATE, DISPLAY_DATE, DISPLAY_YEARMONTH_NUMERICAL } from '../../constants/dateFormats'
import MaskedInput from './MaskedInput'

const DateInput = (props) => {
  const {
    disabled,
    error,
    options,
    touched,
    placeholder,
    onChange,
    onChangeDefault,
    onBlur,
    value,
    fromFormat, // eslint-disable-line no-unused-vars
    toFormat, // eslint-disable-line no-unused-vars
    collectionKey, // eslint-disable-line no-unused-vars
    modelId, // eslint-disable-line no-unused-vars
    propertyName, // eslint-disable-line no-unused-vars
    transform, // eslint-disable-line no-unused-vars
    ...restProps
  } = props

  const { formatMessage } = useIntl()

  const getDatePattern = () => {
    if (options && options.datePattern) return options.datePattern

    switch (fromFormat) {
      case DISPLAY_YEARMONTH_NUMERICAL: {
        return ['m', 'Y']
      }
      default: {
        return ['d', 'm', 'Y']
      }
    }
  }

  const getFromFormat = () => fromFormat || DISPLAY_DATE

  const getToFormat = () => toFormat || INTERNAL_DATE

  const getPlaceholder = () => {
    if (placeholder) return placeholder

    return fromFormat === DISPLAY_YEARMONTH_NUMERICAL
      ? formatMessage({ id: 'placeholder.date_type_month' })
      : formatMessage({ id: 'placeholder.date_type_day' })
  }

  /**
   * Checks if the given date is in the right format and is a valid date
   * If so, it transforms the date to the right format
   */
  const transformDate = (event) => {
    if (event.target.value) {
      const date = moment(event.target.value, getFromFormat(), true)

      if (date.isValid()) {
        // Allow transforming the date before passing to the onChange callback
        if (transform) transform(date)

        return date.format(getToFormat())
      }
    }
    return null
  }

  const handleChange = (event) => {
    const date = transformDate(event)
    if (date) onChange(date)
    if (!date && onChangeDefault) {
      onChangeDefault(event.target.value)
    }
  }

  const handleBlur = (event) => {
    const date = transformDate(event)
    if (date) onBlur(date)
    else onBlur(null)
  }

  /**
   * Checks if the given value is a valid internal date.
   * If so, it transforms the date to the correct display format.
   * If not, it just returns the actual value so the users sees what he's actually typing
   */
  const handleValue = () => {
    if (value) {
      const date = moment(value, getToFormat(), true)
      if (date.isValid()) return date.format(getFromFormat())
    }

    return value
  }

  return (
    <MaskedInput
      {...restProps}
      value={handleValue()}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={getPlaceholder()}
      disabled={disabled}
      options={{
        date: true,
        delimiter: '-',
        datePattern: getDatePattern(),
        ...options,
      }}
      // Workaround for https://github.com/styled-components/styled-components/issues/1198
      touched={touched ? 1 : 0}
      error={error}
    />
  )
}

DateInput.defaultProps = {
  disabled: false,
  onBlur: () => {},
  onChange: () => {},
}

DateInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  onChange: PropTypes.func,
  onChangeDefault: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  fromFormat: PropTypes.string,
  toFormat: PropTypes.string,
  // type: PropTypes.oneOf([TYPE_DAY, TYPE_MONTH]),
  touched: PropTypes.bool,
  transform: PropTypes.func,
  value: PropTypes.string,
}

export default DateInput
