import React from 'react';

const ReferenceDateContext = React.createContext();

/**
 * A simple higher order component to map the referenceDate to the props
 * so that we can access the previous value in `componentDidUpdate`. This is
 * neccessary because `componentDidUpdate` does not provide previous context values
 * in the callback.
 *
 * @param {any} Component
 */
export const withReferenceDate = (Component) => (props) => (
  <ReferenceDateContext.Consumer>
    {(context) => <Component referenceDate={context} {...props} />}
  </ReferenceDateContext.Consumer>
);

export default ReferenceDateContext;
