import { hasRole } from './authenticationSelectors';

import {
  ROLE_ADMIN,
  ROLE_USER,
  ROLE_VIEWER,
  ROLE_PAYER,
  ROLE_ANONYMOUS,
} from '../constants/roles';

const RoleSelector = {
  /**
   * Employee permission checks
   */
  canViewEmployees: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canCreateEmployees: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canCreateEmployeeVersions: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canApproveEmployeeVersions: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canDeletePublicListViews: (state) => hasRole(state, ROLE_PAYER, ROLE_ADMIN),
  canDenyEmployeeVersions: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canDownloadEmployees: (state) => hasRole(state, ROLE_ADMIN),
  canRunProforma: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN, ROLE_VIEWER),

  /**
   * Allowance permission checks
   */
  canViewAllowances: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canExecuteMonthrun: (state) => hasRole(state, ROLE_ADMIN, ROLE_PAYER),
  canDownloadAuditLog: (state) => hasRole(state, ROLE_ADMIN),

  /**
   * Flow permission checks
   */
  canViewFlows: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canAddFlows: (state) => hasRole(state, ROLE_ADMIN),
  canEditFlows: (state) => hasRole(state, ROLE_ADMIN),
  canDeleteFlows: (state) => hasRole(state, ROLE_ADMIN),
  canApproveFlows: (state) => hasRole(state, ROLE_ADMIN),
  canDenyFlows: (state) => hasRole(state, ROLE_ADMIN),
  canExecuteFlows: (state) => hasRole(state, ROLE_ADMIN),
  canAssignFlows: (state) => hasRole(state, ROLE_ADMIN),
  canReviewFlows: (state) => hasRole(state, ROLE_ADMIN),

  /**
   * MasterData Table permission checks
   */

  canViewDataTables: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canCreateDataTables: (state) => hasRole(state, ROLE_ADMIN),
  canEditDataTables: (state) => hasRole(state, ROLE_ADMIN),
  canDeleteDataTables: (state) => hasRole(state, ROLE_ADMIN),
  canApproveDataTables: (state) => hasRole(state, ROLE_ADMIN),
  canRevokeDataTables: (state) => hasRole(state, ROLE_ADMIN),

  /**
   * Model permission checks
   */
  canViewModels: (state) => hasRole(state, ROLE_ADMIN),
  canViewModel: (state) => hasRole(state, ROLE_ADMIN),
  canEditModel: (state) => hasRole(state, ROLE_ADMIN),

  /**
   * Audit permission checks
   */
  canViewAudits: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),

  /**
   * User permission checks
   */
  canViewUsers: (state) => hasRole(state, ROLE_ADMIN),
  canViewUser: (state) => hasRole(state, ROLE_ADMIN),
  canAddUser: (state) => hasRole(state, ROLE_ADMIN),
  canEditUser: (state) => hasRole(state, ROLE_ADMIN),

  /**
   * Correction permission checks
   */
  canViewCorrections: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canAddCorrections: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canCreateCorrections: (state) => Object.values(state.entities.employeetimeline).filter((dossier) => dossier._status === 'APPROVED').length > 0,
  canEditCorrections: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canApproveCorrections: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canDeleteCorrections: (state) => hasRole(state, ROLE_ADMIN),
  canRevokeCorrections: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),

  /**
   * Reimbursement permission checks
   */
  canViewReimbursements: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canAddReimbursements: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canCreateReimbursements: (state) => Object.values(state.entities.employeetimeline).filter((dossier) => dossier._status === 'APPROVED').length > 0,
  canEditReimbursements: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canDeleteReimbursements: (state) => hasRole(state, ROLE_ADMIN, ROLE_PAYER),
  canAssignReimbursements: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canReviewReimbursements: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canExportReimbursements: (state) => hasRole(state, ROLE_PAYER, ROLE_ADMIN),
  canRevokeReimbursements: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canEmailReimbursements: (state) => hasRole(state, ROLE_ADMIN, ROLE_PAYER),

  /**
   * Advance permission checks
   */
  canViewAdvances: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canAddAdvances: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canCreateAdvances: (state) => Object.values(state.entities.employeetimeline).filter((dossier) => dossier._status === 'APPROVED').length > 0,
  canEditAdvances: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canDeleteAdvances: (state) => hasRole(state, ROLE_ADMIN, ROLE_PAYER, ROLE_USER),
  canAssignAdvances: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canReviewAdvances: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canExportAdvances: (state) => hasRole(state, ROLE_PAYER, ROLE_ADMIN),
  canRevokeAdvances: (state) => hasRole(state, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),

  /**
   * Payment permission checks
   */
  canViewPayments: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canExportPayments: (state) => hasRole(state, ROLE_ADMIN, ROLE_PAYER),
  canEmailPayments: (state) => hasRole(state, ROLE_ADMIN, ROLE_PAYER),
  canGeneratePayments: (state) => hasRole(state, ROLE_ADMIN, ROLE_PAYER),

  /**
   * Account permission checks
   */
  canViewAccounts: (state) => hasRole(state, ROLE_VIEWER, ROLE_USER, ROLE_PAYER, ROLE_ADMIN),
  canExportAccounts: (state) => hasRole(state, ROLE_ADMIN, ROLE_USER),
  canViewAnonymousProforma: (state) => hasRole(state, ROLE_ANONYMOUS),

  /**
   * Backups permission checks
   */
  canViewBackups: (state) => hasRole(state, ROLE_PAYER, ROLE_ADMIN),

  /**
   * Listview permission checks
   */
  canSavePublicListViews: (state) => hasRole(state, ROLE_PAYER, ROLE_ADMIN),
};

export default RoleSelector;
