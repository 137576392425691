export const sortByProperyAsc = (a, b, property) => {
  const left = a[property].toLowerCase();
  const right = b[property].toLowerCase();
  if (left < right) return -1;
  if (left > right) return 1;
  return 0;
};

/**
 * Filters out any duplicate items from an array by a given property
 *
 * @param {string} property
 */
export const uniqueBy = (property) => (item, pos, self) => self.findIndex((i) => i[property] === item[property]) === pos;
