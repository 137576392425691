import {
  LOGIN_PENDING,
  LOGIN_SUCCESSFUL,
  LOGOUT,
  SET_USER,
} from '../actionTypes';
import { ACTION_LOGIN } from '../constants/auth';
import { DATATABLELIST, REIMBURSEMENT, ADVANCE } from '../constants/entities';
import * as Collection from '../constants/collections';

const initialState = {
  isLoggingIn: false,
  isAuthenticated: false,
  action: null,
  error: false,
  user: null,
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PENDING: {
      return {
        ...state,
        isLoggingIn: true,
        isAuthenticated: false,
        action: null,
      };
    }
    case LOGIN_SUCCESSFUL: {
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        action: null,
      };
    }
    case LOGOUT: {
      return {
        ...initialState,
        isAuthenticated: false,
        action: action.payload.action || ACTION_LOGIN
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: {
          ...action.payload,
          savedSearches: [
            ...(action.payload.savedSearches || []),
            {
              _id: 's-1',
              collection: Collection.COLLECTION_EMPLOYEES,
              name: 'Versies in afwachting van akkoord',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: '_status', operator: 'equals', value: 'REVIEW' },
                  // { id: 'd', type: 'date', field: '_updatedAt', operator: 'lte', value: '-1d' }
                ]
              }
            },
            {
              _id: 's-2',
              collection: Collection.COLLECTION_EMPLOYEES,
              name: 'Versies in afwachting van akkoord voor mij',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: '_status', operator: 'equals', value: 'REVIEW' },
                  { id: 'c', type: 'text', field: '_assignee', operator: 'equals', value: action.payload.id },
                  // { id: 'd', type: 'date', field: '_updatedAt', operator: 'lte', value: '-1d' }
                ]
              }
            },
            {
              _id: 's-3',
              collection: Collection.COLLECTION_FLOWS,
              name: 'Te accorderen flows',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                ]
              }
            },
            {
              _id: 's-4',
              collection: Collection.COLLECTION_FLOWS,
              name: 'Flows in afwachting van akkoord voor mij',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                  { id: 'c', type: 'text', field: 'assignee', operator: 'equals', value: action.payload.id },
                ]
              }
            },
            {
              _id: 's-5',
              collection: Collection.COLLECTION_CORRECTIONS,
              name: 'Te accorderen uitzonderingen',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                ]
              }
            },
            {
              _id: 's-6',
              collection: Collection.COLLECTION_CORRECTIONS,
              name: 'Uitzonderingen in afwachting van akkoord voor mij',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                  { id: 'c', type: 'text', field: 'assignee', operator: 'equals', value: action.payload.id },
                ]
              }
            },
            {
              _id: 's-7',
              collection: DATATABLELIST,
              name: 'Te accorderen stamtabellen',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                ]
              }
            },
            {
              _id: 's-8',
              collection: DATATABLELIST,
              name: 'Stamtabellen in afwachting van akkoord voor mij',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                  { id: 'c', type: 'text', field: 'assignee', operator: 'equals', value: action.payload.id },
                ]
              }
            },
            {
              _id: 's-9',
              collection: REIMBURSEMENT,
              name: 'Te accorderen declaraties',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                ]
              }
            },
            {
              _id: 's-10',
              collection: REIMBURSEMENT,
              name: 'Declaraties in afwachting van akkoord voor mij',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                  { id: 'c', type: 'text', field: 'assignee', operator: 'equals', value: action.payload.id },
                ]
              }
            },
            {
              _id: 's-11',
              collection: ADVANCE,
              name: 'Te accorderen aanloopvoorschotten',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                ]
              }
            },
            {
              _id: 's-12',
              collection: ADVANCE,
              name: 'Aanloopvoorschotten in afwachting van akkoord voor mij',
              query: {
                aggregate: false,
                limit: 25,
                offset: 0,
                query: [
                  { id: 'a', type: 'text', field: 'status', operator: 'equals', value: 'REVIEW' },
                  { id: 'c', type: 'text', field: 'assignee', operator: 'equals', value: action.payload.id },
                ]
              }
            }
          ]
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default authenticationReducer;
