import styled from 'styled-components/macro';

export const SplitPanelHorizontal = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const TopPanel = styled('div')`
  height: ${(props) => (props.collapsed ? 'auto' : '100%')};
  max-height: ${(props) => (props.collapsed ? '190px' : 'auto')};
  min-height: 166px;
  overflow-y: scroll;
  border-bottom: 3px solid ${(props) => props.theme.borderColors.default};
  overflow-x: hidden;
`;

export const BottomPanel = styled('div')`
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const PanelWrapper = styled('div')`
  padding: 10px 0;
`;

export const PanelTitle = styled('h4')`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: ${(props) => (props.size ? props.theme.fontSize[props.size] : props.theme.fontSize.h3)}px;
  font-weight: bold;
  color: ${(props) => props.theme.textColors.default};
  margin: 0;
`;

export const PanelHead = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 34px;
  margin: 0 0 10px -10px;

  > * {
    padding: 0 10px;
  }
`;

export const PanelHeadGroup = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 -10px;

  > * {
    padding: 0 10px;
  }
`;
