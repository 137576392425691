import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import moment from 'moment';
import {
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import { Icon } from '.';
import { getStatusColor, getSortableFullName } from '../../utilities/employeeUtilities';
import DataTableFormatter from './DataTableFormatter';

import { DISPLAY_DATETIME } from '../../constants/dateFormats';

export const AuditFormatter = ({ value }) => (
  <Icon name="Glasses" color={getStatusColor(value)} />
);

AuditFormatter.propTypes = {
  value: PropTypes.any
};

export const AuditTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={AuditFormatter}
    {...props}
  />
);

const Bold = styled('span')`
  font-weight: bold;
`;

export const BoldFormatter = ({ value }) => (<Bold>{value}</Bold>);

BoldFormatter.propTypes = {
  value: PropTypes.any
};

export const BoldTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={BoldFormatter}
    {...props}
  />
);

export const PercentageFormatter = ({ value }) => (value ? `${value} %` : '0 %');

export const PercentageProvider = (props) => (
  <DataTypeProvider
    formatterComponent={PercentageFormatter}
    {...props}
  />
);

export const CurrencyFormatter = injectIntl(({ value, currency, intl: { formatNumber } }) => formatNumber(value || 0, { style: 'currency', currency }));

export const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <CurrencyFormatter {...cellProps} currency={props.currency} />}
    {...props}
  />
);

const EnabledFormatter = ({ value }) => <Icon name={value ? 'CheckMark' : 'Blocked'} color={value ? 'green' : 'red'} />;

EnabledFormatter.propTypes = {
  value: PropTypes.any
};

export const EnabledProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <EnabledFormatter {...cellProps} />}
    {...props}
  />
);

const RolesFormatter = ({ value }) => value.join(', ');

export const RolesProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <RolesFormatter {...cellProps} />}
    {...props}
  />
);

export const MomentFormatter = ({ value, dateFormat }) => (value ? moment(value).format(dateFormat) : '');

export const MomentTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <MomentFormatter {...cellProps} dateFormat={props.dateFormat} />}
    {...props}
  />
);

export const PDirektSentAtFormatter = ({ value }) => {
  if (typeof value === 'undefined') return <FormattedMessage id="label.unknown" />;
  switch (value) {
    case null:
      return <FormattedMessage id="label.not_sent" />;
    case false:
      return <FormattedMessage id="label.not_applicable" />;
    default:
      return <span>{moment(value).format(DISPLAY_DATETIME)}</span>;
  }
}

export const PDirektSentAtTypeProvider = () => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <PDirektSentAtFormatter {...cellProps} />}
    for={['sentToPDirektAt', 'pdirekt']}
  />
)

export const PDirektStatusFormatter = ({ value }) => {
  if (typeof value === 'undefined') return <FormattedMessage id="label.unknown" />;
  switch (value) {
    case 'UNKNOWN':
      return <FormattedMessage id="label.unknown_error" />;
    case 'SENT':
      return <FormattedMessage id="label.sent" />;
    case 'UNAUTHORIZED':
      return <FormattedMessage id="label.unauthorized" />;
    case 'NEW':
      return <FormattedMessage id="label.new" />;
    case 'BAD_REQUEST':
      return <FormattedMessage id="label.bad_request" />;
    case 'ERROR':
      return <FormattedMessage id="label.error" />;
    default:
      return <FormattedMessage id="label.unknown" />;
  }
}

export const PDirektStatusProvider = () => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <PDirektStatusFormatter {...cellProps} />}
    for={['pDirektStatus']}
  />
)

const EmployeeNameFormatter = ({ value }) => getSortableFullName(value);

export const EmployeeNameProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <EmployeeNameFormatter {...cellProps} />}
    {...props}
  />
);

export const DataTableProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <DataTableFormatter {...cellProps} modelId={props.modelId} />}
    {...props}
  />
);

const BooleanFormatter = injectIntl(({ value, intl: { formatMessage } }) => (value ? formatMessage({ id: 'label.yes' }) : formatMessage({ id: 'label.no' })));

export const BooleanProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(cellProps) => <BooleanFormatter {...cellProps} />}
    {...props}
  />
);
