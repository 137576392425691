import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Label, FormFeedback, FormText, FormError } from '.';
import { userHasRole } from '../../utilities/userUtilities';
import UserSelect from '../_form/UserSelect';
import { getCurrentUser } from '../../selectors/authenticationSelectors';

const UserSelectField = (props) => {
  const {
    input,
    filter,
    label,
    disabled,
    meta: {
      touched,
      error
    },
    feedback,
    helptext,
    roles,
    currentUser,
    includeCurrent,
    required
  } = props;

  return (
    <>
      <Label required={required}>{label}</Label>
      <UserSelect
        {...input}
        label={label}
        disabled={disabled}
        onBlur={() => input.onBlur(input.value)}
        onChange={(opt) => input.onChange(opt.value)}
        filter={(user) => user.active
          && userHasRole(user, ...roles)
          && (!includeCurrent ? (user.id !== currentUser.id) : user)
          && filter(user)}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {touched && error && <FormError data-testid="user-field-error">{error}</FormError>}
    </>
  );
};

UserSelectField.defaultProps = {
  filter: () => true,
  roles: [],
};

UserSelectField.propTypes = {
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }),
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  currentUser: PropTypes.object,
  input: PropTypes.object,
  includeCurrent: PropTypes.bool,
  filter: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  roles: PropTypes.array,
  required: PropTypes.bool,
};

const mapState = (state) => ({
  currentUser: getCurrentUser(state)
});

export default connect(mapState)(UserSelectField);
