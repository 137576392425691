import { normalize } from 'normalizr';
import { message } from 'react-toastify-redux';
import schemas from '../schemas';
import { addEntities, handleEntityCollection } from './entityActions';
import { USER } from '../constants/entities';
import { handleError } from './toastActions';
import { getUsers, getUser, postUser, putUser } from '../api/userApi';

/**
 * Adds a new user
 * @param {object} values
 */
export const addUser = (values) => (dispatch) => postUser(values)
  .then((response) => {
    const item = response.data;
    const normalizedItems = normalize(item, schemas.users);

    dispatch(addEntities(normalizedItems.entities));
    dispatch(message('toast.save_successful'));

    return normalizedItems.entities;
  })
  .catch((err) => handleError(err, 'toast.save_failed_reason')(dispatch));

export const fetchUsers = (params) => (dispatch) => getUsers(params)
  .then((response) => dispatch(handleEntityCollection(USER, response, params)))
  .catch((err) => {
    dispatch(handleError(err));
    return { results: [], total: 0, skip: 0, limit: 25 };
  });

/**
 * Update user
 *
 * @param {object} values
 */
export const updateUser = (values) => (dispatch) => {
  const data = values;
  // Clear the password field completely if its an empty string
  if (data.password === '') {
    delete data.password;
  }

  return putUser(data)
    .then((response) => {
      const item = response.data;
      const normalizedItems = normalize(item, schemas.users);

      dispatch(addEntities(normalizedItems.entities));
      dispatch(message('toast.update_successful'));

      return normalizedItems.entities;
    })
    .catch((err) => handleError(err, 'toast.save_failed_reason')(dispatch));
};

// TODO: remove when selector for user is fully implemented
export const fetchUser = (entityId) => (dispatch) => getUser(entityId)
  .then((response) => {
    const items = normalize(response.data, schemas.users);

    dispatch(addEntities(items.entities));

    return response.data;
  });
