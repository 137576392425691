export const COLLECTION_ACCOUNTS = 'accounts';
export const COLLECTION_ACCOUNTREPORTS = 'accountreports';
export const COLLECTION_ADVANCES = 'advances';
export const COLLECTION_ADVANCES_BALANCES = 'advancebalances';
export const COLLECTION_ALLOWANCES = 'allowances';
export const COLLECTION_EMPLOYEES = 'employees';
export const COLLECTION_FLOWS = 'flows';
export const COLLECTION_MODELS = 'models';
export const COLLECTION_PAYMENTS = 'payments';
export const COLLECTION_PERIODS = 'periods';
export const COLLECTION_USERS = 'users';
export const COLLECTION_DATATABLELIST = 'datatablelist';
export const COLLECTION_AUDITS = 'audits';
export const COLLECTION_CORRECTIONS = 'corrections';
export const COLLECTION_REIMBURSEMENTS = 'reimbursements';
export const COLLECTION_REIMBURSEMENT_EMAILS = 'reimbursementemails';
export const COLLECTION_REIMBURSEMENT_PAYMENTS = 'reimbursementpayments';
export const COLLECTION_REIMBURSEMENT_PERIODS = 'reimbursementperiods';
