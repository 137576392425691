import update from 'immutability-helper'

import {
  ADD_LISTVIEW,
  FETCH_LISTVIEWS,
  RECEIVE_LISTVIEWS,
  REMOVE_LISTVIEW,
  INVALIDATE_LISTVIEWS,
} from '../actionTypes'

const initialState = {
  isFetching: false,
  didInvalidate: true,
  items: [],
  lastFetchAt: null,
}

const listViewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INVALIDATE_LISTVIEWS: {
      return update(state, {
        didInvalidate: { $set: true },
      })
    }
    case RECEIVE_LISTVIEWS: {
      return update(state, {
        isFetching: { $set: false },
        didInvalidate: { $set: false },
        items: { $set: payload },
        lastFetchAt: { $set: Date.now() },
      })
    }
    case FETCH_LISTVIEWS: {
      return update(state, {
        isFetching: { $set: true },
        didInvalidate: { $set: false },
      })
    }
    case ADD_LISTVIEW: {
      return update(state, {
        items: { $push: [payload] },
      })
    }
    case REMOVE_LISTVIEW: {
      const filtered = state.items.filter((item) => item.id !== payload)
      return update(state, {
        items: { $set: filtered },
      })
    }
    default: {
      return state
    }
  }
}

export default listViewReducer
