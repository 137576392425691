import { get, post, remove, vdbsApi } from '../utilities/apiUtilities';

export const getListViews = () => get({
  url: `${vdbsApi}/listviews`
});

export const saveListView = (data) => post({
  url: `${vdbsApi}/listviews`,
  data
});

export const removeListView = (id) => remove({
  url: `${vdbsApi}/listviews/${id}`,
});
