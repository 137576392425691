import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const HeaderRow = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const HeaderCol = styled('div')`
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

export const HeadingBar = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const Heading = styled('h2')`
  margin-bottom: 1rem;
`;

const DetailHeaderRow = ({ title, children, className }) => (
  <HeaderRow className={className}>
    <HeaderCol>
      {title && (
        <Heading>{title}</Heading>
      )}
      {children}
    </HeaderCol>
  </HeaderRow>
);

DetailHeaderRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default DetailHeaderRow;
