import { createSelector } from 'reselect';

export const getItems = (state, props) => state.collection[props.collection || props].items;
export const getParams = (state, props) => state.collection[props.collection || props].params;
export const getKeywordsParam = createSelector(
  getParams,
  (params) => params.keywords
);
export const getAggregateParam = createSelector(
  getParams,
  (params) => params.aggregate
);

export const getTotal = (state, props) => state.collection[props.collection || props].total;
export const getColumns = (state, props) => state.collection[props.collection || props].columns;
export const isLoading = (state, props) => state.collection[props.collection || props].loading;
export const getFilterCollapsed = (state, props) => state.collection[props.collection || props].filterCollapsed;
