import moment from 'moment'
import axios from 'axios'
import {
  getPayments,
  getPayment,
  getPeriods,
  downloadPayment,
  getPaymentExport,
  generatePaymentsByMonth,
  excludePayment,
  includePayment,
  postPaymentsSearch,
  getEmployerPaymentExport,
  downloadProforma,
  runProforma,
  getProformaPayment,
} from '../api/paymentApi'
import { ALLOWANCE, PAYMENT, PERIOD } from '../constants/entities'
import {
  handleEntityCollection,
  handleEntity,
  updateEntity,
} from './entityActions'
import { download, getFilenameFromHeaders } from '../utilities/fileUtilities'
import { errorToast, handleError } from './toastActions'
import { P_DIRECT_DATEFORMAT } from '../constants/dateFormats'
import { getSortableFullName } from '../utilities/employeeUtilities'

export const mapAllowancesInPayment = (payment) => {
  const paymentLines = payment.paymentLines && payment.paymentLines.map((paymentLine) => {
    if (paymentLine.referenceType !== 'ALLOWANCE') return paymentLine;

    return {
      ...paymentLine,
      allowance: payment.allowances.find((allowance) => allowance.id === paymentLine.referenceId),
    };
  });

  return {
    ...payment,
    paymentLines,
  };
};

/**
 * @param {object} params
 */
export const searchPayments = (params) => (dispatch) => postPaymentsSearch({
  ...(params || {}),
  query: [
    ...((params && params.query) || []),
    ...(params.sentToPDirekt ? [{ id: 'sentToPDirekt', type: 'boolean', field: 'sentToPDirekt', operator: 'equals', value: params.sentToPDirekt }] : []),
    ...(params.includedInPDirekt ? [{ id: 'includedInPDirekt', type: 'boolean', field: 'includedInPDirekt', operator: 'equals', value: params.includedInPDirekt }] : []),
    ...(params.period ? [{ id: 'paymentByPeriod', type: 'text', field: 'period', operator: 'equals', value: params.period }] : []),
  ]
})
  .then((response) => {
    const payments = response.data.results.map((payment) => mapAllowancesInPayment(payment));
    dispatch(handleEntityCollection(PAYMENT, { data: { ...response.data, results: payments } }, { ...params }));
  })
  .catch((err) => handleError(err, 'toast.query_unsuccesful')(dispatch));

export const fetchPayments = (params) => (dispatch) => getPayments()
  .then((response) => dispatch(handleEntityCollection(PAYMENT, response, params)))
  .catch((err) => handleError(err, 'toast.query_unsuccesful')(dispatch));

const getPaymentsByEmployeeParams = (entityId, params) => ({
  limit: 25,
  offset: 0,
  ...(params || {}),
  query: [
    ...((params && params.query) || []),
    { id: 'custom', type: 'text', field: 'employeeEntityId', operator: 'equals', value: entityId },
  ]
});

export const fetchPaymentsByEmployee = (entityId, params) => (dispatch) => postPaymentsSearch(getPaymentsByEmployeeParams(entityId, params))
  .then((response) => {
    const payments = response.data.results.map((payment) => mapAllowancesInPayment(payment));
    return dispatch(handleEntityCollection(PAYMENT, { data: { ...response.data, results: payments } }, params));
  })
  .catch((err) => {
    if (!axios.isCancel(err)) {
      handleError(err, 'toast.query_unsuccesful')(dispatch);
    }
  });

export const fetchPeriods = (params) => (dispatch) => getPeriods()
  .then((response) => dispatch(handleEntityCollection(PERIOD, response, params)));

export const fetchPayment = (id) => (dispatch) => getPayment(id)
  .then((response) => {
    const payment = mapAllowancesInPayment(response.data);

    return dispatch(handleEntity(PAYMENT, { data: payment }));
  });

export const requestProformaCalculation = (entityId, referenceDate, useApprovedDossiers) => (dispatch) => getProformaPayment(referenceDate, entityId, useApprovedDossiers)
  .then((response) => {
    const payment = mapAllowancesInPayment(response.data);

    dispatch(handleEntity(PAYMENT, { data: { ...payment, proforma: true, useApprovedDossiers } }));
    dispatch(handleEntity(ALLOWANCE, { data: payment.allowances.map((a) => ({ ...a, paymentId: payment.id })) }));
    return payment;
  })
  .catch((err) => {
    throw handleError(err, 'toast.unknown_error')(dispatch)
  });

export const downloadExport = (period) => () => getPaymentExport(period)
  .then((response) => download(response.data,
    `Aanvraag_collectieve_mutatie_3W_incidentele_vergoedingen_${moment(period).format(P_DIRECT_DATEFORMAT)}.xlsx`,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));

export const downloadEmployerExport = (period) => () => getEmployerPaymentExport(period)
  .then((response) => download(response.data, `werkgevers_rapporten_${moment().format('DD-MM-YYYY__hh-mm-ss')}.zip`, 'application/zip'));

const period = (payment) => {
  const periodArray = payment.period.split('-')
  const period = `${periodArray[1]}-${periodArray[0]}`
  return period
};

/**
 * This function is shared between proforma payments and persistent payments
 */
export const downloadSpecification = (payment, documentType) => (dispatch) => (payment.proforma
  ? downloadProforma(payment, payment.useApprovedDossiers, documentType)
    .then((response) => (documentType === 'WORD'
      ? download(response.data, getFilenameFromHeaders(response), 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      : download(response.data, getFilenameFromHeaders(response), 'application/pdf')))
    .catch((err) => handleError(err, 'toast.download_fail')(dispatch))
  : downloadPayment(payment.id, documentType)
    .then((response) => (documentType === 'WORD'
      ? download(response.data, `maand-specificatie-${getSortableFullName(payment.employee)}-${period(payment)}.docx`, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      : download(response.data, `maand-specificatie-${getSortableFullName(payment.employee)}-${period(payment)}.pdf`, 'application/pdf')))
    .catch((err) => handleError(err, 'toast.download_fail')(dispatch)));

export const generatePayments = (date) => () => generatePaymentsByMonth(date);

export const setPaymentToExclude = (id) => (dispatch) => excludePayment(id).then(
  (payment) => dispatch(updateEntity({
    entity: PAYMENT,
    entityId: payment.data.id,
    newData: { includedInMailing: false },
  }))
);

export const setPaymentToInclude = (id) => (dispatch) => includePayment(id).then(
  (payment) => dispatch(updateEntity({
    entity: PAYMENT,
    entityId: payment.data.id,
    newData: { includedInMailing: true },
  }))
);

export const requestProformaSimulation = (employee, date) => (dispatch) => runProforma(employee, date)
  .then(({ data }) => download(data, 'vergoedingen.pdf', 'application/pdf'))
  .catch((err) => {
    const [key] = Object.keys(err.response.data.errors);
    throw errorToast(err, `toast.${key}`)(dispatch);
  });
