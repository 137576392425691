import styled from 'styled-components/macro';

export default styled('div')`
  background-color: ${(props) => props.theme.backgroundColors.default};
  padding: 50px;
  box-shadow: ${(props) => props.theme.shadows.large};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderRadius.default};
  flex-direction: column;
  width: 400px;
  margin-top: 150px;
`;
