import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Label, FormFeedback, FormText, FormError } from '../form';
import { convertToFloatFormat, convertToString } from '../../utilities/stringUtilities';
import CleaveInput from '../form/CleaveInput';
import FieldTooltip from '../tooltip/FieldTooltip';

const CleaveField = ({
  feedback,
  field,
  form: { errors, submitCount, touched },
  helptext,
  label,
  pattern,
  placeholder,
  required,
  options,
  disabled,
  tooltipLabel,
  intl: { formatMessage },
  ...restProps
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  // Workaround to handle form submission errors
  // as defined in https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600
  const isTouched = touched[field.name] || (submitCount > 0);

  // Cleave cannot handle null https://github.com/nosir/cleave.js/issues/400
  const val = field.value === null ? undefined : field.value;

  const handleOnBlur = (convertNumeral, field, event) => {
    setFieldTouched(field.name);
    return convertNumeral ? setFieldValue(field.name, convertToFloatFormat(event.target.value)) : field.onBlur(event);
  };

  const convertNumeral = options && options.numeral;

  return (
    <>
      {label && (
        <Label htmlFor={field && field.name} required={required}>
          <FormattedMessage id={label} />
        </Label>
      )}
      <CleaveInput
        {...field}
        {...restProps}
        value={convertNumeral ? convertToString(val) : val}
        options={options}
        pattern={pattern}
        placeholder={placeholder}
        error={isTouched ? errors[field.name] : null}
        disabled={disabled}
        onBlur={(event) => handleOnBlur(convertNumeral, field, event)}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {isTouched && errors[field.name] && (
        <FormError data-testid="cleave-field-error"><FormattedMessage id={errors[field.name]} /></FormError>
      )}
      {helptext && <FormText>{helptext}</FormText>}
      {tooltipLabel && (
        <FieldTooltip
          placement="right"
          content={formatMessage({ id: tooltipLabel })}
          iconColor="gray"
          iconSize="sm"
          right="-134px"
          minWidth="150px"
        />
      )}
    </>
  );
};

CleaveField.propTypes = {
  disabled: PropTypes.bool,
  feedback: PropTypes.any,
  field: PropTypes.object,
  form: PropTypes.shape({
    errors: PropTypes.object,
    submitCount: PropTypes.number,
    touched: PropTypes.object
  }),
  helptext: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.any,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  restProps: PropTypes.any,
  required: PropTypes.bool,
  tooltipLabel: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  })
};

export default injectIntl(CleaveField);
