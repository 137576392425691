import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputCondition from './InputCondition';
import GroupCondition from './GroupCondition';
import { TYPE_OR, TYPE_AND, TYPE_DATE, TYPE_DATE_TIME_DAY, TYPE_OBJECT, TYPE_ARRAY, TYPE_BOOLEAN, TYPE_YEAR_MONTH } from '../../../constants/types';
import { getCondition } from '../../selectors/expressionSelectors';
import { conditionShape } from '../../shapes';
import { withContext } from '../withContext';
import DataTableCondition from './DataTableCondition';
import DateCondition from './DateCondition';
import ObjectCondition from './ObjectCondition';
import EnumCondition from './EnumCondition';
import { FIELD_EMPLOYEE, FIELD_ENUM, FIELD_USER, FIELD_PERIOD } from '../../../constants/fields';
import UserCondition from './UserCondition';
import BooleanCondition from './BooleanCondition';
import PeriodCondition from './PeriodCondition';
import EmployeeCondition from './EmployeeCondition';

const Condition = ({ condition, context, parent }) => {
  if ([TYPE_AND, TYPE_OR].includes(condition.type)) {
    return <GroupCondition condition={condition} parent={parent} />;
  }

  const field = context.fields.find((f) => f.value === condition.field);

  if (!field) throw new Error(`The field "${condition.field}" was not added as filterable field on the expression builder`);

  switch (field.field) {
    case FIELD_ENUM: {
      return <EnumCondition condition={condition} field={field} parent={parent} />;
    }
    case FIELD_USER: {
      return <UserCondition condition={condition} field={field} parent={parent} />;
    }
    case FIELD_EMPLOYEE: {
      return <EmployeeCondition condition={condition} field={field} parent={parent} />;
    }
    case FIELD_PERIOD: {
      return <PeriodCondition condition={condition} field={field} parent={parent} />;
    }
    default: {
      break;
    }
  }

  // This is still here for backwards compatibility, but should also be rewritten to use conditions
  switch (field.type) {
    case TYPE_BOOLEAN: {
      return <BooleanCondition condition={condition} field={field} parent={parent} />;
    }
    case TYPE_DATE: {
      return <DateCondition hasRelatives condition={condition} field={field} parent={parent} />;
    }
    case TYPE_DATE_TIME_DAY: {
      return <DateCondition hasRelatives={false} condition={condition} field={field} parent={parent} />;
    }
    case TYPE_YEAR_MONTH: {
      return <DateCondition isMonth hasRelatives={false} condition={condition} field={field} parent={parent} />;
    }
    // We're handling an array field the same as an object field for now
    case TYPE_ARRAY:
    case TYPE_OBJECT: {
      return <ObjectCondition condition={condition} field={field} parent={parent} />;
    }
    default: {
      if (field.reference) {
        return <DataTableCondition condition={condition} field={field} parent={parent} />;
      }

      return <InputCondition condition={condition} field={field} parent={parent} />;
    }
  }
};

Condition.propTypes = {
  condition: PropTypes.shape(conditionShape),
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
};

const mapState = (state, props) => ({
  condition: getCondition(state, props.context.name, props.id),
});

export default withContext(connect(
  mapState
)(Condition));
