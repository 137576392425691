import { createSelector } from 'reselect';
import { COLLECTION_USERS } from '../constants/collections';

export const getUsers = (state) => state.entities.users;
export const getUserItems = (state) => state.collection[COLLECTION_USERS].items;
export const getUser = (state, id) => state.entities.users[id];
export const getUsersFromCollection = createSelector(
  getUsers,
  getUserItems,
  (users, ids) => ids.map((id) => users[id])
);
