import { normalize } from 'normalizr';
import { message } from 'react-toastify-redux';
import moment from 'moment';
import schemas from '../schemas';
import { addEntities, deleteEntity, handleEntityCollection, updateEntity, setEntities } from './entityActions';
import { DATATABLE, DATATABLELIST, DATATABLEVERSION } from '../constants/entities';
import { isCalled } from '../utilities/apiUtilities';
import { handleError } from './toastActions';
import { APPROVED, REJECTED, REVIEW } from '../constants/dataSourceStatus';
import { COLLECTION_DATATABLELIST } from '../constants/collections';
import { setColumns, setLoading } from './collectionActions';
import {
  postDataTablesSearch,
  getDataTables,
  getDataTable,
  getDataTableVersion,
  getDataTableVersions,
  postDataTable,
  postDataTableVersion,
  postDataTableVersionApprove,
  postDataTableVersionReject,
  postDataTableVersionRevoke,
  postDataTableVersionAssign,
  deleteDataTable,
  getDataTableAsofDate,
} from '../api/dataTableApi';
import { getClosestDatatable } from '../selectors/dataTableSelectors';
import { INTERNAL_DATE } from '../constants/dateFormats';

export const searchDataTables = (params) => (dispatch) => postDataTablesSearch(params)
  .then((response) => dispatch(handleEntityCollection(DATATABLELIST, response, params)))
  .catch((err) => dispatch(handleError(err)));

export const fetchDataTables = (params) => (dispatch) => getDataTables(params)
  .then((response) => dispatch(handleEntityCollection(DATATABLELIST, response, params)))
  .catch((err) => dispatch(handleError(err)));

export const fetchDataTableVersions = (tableId) => (dispatch) => getDataTableVersions(tableId)
  .then((response) => {
    const normalized = normalize(response.data, [schemas[DATATABLEVERSION]]);

    return dispatch(setEntities(DATATABLEVERSION, normalized.entities[DATATABLEVERSION]));
  })
  .catch((err) => dispatch(handleError(err)));

export const fetchDataTableVersion = (tableId, id) => (dispatch) => getDataTableVersion(tableId, id)
  .then(({ data }) => {
    const cols = Object.keys(data.records[0]);
    dispatch(setColumns(DATATABLELIST, cols));
    const normalizedItems = normalize(data, schemas.datatables);
    dispatch(addEntities(normalizedItems.entities));
    return data;
  });

export const fetchDataTableAsofDate = (tableId, date) => (dispatch, getState) => {
  const referenceDate = date || moment().format(INTERNAL_DATE);
  if (isCalled(`datatable-${referenceDate}`, tableId)) {
    // If the datatable version was already called before we try to find it in the state.
    const closest = getClosestDatatable(tableId, moment(referenceDate))(getState());
    // If we found it, we return it directly and skip refetching.
    // It might happen that the datatable is actually still fetching from a previous call. In that case we still
    // call it again, since some parts of the code depends on the response directly.
    // TODO: Prevent multiple requests for the exact same resource (but make sure each function call returns the results)
    if (closest) return Promise.resolve(closest);
  }
  dispatch(setLoading(COLLECTION_DATATABLELIST, true));

  return getDataTableAsofDate(tableId, referenceDate)
    .then(({ data }) => {
      const normalizedItems = normalize(data, schemas.datatables);
      dispatch(addEntities(normalizedItems.entities));
      dispatch(setLoading(COLLECTION_DATATABLELIST, false));
      return data;
    });
};

export const fetchDataTable = (tableId) => (dispatch) => {
  // Avoid calling datatable fetches multiple times
  if (isCalled('datatable', tableId)) return Promise.resolve();

  return getDataTable(tableId)
    .then(({ data }) => {
      const normalizedItems = normalize(data, schemas.datatables);
      dispatch(addEntities(normalizedItems.entities));

      return data;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addDataTable = (values) => (dispatch) => postDataTable(values)
  .then(({ data }) => {
    const normalizedItems = normalize(data, schemas.datatables);
    dispatch(addEntities(normalizedItems.entities));

    return data;
  });

export const addDataTableVersion = (id, values) => (dispatch) => postDataTableVersion(id, values)
  .then(({ data }) => {
    const normalizedItems = normalize(data, schemas.datatables);
    dispatch(addEntities(normalizedItems.entities));

    return data;
  });

export const removeDataTable = (id) => (dispatch) => deleteDataTable(id)
  .then(() => dispatch(deleteEntity({
    entity: DATATABLE,
    entityId: id
  })));

export const approveDataTableVersion = (tableId, id) => (dispatch) => postDataTableVersionApprove(tableId, id)
  .then(() => dispatch(updateEntity({
    entity: DATATABLEVERSION,
    entityId: id,
    newData: { status: APPROVED }
  })))
  .then(() => {
    dispatch(fetchDataTableVersion(tableId, id))
    dispatch(fetchDataTableVersions(tableId))
  })
  .then(() => dispatch(message('toast.approved')));

export const rejectDataTableVersion = (tableId, id, params) => (dispatch) => postDataTableVersionReject(tableId, id, params)
  .then(() => {
    dispatch(updateEntity({
      entity: DATATABLEVERSION,
      entityId: id,
      newData: { status: REJECTED }
    }));
  })
  .then(() => {
    dispatch(fetchDataTableVersion(tableId, id))
    dispatch(fetchDataTableVersions(tableId))
  })
  .then(() => dispatch(message('toast.rejected')));

export const revokeDataTableVersion = (tableId, id) => (dispatch) => postDataTableVersionRevoke(tableId, id)
  .then(() => {
    dispatch(fetchDataTableVersion(tableId, id))
    dispatch(fetchDataTableVersions(tableId))
  })
  .catch((err) => dispatch(handleError(err)));

export const assignDataTableVersion = (tableId, id, params) => (dispatch) => postDataTableVersionAssign(tableId, id, params)
  .then(() => {
    dispatch(updateEntity({
      entity: DATATABLEVERSION,
      entityId: id,
      newData: { status: REVIEW }
    }));
  })
  .then(() => {
    dispatch(fetchDataTableVersion(tableId, id))
    dispatch(fetchDataTableVersions(tableId))
  })
  .then(() => dispatch(message('toast.assigned')));
