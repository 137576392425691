import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExpressionContext from '../context/expressionContext';
import { initExpression } from '../actions/expressionActions';
import { getRootConditions } from '../selectors/expressionSelectors';
import ExpressionEvent from './ExpressionEvent';
import ConditionSet from './conditions/ConditionSet';

class ExpressionBuilder extends Component {
  componentDidMount = () => {
    this.props.init();
  }

  render = () => {
    const { conditions, name, fields, getOperators, isInitialized, onSubmit } = this.props;
    if (!isInitialized) return null;

    return (
      <ExpressionContext.Provider value={{ name, fields, getOperators, onSubmit }}>
        <ConditionSet parent="" conditions={conditions} />
        <ExpressionEvent />
      </ExpressionContext.Provider>
    );
  }
}

ExpressionBuilder.defaultProps = {
  fields: [],
  getOperators: () => []
};

ExpressionBuilder.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.string),
  // Should return an array with a `value` and `label` property
  fields: PropTypes.array,
  // Receives the selected field as the first parameter
  // Should return an array with a `value` and `label` property
  getOperators: PropTypes.func,
  init: PropTypes.func.isRequired,
  isInitialized: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const mapState = (state, { name }) => ({
  isInitialized: state.expression[name] !== undefined,
  conditions: getRootConditions(state, name),
});

const mapDispatch = (dispatch, props) => ({
  init: () => dispatch(initExpression(props.name))
});

export default connect(
  mapState,
  mapDispatch
)(ExpressionBuilder);
