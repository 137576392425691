import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Heading, Button } from '../components/layout'
import Logo from '../assets/logo.svg'
import { authUrl } from '../utilities/apiUtilities'
import BackgroundImage from '../components/layout/BackgroundImage'
import CenteredPanel from '../components/layout/CenteredPanel'

const getTranslationKey = (params) => {
  switch (params.reason) {
    case 'not-authenticated': {
      return 'auth_not_authenticated'
    }
    case 'not-authorised': {
      return 'auth_not_authorised'
    }
    case 'user-inactive': {
      return 'auth_user_inactive'
    }
    default: {
      return 'auth_failed'
    }
  }
}

const AuthFailed = () => {
  const [searchParams] = useSearchParams()
  return (
    <BackgroundImage>
      <img src={Logo} alt="VDBS" />
      <CenteredPanel>
        <Heading size={1}>
          <FormattedMessage id="title.auth_failed" />
        </Heading>
        <p>
          <FormattedMessage id={`error.${getTranslationKey(searchParams)}`} />
        </p>

        <Button
          onClick={() => {
            window.location = authUrl
          }}
        >
          <FormattedMessage id="button.reauthenticate" />
        </Button>
      </CenteredPanel>
    </BackgroundImage>
  )
}

export default AuthFailed
