import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import mediaqueries from './mediaqueries';
import Icon from './Icon';
import { ICON_CLOSE } from '../../constants/icons';

export const BackDrop = styled('div')`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 90;
  display: flex;
`;

export const ModalContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const ModalPane = styled('div')`
  position: relative;
  width: 800px;
  margin: 70px auto;
  box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px, rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
  background: white;
  border-radius: ${(props) => props.theme.borderRadius.default};
  z-index: 900;
  ${mediaqueries.lg`width: 500px;`}
  ${mediaqueries.md`width: 400px; margin-right: -300px;`}
  ${mediaqueries.sm`width: 100%; margin-right: -200px;`}
`;

const ModalHeader = styled('h3')`
  margin-bottom: .75rem;
  padding: ${(props) => props.theme.padding.sm} ${(props) => props.theme.padding.default};
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 ${(props) => props.theme.padding.default} ${(props) => props.theme.padding.sm} ${(props) => props.theme.padding.default};
`;

const ModalClose = styled(Icon)`
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
  font-size: 16px;

  :hover {
    color: ${(props) => props.theme.textColors.primary};
  }
`;

const Modal = ({ children, header, onClose }) => (
  <BackDrop>
    <ModalContainer>
      <ModalPane>
        <ModalHeader data-testid="modal-header">{header}</ModalHeader>
        <ModalClose name={ICON_CLOSE} onClick={onClose} />
        <Body>
          {children}
        </Body>
      </ModalPane>
    </ModalContainer>
  </BackDrop>
);

Modal.propTypes = {
  children: PropTypes.any,
  header: PropTypes.object,
  onClose: PropTypes.func
};

export default Modal;
