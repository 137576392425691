import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
  OPERATOR_EQUALS,
  OPERATOR_GREATERTHAN,
  OPERATOR_GREATERTHANEQUALS,
  OPERATOR_LESSTHAN,
  OPERATOR_LESSTHANEQUALS
} from '../../constants/operators';
import { removeCondition, updateCondition } from '../../actions/expressionActions';
import { withContext } from '../withContext';
import { conditionShape } from '../../shapes';
import { DISPLAY_YEARMONTH_NUMERICAL, INTERNAL_YEARMONTH } from '../../../constants/dateFormats';
import { Label } from '../../../components/form';
import Select from '../../../components/form/Select';
import { ButtonGroup } from '../../../components/layout/Buttons';
import RemoveButton from '../../../components/layout/RemoveButton';
import ConditionItem from '../../layout/ConditionItem';
import LabelSegment from '../../layout/LabelSegment';
import OperatorSegment from '../../layout/OperatorSegment';
import CriteriaSegment from '../../layout/CriteriaSegment';
import InputCriterium from '../../layout/InputCriterium';
import DateInput from '../../../components/form/DateInput';

class PeriodCondition extends Component {
  onOperatorChange = ({ value }) => this.props.update({ ...this.props.condition, operator: value });

  onValueChange = (event) => {
    let value = event.target ? event.target.value : event;
    value = moment(value).format(INTERNAL_YEARMONTH);
    this.props.update({ ...this.props.condition, value });
  }

  getOperators = () => [
    { value: OPERATOR_EQUALS, label: <FormattedMessage id="label.operator_eq" /> },
    { value: OPERATOR_GREATERTHAN, label: <FormattedMessage id="label.operator_gt" /> },
    { value: OPERATOR_GREATERTHANEQUALS, label: <FormattedMessage id="label.operator_gte" /> },
    { value: OPERATOR_LESSTHAN, label: <FormattedMessage id="label.operator_lt" /> },
    { value: OPERATOR_LESSTHANEQUALS, label: <FormattedMessage id="label.operator_lte" /> },
  ]

  render() {
    const { condition, field, remove } = this.props;

    return (
      <ConditionItem>
        <LabelSegment>
          <Label><FormattedMessage id={field.label} /></Label>
        </LabelSegment>
        <OperatorSegment>
          <Select
            value={this.getOperators().find((op) => op.value === (condition.operator || OPERATOR_EQUALS))}
            onChange={this.onOperatorChange}
            options={this.getOperators(condition.field)}
          />
        </OperatorSegment>
        <CriteriaSegment>
          <InputCriterium>
            <DateInput
              fromFormat={DISPLAY_YEARMONTH_NUMERICAL}
              onChange={this.onValueChange}
            />
          </InputCriterium>
          <ButtonGroup>
            <RemoveButton onClick={() => remove(condition)} />
          </ButtonGroup>
        </CriteriaSegment>
      </ConditionItem>
    );
  }
}

PeriodCondition.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  context: PropTypes.object.isRequired,
  condition: PropTypes.shape(conditionShape),
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  parent: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch, props) => ({
  remove: (condition) => dispatch(removeCondition(props.context.name, condition, props.parent, props.context.onSubmit)),
  update: (condition) => dispatch(updateCondition(props.context.name, condition)),
});

export default withContext(connect(null, mapDispatch)(PeriodCondition));
