import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Rect } from '../skeleton';

const SkeletonRow = styled('div')`
  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,.12);
  display: flex;
`;

const TableSkeleton = ({ rows }) => (
  <>
    {[...Array(rows || 10).keys()].map((key) => (
      <SkeletonRow key={key}>
        <Rect width={16} height={16} margin="12px" borderRadius="default" />
        <Rect width={50} height={16} margin="12px 20px" borderRadius="default" />
        <Rect width={300} height={16} margin="12px 10px" borderRadius="default" />
        <Rect width={110} height={16} margin="12px 50px" borderRadius="default" />
        <Rect width={60} height={16} margin="12px 10px" borderRadius="default" />
        <Rect width={180} height={16} margin="12px 10px" borderRadius="default" />
      </SkeletonRow>
    ))}
  </>
);

TableSkeleton.propTypes = {
  rows: PropTypes.number
};

export default TableSkeleton;
