import React from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components/macro';
import { Rect } from '../skeleton';

const StyledDossierSkeleton = styled('div')`
  position: absolute;
  background: white;
  height: 100%;
  width: 100%;
  z-index: 99;
`;

const DossierSkeleton = () => (
  <StyledDossierSkeleton>
    <Row>
      <Rect width={180} height={22} margin="10px 0" borderRadius="default" />
      <Rect width={234} height={34} margin="10px" borderRadius="default" />
      <Rect width={234} height={34} margin="10px 0" borderRadius="default" />
    </Row>
    <Row>
      <Col xs="6" lg="2">
        <Rect width={100} height={18} margin="0 0 15px 0" borderRadius="default" />
        <Rect width={70} height={18} margin="0 20px 40px 0" borderRadius="default" />
      </Col>
      <Col xs="6" lg="2">
        <Rect width={100} height={18} margin="0 0 15px 0" borderRadius="default" />
        <Rect width={70} height={18} margin="0 20px 40px 0" borderRadius="default" />
      </Col>
      <Col xs="6" lg="2">
        <Rect width={100} height={18} margin="0 0 15px 0" borderRadius="default" />
        <Rect width={70} height={18} margin="0 20px 40px 0" borderRadius="default" />
      </Col>
      <Col xs="6" lg="2">
        <Rect width={100} height={18} margin="0 0 15px 0" borderRadius="default" />
        <Rect width={70} height={18} margin="0 20px 40px 0" borderRadius="default" />
      </Col>
    </Row>
    <Row>
      <Col xs="6" lg="4">
        <Rect width={100} height={18} margin="0 0 10px 0" borderRadius="default" />
        <Rect height={36} margin="0 20px 25px 0" borderRadius="default" />
      </Col>
      <Col xs="6" lg="7">
        <Rect width={100} height={18} margin="0 0 10px 0" borderRadius="default" />
        <Rect height={36} margin="0 20px 25px 0" borderRadius="default" />
      </Col>
    </Row>
    <Row>
      <Col xs="6" lg="4">
        <Rect width={100} height={18} margin="0 0 15px 0" borderRadius="default" />
        <Rect height={36} margin="0 20px 25px 0" borderRadius="default" />
      </Col>
      <Col xs="6" lg="7">
        <Rect width={110} height={18} margin="0 0 15px 0" borderRadius="default" />
        <Rect height={100} borderRadius="default" />
      </Col>
    </Row>
    <Row>
      <Col xs="12">
        <Rect width={300} height={20} margin="20px 0 15px 0" borderRadius="default" />
      </Col>
    </Row>
  </StyledDossierSkeleton>
);

export default DossierSkeleton;
