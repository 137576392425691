import { css } from 'styled-components/macro';

const sizes = {
  lg: 1168,
  md: 992,
  sm: 768,
  xs: 576,
};

// Iterate through the sizes and create a media template
const mediaqueries = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default mediaqueries;
