import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { IconButton } from '../components/layout';
import { powerupService } from '../actions/serviceActions';
import jstack from '../assets/jstack.svg';

const Background = styled('div')`
  width: 100%;
  height: 100%;
  background-image: url(/standbybg.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
`;

const Window = styled('div')`
  background: rgba(22,22,41,.7);
  padding: 70px;
  min-width: 600px;
  max-width: 840px;
  color: white;
`;

const LinkJstack = styled('a')`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

const ServiceStandby = ({ status, isPowerupPending, onPowerupService }) => {
  if (status === 'RUNNING') {
    return <Navigate to="/employees" />;
  }

  return (
    <Background>
      <Window>
        <h1><FormattedMessage id="title.service_standby" /></h1>
        <p><FormattedMessage id="message.service_standby" /></p>
        <IconButton
          disabled={isPowerupPending}
          iconName="LightningBolt"
          iconSize="sm"
          showSpinner={isPowerupPending}
          onClick={onPowerupService}
        >
          <FormattedMessage id={status === 'POWERING' ? 'label.powering_service' : 'button.powerup_service'} />
        </IconButton>
      </Window>
      <LinkJstack href="https://www.jstack.eu/?utm_source=vdbs&utm_medium=web&utm_campaign=service_standby" target="blank">
        <img src={jstack} width="80" height="30" alt="jstack" />
      </LinkJstack>
    </Background>
  );
}

const mapProps = (state) => ({
  isPowerupPending: state.service.isPowerupPending || state.service.status === 'POWERING',
  status: state.service.status,
});

const mapDispatch = (dispatch) => ({
  onPowerupService: () => dispatch(powerupService()),
});

export default connect(mapProps, mapDispatch)(ServiceStandby);
