import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import FormFeedback from './FormFeedback';
import FormText from './FormText';
import FormError from './FormError';
import FieldTooltip from '../tooltip/FieldTooltip';
import DateInput from './DateInput';

const DateField = (props) => {
  const {
    disabled,
    input,
    label,
    feedback,
    helptext,
    meta: { touched, error },
    tooltip,
    required,
    options,
    fromFormat,
    toFormat,
    ...restProps
  } = props;

  return (
    <>
      {label && <Label htmlFor={input.name} required={required}>{label}</Label>}
      {tooltip && <FieldTooltip placement="top" content={tooltip} iconColor="gray" iconSize="sm" />}
      <DateInput
        {...input}
        {...restProps}
        disabled={disabled}
        options={options}
        touched={touched}
        error={error}
        fromFormat={fromFormat}
        toFormat={toFormat}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {touched && error && <FormError data-testid="date-field-error">{error}</FormError>}
    </>
  );
};

DateField.defaultProps = {
  disabled: false,
};

DateField.propTypes = {
  disabled: PropTypes.bool,
  feedback: PropTypes.string,
  helptext: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  initialFocus: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  meta: PropTypes.object,
  options: PropTypes.object,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  fromFormat: PropTypes.string,
  toFormat: PropTypes.string,
};

export default DateField;
