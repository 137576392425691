import { DefaultTheme } from 'styled-components/macro';
import { darken } from 'polished';

export const theme: DefaultTheme = {
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64,
  ],
  fontSize: {
    sm: 10,
    default: 15,
    lg: 18,
    xl: 24,
    h1: 21.5,
    h2: 18,
    h3: 16,
    h4: 15,
    h5: 15,
  },
  colors: {
    blue: '#007bc7',
    brightBlue: '#003EED',
    lightgray: '#f3f3f3',
    primary: '#D52B1E',
    red: '#D52B1E',
    secondary: '#01689b', // '#007bc7',
  },
  navbarBackgrounds: {
    acceptance: 'blue',
    local: 'purple',
    production: '#D52B1E',
    staging: 'blue',
    testing: 'orange',
  },
  infoColors: {
    error: '#D52B1E',
    errorLight: 'rgba(249,223,221,.5)', // Percentage of lightest red brand color
    warning: '#FFB612',
    warningLight: 'rgba(255,244,220,.5)', // Percentage of lightest yellow brand color
    info: '#007BC7',
    infoLight: 'rgba(217,235,247,.5)', // Percentage of lightest blue brand color
    success: '#39870C',
    successLight: 'rgba(225,237,219,.5)', // Percentage of lightest green brand color
  },
  instructionColors: {
    blue: '#004ad8',
    pink: '#f30667',
    yellow: '#ffbf00',
    green: '#00bf15',
    darkGreen: '#0D3331',
    red: '#ef0000',
    purple: '#4B18AC',
    turqouise: '#089ab3',
  },
  textColors: {
    default: '#202124',
    dark: '#292A2D',
    darkgray: '#4E5155',
    gray: '#66676B',
    lightgray: '#7B7E83',
    lightergray: '#82848A',
    lightestgray: '#bec1ca',
    primary: '#B60000',
    secondary: '#324B7A',
    red: '#ff0000',
    green: '#00ff00',
    blue: '#0000ff',
    orange: '#f78100',
    white: '#ffffff',
  },
  borderColors: {
    dark: '#C8C8C8',
    default: '#D8D8D8',
    lightgray: '#DEDEDE',
  },
  buttonColors: {
    transparent: 'rgba(255,255,255,0)',
    lightgray: '#ebebef',
    lightgrayHover: darken(0.03, '#ebebef'),
    darkgray: '#cbcbcb',
    darkgrayHover: darken(0.03, '#cbcbcb'),
    primary: '#007bc7',
    primaryHover: darken(0.03, '#007bc7'),
    secondary: '#01689b',
    secondaryHover: darken(0.03, '#01689b'),
    positive: '#008000',
    positiveHover: darken(0.03, '#008000'),
    danger: '#D52B1E',
    dangerHover: darken(0.03, '#D52B1E'),
  },
  backgroundColors: {
    lightergray: '#f6f7f9',
    lightgray: '#efeff1',
    gray: '#C1C3C5',
    default: '#ffffff',
    primary: '#D52B1E',
    blue: '#004ad8',
    secondary: '#01689b', // '#007bc7',
    rowConflict: '#fceeee',
    rowHighlight: '#FDF3D5',
    rowResolved: '#f0f6ed',
    green: '#089408',
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256
  ],
  fonts: {
    sans: 'ROsanswebtext, system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  shadows: {
    darkSmall: '0 4px 8px -2px rgba(13, 59, 76, 0.25), 0 0 1px rgba(13, 59, 76, 0.25)',
    default: '0 0 8px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    small: '0 0 4px rgba(0, 0, 0, .125)',
  },
  margin: {
    default: '16px',
    sm: '8px',
    md: '16px',
    lg: '32px',
    xl: '40px',
  },
  padding: {
    default: '30px',
    sm: '15px',
    md: '30px',
    lg: '45px',
  },
  borderRadius: {
    small: '2px',
    default: '3px',
    medium: '4px',
    large: '6px',
    input: '3px',
  },
  input: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#D0D2D5',
    borderColorFocus: '#EFB918',
    borderColorError: '#D52B1E',
    placeholderColor: '#9DA0A9',
    helpTextColor: '#7B7E83',
    helpTextFontSize: '13px',
    padding: '6px 10px',
  },
  Card: {
    borderRadius: 'none',
  },
  skeleton: {
    animationDuration: '1.6s',
    baseColor: '#dfdfe2',
    shineColor: '#efeff1',
  },
  employeeStatusColors: {
    0: '#FFFFFF',
    1: '#DAEEF3',
    2: '#DDD9C4',
    3: '#F2DCDB',
    4: '#8DB4E2',
    5: '#D9D9D9',
    6: '#FCD5B4',
    7: '#C4BD97',
    8: '#FFFF98',
    9: '#E6B8B7',
    10: '#D8E4BC',
    11: '#92CDDC',
    12: '#E4DFEC',
    13: '#B1A0C7',
    14: '#DAEEF3',
    15: '#DDD9C4',
  },
};

export default theme;
