import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import Label from './Label';
import FormFeedback from './FormFeedback';
import FormText from './FormText';
import FieldTooltip from '../tooltip/FieldTooltip';

const Radio = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RadioLabel = styled(Label)`
  display: flex;
  cursor: pointer;
  padding: 0 2rem 0 0;

  input {
    margin: .25rem;
  }
`;

const RadioButtonField = (props) => {
  const {
    input,
    name,
    disabled,
    options,
    feedback,
    helptext,
    required,
    label,
    tooltip,
  } = props;

  return (
    <>
      {label && <Label required={required}>{label}</Label>}
      {tooltip && <FieldTooltip placement="top" content={tooltip} iconColor="gray" iconSize="sm" />}
      <Radio>
        {options.map((option) => (
          <RadioLabel key={option.value}>
            <input
              {...input}
              disabled={disabled}
              name={name}
              type="radio"
              value={option.value}
              checked={option.value === input.value}
            />
            <FormattedMessage id={option.label} />
          </RadioLabel>
        ))}
      </Radio>
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
    </>
  );
};

RadioButtonField.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  name: PropTypes.string,
  feedback: PropTypes.string,
  helptext: PropTypes.string,
  label: PropTypes.element,
  required: PropTypes.bool,
  options: PropTypes.array,
  tooltip: PropTypes.string,
};

export default RadioButtonField;
