import update from 'immutability-helper';
import {
  SET_ENTITY_COLLECTION,
  RESET_COLLECTION,
  SET_COLLECTION,
  SET_COLUMNS,
  SET_PARAM,
  SET_LISTVIEW,
  UPDATE_COLLECTION,
  SET_FILTER_COLLAPSED,
  SET_LOADING,
} from '../actionTypes';
import {
  ACCOUNT,
  ACCOUNTREPORT,
  ADVANCE,
  ADVANCE_BALANCES,
  DATATABLELIST,
  DATATABLEVERSION,
  DATATABLE,
  FLOW,
  FLOWVERSION,
  REIMBURSEMENT,
  REIMBURSEMENT_EMAILS,
  REIMBURSEMENT_PAYMENT,
  REIMBURSEMENT_PERIODS,
} from '../constants/entities';
import { COLLECTION_ALLOWANCES, COLLECTION_EMPLOYEES } from '../constants/collections';

export const defaultParams = {
  limit: 25,
  offset: 0
};

export const initialCollectionState = {
  // An array of ordered column names
  columns: [],
  // An array of ids referencing to entities
  items: [],
  // The entity the ids refer to
  entity: null,
  // Tells if the something is busy fetching new collection data
  loading: true,
  // The total amount of items
  total: 0,
  // Query/Request params used to get this collection
  params: defaultParams,
  // Any metadata. E.g. this could be used for aggregations of a search call.
  meta: {},
  // Active saved search
  saved: null,
  //  Collapse or expand the expression builder filters in the listheader
  filterCollapsed: true,
};

export const initialState = {
  [ACCOUNT]: {
    ...initialCollectionState,
  },
  [ACCOUNTREPORT]: { ...initialCollectionState },
  [COLLECTION_ALLOWANCES]: {
    ...initialCollectionState,
    columns: ['period', 'employee.sortableLastName', 'totalAmount', 'payment.period', 'absoluteDifference', 'percentageDifference', 'createdAt'],
  },
  [COLLECTION_EMPLOYEES]: {
    ...initialCollectionState,
    params: {
      limit: 25,
      offset: 0,
      aggregate: true,
    },
    columns: ['fullName', 'regulation', 'station', 'regulationSpecifics.personType', '_updatedAt'],
  },
  [FLOW]: {
    ...initialCollectionState,
  },
  [FLOWVERSION]: { ...initialCollectionState },
  models: { ...initialCollectionState },
  payments: { ...initialCollectionState },
  periods: { ...initialCollectionState },
  audits: { ...initialCollectionState },
  corrections: { ...initialCollectionState },
  [ADVANCE]: { ...initialCollectionState },
  [ADVANCE_BALANCES]: { ...initialCollectionState },
  [REIMBURSEMENT]: { ...initialCollectionState },
  [REIMBURSEMENT_EMAILS]: { ...initialCollectionState },
  [REIMBURSEMENT_PAYMENT]: { ...initialCollectionState },
  [REIMBURSEMENT_PERIODS]: { ...initialCollectionState },
  users: { ...initialCollectionState },
  [DATATABLE]: { ...initialCollectionState },
  [DATATABLELIST]: {
    ...initialCollectionState,
    params: {
      limit: 25,
      offset: 0,
      direction: 'asc',
      sort: 'name',
    }
  },
  [DATATABLEVERSION]: { ...initialCollectionState },
};

/**
 * This reducer keeps references to certain collections of data, like a dossier list,
 * along with search params, limit, offset and totals
 */
const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    // @deprecated. Still here for BC.
    case SET_ENTITY_COLLECTION: {
      const { collection, entityType, entityIds, total, isNew, params } = action.payload;

      return {
        ...state,
        [collection || entityType]: {
          ...state[collection || entityType],
          items: (isNew || !state[collection || entityType]) ? entityIds : state[collection || entityType].items.concat(entityIds),
          loading: false,
          params: params || defaultParams,
          // Only clear the `saved` value if the params have actually changed
          // saved: params === state[entityType].params ? state[entityType].saved : null,
          total,
        },
      };
    }
    case RESET_COLLECTION: {
      const { name } = action.payload;

      return update(state, {
        [name]: {
          $set: { ...initialCollectionState }
        },
      });
    }
    case SET_COLLECTION: {
      const { name, ids } = action.payload;

      return update(state, {
        [name]: {
          $set: {
            ...state[name],
            items: ids,
            loading: false,
            total: ids.length,
            meta: action.meta,
          }
        }
      });
    }
    case UPDATE_COLLECTION: {
      const { name, add, remove } = action.payload;
      const items = state[name].items.concat(add).filter((i) => !remove.includes(i));

      return update(state, {
        [name]: {
          $set: {
            ...state[name],
            items,
            loading: false,
            total: items.length,
          }
        }
      });
    }
    case SET_COLUMNS: {
      const { name, columns } = action.payload;

      return update(state, {
        [name]: {
          columns: { $set: columns || initialState[name].columns },
        }
      });
    }
    case SET_PARAM: {
      const { name, key, value } = action.payload;

      return update(state, {
        [name]: {
          params: {
            [key]: { $set: value }
          },
        }
      });
    }
    case SET_LISTVIEW: {
      const { name, id } = action.payload;

      return update(state, {
        [name]: {
          listViewId: { $set: id }
        }
      });
    }
    case SET_FILTER_COLLAPSED: {
      const { name, value } = action.payload;

      return update(state, {
        [name]: {
          filterCollapsed: { $set: value }
        }
      });
    }
    case SET_LOADING: {
      const { name, value } = action.payload;

      return update(state, {
        [name]: {
          loading: { $set: value }
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default collectionReducer;
