import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Forbidden from '../error/Forbidden'
import { ACTION_EXPIRED, ACTION_LOGOUT } from '../../constants/auth'

const PrivateRoute = ({ children, hasAccess, redirect }) => {
  const allowed = useSelector(state => hasAccess && hasAccess(state))
  const action = useSelector(state => state.authentication.action)
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const serviceStatus = useSelector(state => state.service.status)

  if (serviceStatus !== 'RUNNING' && serviceStatus !== null) {
    return <Navigate to="/standby" />
  }

  if (!isAuthenticated && serviceStatus !== 'STANDBY') {
    //  User will be redirected by default to a targeted page, otherwise to login page
    if (redirect) {
      return <Navigate to={redirect} />
    }
    switch (action) {
      case ACTION_EXPIRED: {
        return <Navigate to="/logout?action=expired" />
      }
      case ACTION_LOGOUT: {
        return <Navigate to="/logout?action=logout" />
      }
      default: {
        return <Navigate to="/logout?action=login" />
      }
    }
  }

  //  User is redirected to error page
  if (!allowed && typeof allowed !== 'undefined') {
    return <Forbidden />
  }

  return children
}

export default PrivateRoute
