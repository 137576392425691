import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Helmet } from 'react-helmet'
import { useIntl, IntlProvider } from 'react-intl'
import locales from '../../locales'

const Head = ({ title }) => {
  const { formatMessage } = useIntl()

  return (
    <Helmet>
      <title>
        {renderToStaticMarkup(
          <IntlProvider defaultLocale="nl" locale="nl" messages={locales.nl}>
            {title || formatMessage({ id: 'head.title' })}
          </IntlProvider>
        )}
      </title>
      <meta name="description" content={formatMessage({ id: 'head.description' })} />
    </Helmet>
  )
}

export default Head
