import React, { memo, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components/macro'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Icon, Header } from '../layout'
import { getActiveEntityVersion, getLastEntityTimelineItem } from '../../selectors/employeeSelectors'
import { isLoading } from '../../selectors/collectionSelectors'
import RoleSelector from '../../selectors/hasRoleSelectors'
import { getFullName } from '../../utilities/employeeUtilities'
import {
  ICON_ADVANCE,
  ICON_EMPLOYEE,
  ICON_ALLOWANCE,
  ICON_PAYMENT,
  ICON_CORRECTION,
  ICON_REIMBURSEMENT,
  ICON_PROFORMA,
} from '../../constants/icons'
import { employeeVersionRoute } from '../../utilities/routeUtilities'
import { COLLECTION_EMPLOYEES } from '../../constants/collections'
import { fetchTimeline } from '../../actions/employeeActions'
import { handleError } from '../../actions/toastActions'
import Head from '../layout/Head'

const SecondaryNav = styled('div')`
  width: 240px;
`

const NavHeader = styled('div')`
  padding: 20px;
`

const Title = styled('h1')`
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`

const Navbar = styled('div')`
  display: flex;
  flex-direction: column;
`

const NavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none !important;
`

const StyledNavItem = styled('div')`
  background: ${(props) => (props.isActive ? props.theme.backgroundColors.lightergray : 'transparent')};
  padding: 2px 20px;
  border-top-right-radius: ${(props) => props.theme.borderRadius.large};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.large};

  ${NavLink} {
    color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.textColors.default)};
    text-decoration: none !important;
  }

  :hover {
    ${NavLink} {
      color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.textColors.default)};
    }
  }
`

const LinkText = styled('div')`
  padding: 7px 10px;
`

const NavItem = memo(({ icon, isActive, to, label }) => (
  <StyledNavItem isActive={isActive}>
    <NavLink to={to}>
      <Icon name={icon} size="lg" />
      <LinkText>
        <FormattedMessage id={label} />
      </LinkText>
    </NavLink>
  </StyledNavItem>
))

const EmployeeNavHeader = memo(({ title }) => (
  <NavHeader>
    <Title>{title}</Title>
  </NavHeader>
))

const EmployeeNav = ({ fetchTimeline, handleError, canViewEmployees, isLoading }) => {
  const { entityId } = useParams()
  const { pathname } = useLocation();
  const employee = useSelector((state) => getActiveEntityVersion(state, entityId) || getLastEntityTimelineItem(state, entityId))

  useEffect(() => {
    if (!employee) {
      fetchTimeline(entityId).catch((err) => handleError(err))
    }
  }, [employee])

  if (isLoading && !employee) {
    return (
      <Header />
    )
  }

  if (pathname.includes('/employees/new') || (!pathname.includes('new') && !canViewEmployees)) {
    return <Header />
  }

  if (!employee) {
    return (
      <Header />
    )
  }

  return (
    <Header>
      <SecondaryNav>
        <Head title={getFullName(employee)} />
        <EmployeeNavHeader title={getFullName(employee)} />
        <Navbar>
          <NavItem
            icon={ICON_EMPLOYEE}
            isActive={pathname.includes('/versions')}
            label="nav.dossier"
            to={employeeVersionRoute(employee._entityId, employee._id)}
          />
          <NavItem
            icon={ICON_ALLOWANCE}
            isActive={pathname.includes('/allowances')}
            label="nav.allowances"
            to={`/employees/${employee._entityId}/allowances`}
          />
          <NavItem
            icon={ICON_PAYMENT}
            isActive={pathname.includes('/payments')}
            label="nav.payments"
            to={`/employees/${employee._entityId}/payments`}
          />
          <NavItem
            icon={ICON_CORRECTION}
            isActive={pathname.includes('/corrections')}
            label="nav.corrections"
            to={`/employees/${employee._entityId}/corrections`}
          />
          <NavItem
            icon={ICON_REIMBURSEMENT}
            isActive={pathname.includes('/reimbursements')}
            label="nav.reimbursements"
            to={`/employees/${employee._entityId}/reimbursements`}
          />
          <NavItem
            icon={ICON_ADVANCE}
            isActive={pathname.includes('/advances')}
            label="nav.advances"
            to={`/employees/${employee._entityId}/advances`}
          />
          <NavItem
            icon="PivotChart"
            isActive={pathname.includes('/paymentreport')}
            label="nav.paymentreport"
            to={`/employees/${employee._entityId}/paymentreport`}
          />
          <NavItem
            icon="PDF"
            isActive={pathname.includes('/specifications')}
            label="label.paymentspecifications"
            to={`/employees/${employee._entityId}/specifications`}
          />
          <NavItem
            icon={ICON_PROFORMA}
            isActive={pathname.includes('/proforma')}
            label="nav.proforma"
            disabled={!employee}
            to={`/employees/${employee._entityId}/proforma`}
          />
        </Navbar>
      </SecondaryNav>
    </Header>
  )
}

const mapState = (state) => ({
  canViewEmployees: RoleSelector.canViewEmployees(state),
  isLoading: isLoading(state, COLLECTION_EMPLOYEES),
})

const mapDispatch = (dispatch) => ({
  fetchTimeline: (entityId) => dispatch(fetchTimeline(entityId)),
  handleError: (err) => dispatch(handleError(err)),
})

export default connect(mapState, mapDispatch)(EmployeeNav)
