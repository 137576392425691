import React from 'react'
import ExpressionContext from '../context/expressionContext'

/**
 * A simple higher order component to map the context to the props.
 * Ideally this should be replaced with the use of a `contextType` definition on the component
 * See
 * - https://reactjs.org/docs/context.html#classcontexttype
 * - https://github.com/facebook/react/issues/13944
 *
 * @param {any} Component
 */
export const withContext = (Component) =>
  function WrapperComponent(props) {
    return <ExpressionContext.Consumer>{(state) => <Component {...props} context={state} />}</ExpressionContext.Consumer>
  }
