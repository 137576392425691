import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components/macro'
import { Link, useLocation } from 'react-router-dom'
import { Icon, Header } from '../components/layout'

const SecondaryNav = styled('div')`
  width: 240px;
`

const Head = styled('div')`
  padding: 20px;
`

const Title = styled('h1')`
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`

const Navbar = styled('div')`
  display: flex;
  flex-direction: column;
`

const NavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none !important;
`

const NavItem = styled('div')`
  background: ${(props) => (props.isActive ? props.theme.backgroundColors.lightergray : 'transparent')};
  padding: 2px 20px;
  border-top-right-radius: ${(props) => props.theme.borderRadius.large};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.large};

  ${NavLink} {
    color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.textColors.default)};
    text-decoration: none !important;
  }

  :hover {
    ${NavLink} {
      color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.textColors.default)};
    }
  }
`

const LinkText = styled('div')`
  padding: 7px 10px;
`

const AccountNav = () => {
  const { pathname } = useLocation()
  return (
    <Header collapsed={false}>
      <SecondaryNav>
        <Head>
          <Title>
            <FormattedMessage id="title.account_list" />
          </Title>
        </Head>
        <Navbar>
          <NavItem isActive={pathname === '/accounts'}>
            <NavLink to="/accounts">
              <Icon name="GridViewSmall" size="lg" />
              <LinkText>
                <FormattedMessage id="nav.accounts_all" />
              </LinkText>
            </NavLink>
          </NavItem>
          <NavItem isActive={pathname.includes('/report')}>
            <NavLink to="/accounts/report">
              <Icon name="BarChart4" size="lg" />
              <LinkText>
                <FormattedMessage id="nav.accounts_report" />
              </LinkText>
            </NavLink>
          </NavItem>
        </Navbar>
      </SecondaryNav>
    </Header>
  )
}

export default AccountNav
