import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

const Content = styled('div')`
  align-content: middle;
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.padding.default};
`;

const Forbidden = () => (
  <Content>
    <FormattedMessage id="label.noaccess" />
  </Content>
);

export default injectIntl(Forbidden);
