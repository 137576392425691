export const getFullName = (user) => {
  if (!user) return null;

  return [
    user.firstName,
    user.middleName,
    user.lastName
  ].filter((v) => v).join(' ');
};

export const userHasRole = (user, ...roles) => roles.some((role) => user && user.roles.includes(role));
