import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components/macro'
import { Button, ButtonGroup } from './Buttons'
import Modal from './Modal'
import Alert from './Alert'

const ButtonHolder = styled(ButtonGroup)`
  justify-content: flex-end;

  ${Button} {
    margin: 15px 0 5px ${(props) => props.theme.padding.sm};
  }
`

const Error = styled('div')`
  p {
    color: ${(props) => props.theme.textColors.lightergray};
  }
`

const ErrorList = styled('ul')`
  margin: 0 -23px 5px;
`

const ConfirmationError = ({ error }) => (
  <Error>
    {error.response && error.response.data.code === 'func-13' ? (
      <Alert header={<FormattedMessage id="error.repository_item_in_use" />} type="error">
        <ErrorList>
          {error.response.data.data.parents.map((item) => (
            <li key={item.name}>{item.name}</li>
          ))}
        </ErrorList>
      </Alert>
    ) : (
      <Alert header={<FormattedMessage id="error.unknown" />} type="error" />
    )}
  </Error>
)

ConfirmationError.propTypes = {
  error: PropTypes.object,
}

const Dialog = (props) => {
  const { children, header, onCancel, onConfirm, errors } = props
  const [error, setError] = useState(null)

  const handleConfirm = async () => {
    try {
      await onConfirm()
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Modal header={header} onClose={onCancel}>
      {!error ? children : <ConfirmationError error={error} />}
      <ButtonHolder>
        {onCancel && (
          <Button color="lightgray" onClick={onCancel}>
            <FormattedMessage id="button.cancel" />
          </Button>
        )}
        {onConfirm && !error && (
          <Button disabled={errors} onClick={handleConfirm} autoFocus>
            <FormattedMessage id="button.confirm" />
          </Button>
        )}
      </ButtonHolder>
    </Modal>
  )
}

Dialog.propTypes = {
  children: PropTypes.any,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errors: PropTypes.bool,
  onCancel: PropTypes.func,
  // Callback function which is triggered as soon as a user clicks the "confirm" button.
  // Expects to receive a Promise.
  onConfirm: PropTypes.func,
}

export default Dialog
