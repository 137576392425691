import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { tokenReceived } from '../actions/authenticationActions'
import { useSnackbar } from 'notistack'

const Token = () => {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const token = searchParams.get('token')
    const returnUrl = searchParams.get('returnUrl')
    if (token) {
      dispatch(tokenReceived(token)).then((user) => {
        enqueueSnackbar(<FormattedMessage id="toast.login_successful" values={{ name: user?.displayName }} />, { variant: 'success' })

        if (!returnUrl) {
          return navigate('/employees')
        }

        return navigate(returnUrl)
      })
    } else {
      setError('error.token_missing')
    }
  }, [dispatch, navigate, searchParams])

  if (error) {
    return <FormattedMessage id={error} />
  }

  return <FormattedMessage id="message.token_validating" />
}

export default Token
