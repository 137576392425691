import React from 'react';
import { components } from 'react-select';

const CheckBoxOption = (props) => (
  <components.Option {...props}>
    <input
      type="checkbox"
      checked={props.isSelected}
      readOnly
    />
    {' '}
    {props.label}
  </components.Option>
);

export default CheckBoxOption;
