import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Label, EmployeeSelect } from '.';
import { getEmployeeByEntityId } from '../../selectors/employeeSelectors';
import { fetchEmployeeApproved } from '../../actions/employeeActions';

class EmployeeSelectField extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.input && prevProps.input.value !== this.props.input.value) {
      const { fetchEmployee } = this.props;
      fetchEmployee(this.props.input.value);
    }
    if (!this.props.employee && this.props.tandemId) {
      this.props.fetchEmployee(this.props.tandemId);
    }
  }

  render() {
    const {
      label,
      input,
      employee,
      disabled,
      required,
    } = this.props;

    return (
      <>
        <Label required={required}>{label}</Label>
        <EmployeeSelect
          {...this.props}
          input={input}
          label={label}
          disabled={disabled}
          employee={employee}
        />
      </>
    );
  }
}

EmployeeSelectField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  input: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  employee: PropTypes.object,
};

const mapState = (state, { input }) => ({
  employee: getEmployeeByEntityId(state, input.value),
});

const mapDispatch = (dispatch) => ({
  fetchEmployee: (entityId) => dispatch(fetchEmployeeApproved(entityId)),
});

export default connect(mapState, mapDispatch)(EmployeeSelectField);
