import { ADD_LISTVIEW, FETCH_LISTVIEWS, RECEIVE_LISTVIEWS, REMOVE_LISTVIEW } from '../actionTypes';
import {
  getListViews,
  removeListView,
  saveListView
} from '../api/listViewApi';
import { setListView } from './collectionActions';

const requestListViews = () => ({
  type: FETCH_LISTVIEWS,
});

const receiveListViews = (listViews) => ({
  type: RECEIVE_LISTVIEWS,
  payload: listViews,
});

const addListView = (listView) => ({
  type: ADD_LISTVIEW,
  payload: listView,
});

const deleteListViewId = (listViewId) => ({
  type: REMOVE_LISTVIEW,
  payload: listViewId,
});

const rebuildSearchQuery = (listView) => (
  { ...listView, searchQuery: JSON.parse(listView.searchQuery) }
);

export const fetchListViews = () => async (dispatch) => {
  dispatch(requestListViews());
  const { data } = await getListViews();
  const listViews = data.map((listview) => (rebuildSearchQuery(listview)));
  return dispatch(receiveListViews(listViews));
};

export const shouldFetchListViews = (state) => {
  const { listView } = state

  if (!listView.items) {
    return true
  } if (listView.isFetching) {
    return false
  }
  return listView.didInvalidate
}

export const fetchListViewsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchListViews(getState())) {
    return dispatch(fetchListViews())
  }

  return dispatch
}

export const createListView = (listView) => (dispatch) => saveListView(listView)
  .then(({ data }) => dispatch(addListView(rebuildSearchQuery(data))));

export const deleteListView = (collection, id) => (dispatch) => removeListView(id)
  .then(({ data: { id } }) => {
    dispatch(deleteListViewId(id));
    dispatch(setListView(collection, null));
  });
