import moment from 'moment'

/**
 * Tries to find the closest version in the past.
 * If that's not found, it will get the closest version in the future.
 *
 * @param {object|array} timeline
 * @param {moment} refDate
 */
export const getClosestTimelineItem = (timeline, refDate = moment()) => {
  const exactDate = Object.values(timeline).find((item) => moment(item._startDate).isSame(refDate))
  if (exactDate) return exactDate

  return Object.values(timeline).reduce((prev, curr) => {
    const startDate = moment(curr._startDate)
    if (!prev) {
      return curr
    }

    const prevStartDate = moment(prev._startDate)

    // If we're dealing with a version in the past
    if (startDate < refDate) {
      // If we have a past version here and the previous is in the future, we always prefer the past version
      if (prevStartDate > refDate) return curr

      // If the previous version is not in the future, we need to see if the current version is closer
      if (startDate > prevStartDate) return curr

      return prev
    }

    // If we're dealing with a version in the future, we must first check if the previous version is also in the future.
    // If so, we check if the current version is closer to the refDate than the previous
    if (startDate > refDate && prevStartDate > refDate && startDate < prevStartDate) {
      return curr
    }

    return prev
  }, null)
}

export const sortByDateDesc = (a, b) => moment(b._startDate) - moment(a._startDate)
export const sortByDateAsc = (a, b) => moment(a._startDate) - moment(b._startDate)
export const sortByDateAndOffsetDesc = (a, b) => moment(b._startDate) - moment(a._startDate) || b._offset - a._offset

export const getYoungestTimelineItem = (timeline) => Object.values(timeline).sort(sortByDateDesc)[0]
