import styled from 'styled-components/macro';

export default styled('div')`
  padding: 0 20px;

  /* IE11 specific media query */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* Hide cursor label on drag & drop inside react-grid */
    ul.list-group > li.list-group-item {
      display: none;
    }
  }
`;
