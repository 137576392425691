import React from 'react';
import { injectIntl } from 'react-intl';
import SelectField from './SelectField';
import * as MODELTYPE from '../../constants/modelTypes';

const ModelTypeSelectField = (props) => (
  <SelectField
    {...props}
    options={[
      {
        value: MODELTYPE.STRING,
        label: props.intl.formatMessage({ id: 'label.type_text' })
      },
      {
        value: MODELTYPE.DOUBLE,
        label: props.intl.formatMessage({ id: 'label.type_number' })
      },
      {
        value: MODELTYPE.CURRENCY,
        label: props.intl.formatMessage({ id: 'label.type_currency' })
      },
      {
        value: MODELTYPE.INTEGER,
        label: props.intl.formatMessage({ id: 'label.type_integer' })
      },
      {
        value: MODELTYPE.DATE,
        label: props.intl.formatMessage({ id: 'label.type_date' })
      },
      {
        value: MODELTYPE.TEXT,
        label: props.intl.formatMessage({ id: 'label.type_textarea' })
      },
      {
        value: MODELTYPE.OBJECT,
        label: props.intl.formatMessage({ id: 'label.type_object' })
      },
      {
        value: MODELTYPE.URL,
        label: props.intl.formatMessage({ id: 'label.type_url' })
      },
      {
        value: MODELTYPE.ARRAY,
        label: props.intl.formatMessage({ id: 'label.type_array' })
      },
      {
        value: MODELTYPE.RELATION,
        label: props.intl.formatMessage({ id: 'label.type_relation' })
      },
    ]}
  />
);

export default injectIntl(ModelTypeSelectField);
