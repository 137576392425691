import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import TextareaAutosize from 'react-autosize-textarea';
import { FormattedMessage } from 'react-intl';
import { TextArea, Label, FormFeedback, FormText, FormError } from '.';
import FieldTooltip from '../tooltip/FieldTooltip';

const TextAreaAutoSize = TextArea.withComponent(TextareaAutosize);

const TextAreaStyled = styled(TextArea)`
  margin-bottom: 10px;
`;

const TextAreaAutoSizeStyled = styled(TextAreaAutoSize)`
  margin-bottom: 10px;
`;

const TextAreaField = (props) => {
  const {
    label,
    id,
    placeholder,
    type,
    feedback,
    helptext,
    input,
    tooltip,
    autosize,
    name,
    meta: {
      touched,
      error
    },
    isRequired
  } = props;

  const Tag = autosize ? TextAreaAutoSizeStyled : TextAreaStyled;

  return (
    <>
      {label && <Label htmlFor={input && input.name} required={isRequired}>{label}</Label>}
      {tooltip && <FieldTooltip placement="top" content={tooltip} iconColor="gray" iconSize="sm" />}
      <Tag
        {...input}
        {...props}
        autosize={autosize ? 1 : 0}
        name={name}
        type={type}
        id={id}
        placeholder={placeholder}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {touched && error && <FormError><FormattedMessage id={error} /></FormError>}
    </>
  );
};

TextAreaField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  onChange: PropTypes.func,
  input: PropTypes.object,
  tooltip: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  required: PropTypes.bool,
  autosize: PropTypes.bool,
};

export default TextAreaField;
