import React from 'react';
import styled from 'styled-components/macro';

interface IStyledSpinnerProps {
  size: number;
}

const StyledSpinner = styled('svg')<IStyledSpinnerProps>`
  border: ${({ size }) => Math.round(size/8) || 2}px solid #f3f3f3; /* Light grey */
  border-top: ${({ size }) => Math.round(size/8) || 2}px solid white; /* Environment color */
  border-radius: 50%;
  animation: rotate 1s linear infinite;
  margin: 0;
  width: ${({ size }) => size || 18}px;
  height: ${({ size }) => size || 18}px;

  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Spinner = (props) => (
  <StyledSpinner viewBox="0 0 18 18" {...props}>
    <circle
      className="path"
      cx="9"
      cy="9"
      r="7"
      fill="none"
      strokeWidth="1"
    />
  </StyledSpinner>
);

export default Spinner;
