import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { ExpressionBuilder } from '../../expressionbuilder'
import DetailHeaderRow from './DetailHeaderRow'
import SaveListViewAction from './SaveListViewAction'
import { ButtonGroup, Button, ButtonContainer, IconButton } from '.'
import KeywordSearchField from '../form/KeywordSearchField'
import ColumnSelector from '../grid/ColumnSelector'
import AggregationToggle from '../grid/AggregationToggle'
import ListViewSelect from '../grid/ListViewSelect'
import ReferenceDateField from './ReferenceDateField'
import { setFilterCollapsed } from '../../actions/collectionActions'
import { deleteListView } from '../../actions/listViewActions'
import { getFilterCollapsed } from '../../selectors/collectionSelectors'
import { getSelectedListViewId, getSelectedListViewOption } from '../../selectors/listViewSelectors'
import { ICON_UNDO } from '../../constants/icons'
import RoleSelector from '../../selectors/hasRoleSelectors'

const ListHeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Buttons = styled(ButtonGroup)`
  padding: 0 1rem 0 0;

  &:last-child {
    padding: 0;
  }
`

const Filters = styled('div')`
  margin-bottom: ${({ collapse }) => (collapse ? '0' : '15px')};
`

const Search = styled('div')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

const ListHeader = (props) => {
  const { actionButtons, aggregated, availableColumns, collection, filterFields, onFilterSubmit, params, placeholderOnly, referenceDate, title, autoFocusSearch } = props
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const filtersCollapsed = useSelector((state) => getFilterCollapsed(state, collection))
  const selectedListViewId = useSelector((state) => getSelectedListViewId(state, collection))
  const selectedListView = useSelector((state) => getSelectedListViewOption(state, collection))
  const canDeletePublicListViews = useSelector(RoleSelector.canDeletePublicListViews)
  const canDeleteListViews = selectedListView && selectedListView.listView && selectedListView.listView.public
   ? canDeletePublicListViews
   : true

  const toggleFilters = () => {
    dispatch(setFilterCollapsed(collection, !filtersCollapsed))
  }

  const handleAggregation = (aggregate) => onFilterSubmit({ aggregate })

  const handleFilterSearch = (tree) => onFilterSubmit({ query: tree })

  const handleChangeKeyword = (term) => onFilterSubmit({ keywords: term, offset: 0 })

  const handleDeleteListView = async () => {
    await dispatch(deleteListView(collection, selectedListViewId))
    enqueueSnackbar(<FormattedMessage id="toast.delete_successful" />, { variant: 'success' })
    onFilterSubmit({ query: null, aggregate: true, keywords: undefined })
  }

  return (
    <ListHeaderContainer>
      <DetailHeaderRow>
        <ButtonContainer>
          <Buttons>
            <ListViewSelect collection={collection} placeholder={title} placeholderOnly={placeholderOnly} onChange={onFilterSubmit} />
            {filterFields && (<SaveListViewAction collection={collection} />)}
            {selectedListViewId && canDeleteListViews && (
              <IconButton iconName={ICON_UNDO} type="button" color="lightgray" onClick={handleDeleteListView}>
                <FormattedMessage id="button.delete" />
              </IconButton>
            )}
          </Buttons>
          <Buttons align="right">{actionButtons}</Buttons>
        </ButtonContainer>
        <ButtonContainer>
          <Buttons>
            <Search>
              {onFilterSubmit && <KeywordSearchField collection={collection} onChange={handleChangeKeyword} autoFocus={autoFocusSearch} />}
              {filterFields && (
                <Button color="lightgray" onClick={toggleFilters}>
                  <FormattedMessage id="button.search" />
                </Button>
              )}
              {aggregated && <AggregationToggle collection={collection} onClick={handleAggregation} />}
              {referenceDate && <ReferenceDateField value={params.referenceDate} handleDate={(date) => onFilterSubmit({ referenceDate: date, limit: 25 })} />}
              {availableColumns && <ColumnSelector availableColumns={availableColumns} collection={collection} />}
            </Search>
          </Buttons>
        </ButtonContainer>
      </DetailHeaderRow>
      {!placeholderOnly && (
        <Filters collapse={filtersCollapsed}>
          {!filtersCollapsed && <ExpressionBuilder name={collection} fields={filterFields} onSubmit={handleFilterSearch} />}
        </Filters>
      )}
    </ListHeaderContainer>
  )
}

ListHeader.defaultProps = {
  actionButtons: [],
  aggregated: false,
  filtersCollapsed: true,
  params: {},
  referenceDate: false,
}

ListHeader.propTypes = {
  actionButtons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  aggregated: PropTypes.bool,
  availableColumns: PropTypes.array,
  collection: PropTypes.string.isRequired,
  filterFields: PropTypes.array,
  onFilterSubmit: PropTypes.func,
  params: PropTypes.object,
  placeholderOnly: PropTypes.bool,
  referenceDate: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}

export default ListHeader
