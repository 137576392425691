import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import { OPERATOR_EQUALS, OPERATOR_EXISTS } from '../constants/operators';
import { TYPE_OR, TYPE_AND, TYPE_BOOLEAN, TYPE_TEXT } from '../../constants/types';
import { addCondition } from '../actions/expressionActions';
import { withContext } from './withContext';

import Select from '../../components/form/Select';

const Picker = styled('div')`
  width: 360px;
`;

class ConditionPicker extends Component {
  onCreate = ({ value, type }) => {
    switch (value) {
      case TYPE_AND:
      case TYPE_OR: {
        this.props.add({ type: value });
        break;
      }
      case 'partner': {
        // Type is "text" for now, since we only allow exists operators which ignore the type
        this.props.add({ field: value, operator: OPERATOR_EXISTS, type: TYPE_TEXT });
        break;
      }
      default: {
        if (type === TYPE_BOOLEAN) {
          this.props.add({ field: value, operator: OPERATOR_EQUALS, type: TYPE_BOOLEAN, value: 'true' });
        } else {
          this.props.add({ field: value, operator: OPERATOR_EQUALS, type: type || TYPE_TEXT });
        }
        break;
      }
    }
  }

  /**
   * The value of the Select component needs to be an empty string here
   * to make sure the field doesnt stick to the last selected value
   */
  render = () => {
    const { intl: { formatMessage } } = this.props;

    return (
      <Picker>
        <Select
          value=""
          getOptionLabel={(option) => formatMessage({ id: option.label })}
          onChange={this.onCreate}
          placeholder={formatMessage({ id: 'title.add_search_filter' })}
          options={[
            ...this.props.context.fields,
            {
              value: TYPE_AND,
              label: 'title.type_and'
            },
            {
              value: TYPE_OR,
              label: 'title.type_or'
            },
          ]}
        />
      </Picker>
    );
  }
}

ConditionPicker.propTypes = {
  add: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  parent: PropTypes.string,
};

const mapDispatch = (dispatch, props) => ({
  add: (expr) => dispatch(addCondition(props.context.name, expr, props.parent)),
});

export default withContext(connect(null, mapDispatch)(injectIntl(ConditionPicker)));
