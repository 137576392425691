import { createSelector } from 'reselect'
import { denormalizeExpression } from '../utils/expressionUtils'

const getExpression = (state, name) => state.expression[name]
const getConditions = (state, name) => {
  if (!state.expression[name]) return []
  return state.expression[name].conditions
}

export const getRootConditions = (state, name) => {
  if (!state.expression[name]) return []
  return state.expression[name].root
}

export const getCondition = (state, name, id) => {
  if (!state.expression[name] || !state.expression[name].conditions) return null
  return state.expression[name].conditions[id]
}

export const getExpressionTree = createSelector(
  getExpression,
  getConditions,
  (expression, conditions) => {
    if (!expression) return null
    return denormalizeExpression(expression.root, conditions)
  }
)
