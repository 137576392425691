import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import {
  Button,
  Heading,
} from '.';

const Container = styled('div')`
  padding: 40px;
`;

const Trace = styled('pre')`
  font-size: 11px;
`;

const FatalError = ({ error, errorInfo, intl: { formatMessage } }) => (
  <Container>
    <Heading size={1}>{formatMessage({ id: 'title.fatal_error' })}</Heading>
    <Heading size={2} color="gray">{error && error.toString()}</Heading>
    <Trace>
      {errorInfo && errorInfo.componentStack}
    </Trace>
    {process.env.NODE_ENV === 'production' && (
      <Button onClick={() => Sentry.showReportDialog()}>{formatMessage({ id: 'button.report_feedback' })}</Button>
    )}
  </Container>
);

FatalError.propTypes = {
  error: PropTypes.object,
  errorInfo: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export default injectIntl(FatalError);
