import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import FormFeedback from './FormFeedback';
import FormText from './FormText';
import FormError from './FormError';
import MaskedInput from './MaskedInput';

const MaskedField = (props) => {
  const {
    attribute,
    required,
    label,
    placeholder,
    feedback,
    helptext,
    input,
    meta: { touched, error },
    options,
    disabled,
    reverseTransform,
    transform
  } = props;

  return (
    <>
      {label && <Label htmlFor={input && input.name} required={required || attribute.required}>{label}</Label>}
      <MaskedInput
        {...input}
        placeholder={placeholder}
        disabled={disabled}
        options={options}
        value={reverseTransform(input.value)}
        onChange={(event) => input.onChange(transform(event.target.value))}
        onBlur={(event) => input.onBlur(transform(event.target.value))}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {touched && error && <FormError data-testid="masked-field-error">{error}</FormError>}
    </>
  );
};

MaskedField.defaultProps = {
  transform: (value) => value,
  reverseTransform: (value) => value,
};

MaskedField.propTypes = {
  // The transform callback allows you to change the value before the `onChange` is triggered.
  transform: PropTypes.func,
  // The reverseTransform callback allows you to change the value back to what should be displayed
  // in the input field.
  reverseTransform: PropTypes.func,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }),
  options: PropTypes.object,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  required: PropTypes.bool,
};

export default MaskedField;
