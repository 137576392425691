// eslint-disable no-unused-vars
import styled, { keyframes } from 'styled-components/macro';

const shineFrames = (props) => keyframes`
  0% {
    background-position: -200px;
  }

  40%, 100% {
    background-position: ${`${(props.width + 100) + (props.offset || 0)}px`};
  }
`;

const Rect = styled('div')`
  width: ${(props) => `${props.width}px` || '100%'};
  height: ${(props) => `${props.height}px` || '100%'};
  margin: ${(props) => props.margin || '0 10px 10px 0'};
  border-radius: ${(props) => (props.borderRadius ? props.theme.borderRadius[props.borderRadius] : '0')};
  background-image: linear-gradient(
    120deg,
    ${(props) => props.theme.skeleton.baseColor} 100px,
    ${(props) => props.theme.skeleton.shineColor} 155px,
    ${(props) => props.theme.skeleton.baseColor} 210px
  );
  background-size: 1440px;
  /* background-position: -200px; */
  animation: ${(props) => shineFrames(props)} ${(props) => props.theme.skeleton.animationDuration} infinite linear;
`;

export default Rect;
