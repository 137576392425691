import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { fetchDataTableAsofDate } from '../../actions/dataTableActions';
import SelectField from './SelectField';
import { withReferenceDate } from '../../context/ReferenceDateContext';
import { handleError } from '../../actions/toastActions';
import { sortByProperyAsc } from '../../utilities/arrayUtilities';
import { recordMatchesRegulation } from '../../utilities/dataTableUtilities';

class DataTableSelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      validFrom: null
    };
  }

  shouldComponentUpdate = (nextProps) => {
    if (this.props.input.value !== nextProps.input.value || this.props.meta.error !== nextProps.meta.error) {
      return true;
    }

    if (this.props.referenceDate !== nextProps.referenceDate) {
      if (moment(nextProps.referenceDate).isBetween(this.state.validFrom, this.props.referenceDate)) {
        // Avoid a component update when the new refdate is not between the new refdate and the previous
        // validFrom date
        return false;
      }
    }

    return true;
  }

  componentDidMount = () => {
    this.fetchOptions();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.referenceDate !== prevProps.referenceDate) {
      // If the new reference date is between the previous reference date and the validFrom date, we know
      // it's useless to fetch the datatable again, so we skip that action.
      if (moment(this.props.referenceDate).isBetween(this.state.validFrom, prevProps.referenceDate)) {
        return;
      }

      this.fetchOptions();
    }
  }

  fetchOptions = () => {
    const { referenceDate, valueColumn, labelColumn, tableId, fetchDataTableAsofDate, regulation } = this.props;

    return fetchDataTableAsofDate(tableId, referenceDate)
      .then((result) => {
        // Avoid updating state if the validFrom date equals the one we already have
        if (result.validFrom === this.state.validFrom) {
          return null;
        }

        return this.setState({
          validFrom: result.validFrom,
          options: result.records
            .filter((record) => recordMatchesRegulation(record, regulation))
            .map((record) => ({ value: record[valueColumn], label: record[labelColumn], record }))
            .sort((a, b) => sortByProperyAsc(a, b, 'label'))
        });
      })
      .catch((err) => handleError(err));
  }

  render = () => (
    <SelectField
      {...this.props}
      options={this.state.options}
    />
  );
}

DataTableSelectField.propTypes = {
  disabled: PropTypes.bool,
  fetchDataTableAsofDate: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelColumn: PropTypes.string,
  name: PropTypes.string,
  referenceDate: PropTypes.string,
  tableId: PropTypes.string.isRequired,
  valueColumn: PropTypes.string,
  regulation: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  fetchDataTableAsofDate: (tableId, date) => dispatch(fetchDataTableAsofDate(tableId, date))
});

export default connect(null, mapDispatchToProps)(withReferenceDate(DataTableSelectField));
