import moment from 'moment';
import { createSelector } from 'reselect';
import { COLLECTION_DATATABLELIST } from '../constants/collections';
import { getModel } from './modelSelectors';
import { DATATABLELIST, DATATABLE, DATATABLEVERSION } from '../constants/entities';
import { getModelAttributes } from '../utilities/modelUtilities';

export const getDataTableList = (state) => state.entities[DATATABLELIST];
export const getDataTables = (state) => state.entities[DATATABLE];
export const getDataTableItems = (state) => state.collection[COLLECTION_DATATABLELIST].items;
export const getDataTableTimeline = (state) => state.entities[DATATABLEVERSION];
export const getDataTableVersion = (state, id) => state.entities.datatables[id];

export const getDataTablesFromCollection = createSelector(
  getDataTableList,
  getDataTableItems,
  (datatables, ids) => ids.map((id) => datatables[id])
);

export const getClosestDatatable = (tableId, refDate = moment()) => (state) => {
  const tables = Object.values(state.entities.datatables).filter((table) => table.tableId === tableId);

  return tables.reduce((prev, curr) => {
    const validFrom = moment(curr.validFrom);
    // Ignore all items where the validFrom is higher than the given refDate
    if (validFrom > refDate) {
      return prev;
    }

    // If "prev" is defined, there must be an earlier item in the loop that passed the previous check,
    // so we just need to check if the current validFrom is higher than the previous start date.
    // If there's no "prev" defined yet, the current start date is used.
    if (!prev || validFrom > moment(prev.validFrom)) {
      return curr;
    }

    return prev;
  }, null);
};

export const getDataTable = createSelector(
  getDataTables,
  (_state, tableId) => tableId,
  (tables, tableId) => Object.values(tables).find((table) => table.tableId === tableId)
);

/**
 * Retrieves the value of a label column in a datatables table that corresponds with the attribute
 * of a model.
 *
 * @param {string} modelId
 * @param {string} attribute
 * @param {string} value
 */
// (modelId, attribute, value) =>
export const getLabel = createSelector(
  getModel,
  getDataTables,
  (state, props) => props,
  (model, dataTables, props) => {
    if (!model || !Object.keys(dataTables).length) return null;

    // Gather the matching entity data.
    const attr = getModelAttributes(model).find((a) => a.name === props.column.name || a.name === props.column.attrName);
    const table = Object.values(dataTables).find((table) => table.tableId === attr.reference);

    if (!table || !table.headers || !table.data) return null;

    // Get the right indexes of the value and label column headers, so that we
    // know in what position we can find the actual value in the `data` array.
    const valueIndex = table.headers.findIndex((header) => header === attr.valueColumn);
    const labelIndex = table.headers.findIndex((header) => header === attr.labelColumn);
    const row = table.data.find((row) => row[valueIndex] === props.value);
    if (row) {
      return row[labelIndex];
    }

    return null;
  }
);
