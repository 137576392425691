import React from 'react';
import PropTypes from 'prop-types';
import * as MODELTYPE from '../../constants/modelTypes';
import { TextField, DateField, UrlField, CheckBox } from './index';
import DataTableSelectField from './DataTableSelectField';
import EmployeeSelectField from './EmployeeSelectField';
import employeeModel from '../../models/employee';
import { FIELD_USER, FIELD_DECIMAL, FIELD_EMPLOYEE } from '../../constants/fields';
import UserSelectField from './UserSelectField';
import MaskedField from './MaskedField';
import { convertToFloatFormat, convertToString } from '../../utilities/stringUtilities';

let inputName;

const ModelType = (props) => {
  const { input, attribute, version } = props;

  if (!attribute) return null;

  inputName = input.name;

  //  replace [0...] from 'children' in case of children,
  //  because the employeeModel config will handle children fields in general
  const regEx = /^children\[[0-9]\]/;
  const regexReplace = /\[[0-9]\]/;
  if (regEx.test(inputName)) {
    inputName = inputName.replace(regexReplace, '');
  }

  // See if we get any static config for this attribute
  const config = employeeModel[inputName];

  if (attribute.datasource && attribute.datasource.datasourceId) {
    let tableId = attribute.datasource.datasourceId;
    if (config && config.tableId) {
      ({ tableId } = config);
    }

    return (
      <DataTableSelectField
        tableId={tableId}
        isMulti={props.attribute.name === 'country'}
        labelColumn={attribute.datasource.labelColumn}
        valueColumn={attribute.datasource.valueColumn}
        {...attribute}
        {...props}
      />
    );
  }

  if (config) {
    switch (config.field) {
      case FIELD_EMPLOYEE: {
        return (
          <EmployeeSelectField
            employee={version}
            {...attribute}
            {...props}
          />
        );
      }
      case FIELD_USER: {
        return (
          <UserSelectField
            {...attribute}
            {...props}
            roles={config.roles}
            includeCurrent={config.includeCurrent}
          />
        );
      }
      case FIELD_DECIMAL: {
        return (
          <MaskedField
            {...attribute}
            {...props}
            transform={(value) => convertToFloatFormat(value)}
            reverseTransform={(value) => convertToString(value)}
            options={{
              numeral: true,
              numeralThousandsGroupStyle: 'none',
              numeralDecimalMark: ',',
            }}
          />
        );
      }
      default: {
        break;
      }
    }
  }

  switch (attribute.datatype) {
    case MODELTYPE.BOOLEAN:
      return (
        <CheckBox
          {...attribute}
          {...props}
        />
      );
    case MODELTYPE.DATE:
    case MODELTYPE.DATE_STRING:
      return (
        <DateField
          {...attribute}
          {...props}
        />
      );
    case MODELTYPE.DOUBLE:
      return (
        <TextField
          type="number"
          step="0.01"
          {...attribute}
          {...props}
        />
      );
    case MODELTYPE.INTEGER:
      return (
        <TextField
          type="number"
          {...attribute}
          {...props}
        />
      );
    case MODELTYPE.STRING:
      return (
        <TextField
          type="text"
          {...attribute}
          {...props}
        />
      );
    case MODELTYPE.URL:
      return (
        <UrlField
          {...attribute}
          {...props}
        />
      );
    default:
      return (
        <TextField
          type="text"
          {...attribute}
          {...props}
        />
      );
  }
};

ModelType.propTypes = {
  attribute: PropTypes.object,
};

export default ModelType;
