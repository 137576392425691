import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  OPERATOR_EQUALS,
  OPERATOR_NOTEQUALS,
  OPERATOR_CONTAINS,
  OPERATOR_EXISTS,
  OPERATOR_NOTEXISTS,
  OPERATOR_GREATERTHAN,
  OPERATOR_GREATERTHANEQUALS,
  OPERATOR_LESSTHAN,
  OPERATOR_LESSTHANEQUALS,
} from '../../constants/operators'
import { removeCondition, updateCondition } from '../../actions/expressionActions'
import { withContext } from '../withContext'
import { conditionShape } from '../../shapes'
import Input from '../../../components/form/Input'
import Select from '../../../components/form/Select'
import Label from '../../../components/form/Label'
import { ButtonGroup } from '../../../components/layout/Buttons'
import RemoveButton from '../../../components/layout/RemoveButton'
import ConditionItem from '../../layout/ConditionItem'
import LabelSegment from '../../layout/LabelSegment'
import OperatorSegment from '../../layout/OperatorSegment'
import CriteriaSegment from '../../layout/CriteriaSegment'
import InputCriterium from '../../layout/InputCriterium'
import { TYPE_DECIMAL, TYPE_NUMBER } from '../../../constants/types'

class InputCondition extends Component {
  onOperatorChange = ({ value }) => {
    this.props.update({ ...this.props.condition, operator: value })
  }

  onValueChange = (event) => {
    let { value } = event.target
    if (this.props.field.type === TYPE_NUMBER) {
      value = parseInt(value, 10)
    }
    if (this.props.field.type === TYPE_DECIMAL) {
      value = Number(value)
    }
    this.props.update({ ...this.props.condition, value })
  }

  getOperators = () => {
    const operators = [
      { value: OPERATOR_EQUALS, label: <FormattedMessage id="label.operator_eq" /> },
      { value: OPERATOR_NOTEQUALS, label: <FormattedMessage id="label.operator_neq" /> },
      { value: OPERATOR_CONTAINS, label: <FormattedMessage id="label.operator_contains" /> },
      { value: OPERATOR_EXISTS, label: <FormattedMessage id="label.operator_exists" /> },
      { value: OPERATOR_NOTEXISTS, label: <FormattedMessage id="label.operator_notexists" /> },
    ]

    if (this.props.field.type === TYPE_DECIMAL || this.props.field.type === TYPE_NUMBER) {
      operators.push({ value: OPERATOR_GREATERTHAN, label: <FormattedMessage id="label.operator_gt" /> })
      operators.push({ value: OPERATOR_GREATERTHANEQUALS, label: <FormattedMessage id="label.operator_gte" /> })
      operators.push({ value: OPERATOR_LESSTHAN, label: <FormattedMessage id="label.operator_lt" /> })
      operators.push({ value: OPERATOR_LESSTHANEQUALS, label: <FormattedMessage id="label.operator_lte" /> })
    }

    return operators
  }

  render = () => {
    const { condition, field, remove } = this.props

    return (
      <ConditionItem>
        <LabelSegment>
          <Label>
            <FormattedMessage id={field.label} />
          </Label>
        </LabelSegment>
        <OperatorSegment>
          <Select
            value={this.getOperators().find((op) => op.value === (condition.operator || OPERATOR_EQUALS))}
            onChange={this.onOperatorChange}
            options={this.getOperators(condition.field)}
          />
        </OperatorSegment>
        <CriteriaSegment>
          <InputCriterium>
            {![OPERATOR_EXISTS, OPERATOR_NOTEXISTS].includes(condition.operator) && (
              <Input type={condition.type || 'text'} onChange={this.onValueChange} defaultValue={condition.value} />
            )}
          </InputCriterium>
          <ButtonGroup>
            <RemoveButton onClick={() => remove(condition)} />
          </ButtonGroup>
        </CriteriaSegment>
      </ConditionItem>
    )
  }
}

InputCondition.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  context: PropTypes.object.isRequired,
  condition: PropTypes.shape(conditionShape),
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  parent: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}

const mapDispatch = (dispatch, props) => ({
  remove: (condition) => dispatch(removeCondition(props.context.name, condition, props.parent, props.context.onSubmit)),
  update: (condition) => dispatch(updateCondition(props.context.name, condition)),
})

export default withContext(connect(null, mapDispatch)(InputCondition))
