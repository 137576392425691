import update from 'immutability-helper';

import {
  RESET_ENTITIES,
  SET_ENTITIES,
  SET_ENTITY_COLLECTION,
  MERGE_ENTITIES,
  UPDATE_ENTITY,
  DELETE_ENTITY
} from '../actionTypes';
import * as entities from '../constants/entities';

// Initializes the entity state of each entity defined in `../constants/entities`
export const initialState = Object.values(entities).reduce((state, entity) => ({
  ...state,
  [entity]: {}
}), {});

const entitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ENTITIES: {
      return update(state, {
        [action.payload.entity]: { $set: { ...initialState[action.payload.entity] } }
      });
    }
    case SET_ENTITY_COLLECTION: {
      return Object.keys(action.payload.entities).reduce((accumulator, currentItem) => {
        accumulator[currentItem] = {
          ...state[currentItem],
          ...action.payload.entities[currentItem]
        };
        return accumulator;
      }, { ...state });
    }
    // Overrides the whole state slice of the given entity
    case SET_ENTITIES: {
      return {
        ...state,
        [action.payload.entity]: action.payload.items,
      };
    }
    case MERGE_ENTITIES: {
      return Object.keys(action.payload).reduce((accumulator, currentItem) => {
        accumulator[currentItem] = {
          ...state[currentItem],
          ...action.payload[currentItem]
        };
        return accumulator;
      }, { ...state });
    }

    case UPDATE_ENTITY: {
      const { entity, entityId, newData } = action.payload;
      return update(state, {
        [entity]: {
          [entityId]: { $merge: newData }
        }
      });
    }
    case DELETE_ENTITY: {
      const { entity, entityId } = action.payload;
      return {
        ...state,
        [entity]: Object.keys(state[entity]).reduce((accumulator, currentItem) => {
          if (currentItem !== entityId) {
            accumulator[currentItem] = state[entity][currentItem];
          }
          return accumulator;
        }, {})
      };
    }
    default: {
      return state;
    }
  }
};

export default entitiesReducer;
