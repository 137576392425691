import { createSelector } from 'reselect';
import { COLLECTION_MODELS } from '../constants/collections';
import { EMPLOYEE_MODEL_ID } from '../constants/models';
import { MODEL } from '../constants/entities';

export const getModels = (state) => state.entities[MODEL];
export const getModelItems = (state) => state.collection[COLLECTION_MODELS].items;
export const getModel = (state, props) => state.entities.models[props.modelId || props.id || props];

export const getModelsFromCollection = createSelector(
  getModels,
  getModelItems,
  (models, ids) => ids.map((id) => models[id])
);

export const getEmployeeModel = (state) => getModel(state, EMPLOYEE_MODEL_ID);

/**
 * Get all unique Model definitions for collection
 */
export const getModelsForCollection = createSelector(
  getModels,
  (models, collection) => {
    if (models === undefined || collection === undefined) return [];

    const modelIds = Object.values(collection).filter((item) => item).map((item) => item._model);
    const uniqueModelIds = [...new Set(modelIds)];

    return Object.values(models).filter((model) => uniqueModelIds.indexOf(model._id) >= 0);
  }
);
