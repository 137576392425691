export const MERGE_ENTITIES = 'MERGE_ENTITIES';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const SET_ENTITIES = 'SET_ENTITIES';
export const RESET_ENTITIES = 'RESET_ENTITIES';
export const SET_ENTITY_COLLECTION = 'SET_ENTITY_COLLECTION';

// Collection actions
export const RESET_COLLECTION = 'collection/RESET_COLLECTION';
export const SET_COLLECTION = 'collection/SET_COLLECTION';
export const UPDATE_COLLECTION = 'collection/UPDATE_COLLECTION';
export const SET_COLUMNS = 'collection/SET_COLUMNS';
export const SET_PARAM = 'collection/SET_PARAM';
export const SET_LISTVIEW = 'collection/SET_LISTVIEW';
export const SET_FILTER_COLLAPSED = 'collection/SET_FILTER_COLLAPSED';
export const SET_LOADING = 'collection/SET_LOADING';

export const LOGIN_PENDING = 'auth/LOGIN_PENDING';
export const LOGIN_SUCCESSFUL = 'auth/LOGIN_SUCCESSFUL';
export const LOGOUT = 'auth/LOGOUT';
export const SET_USER = 'auth/SET_USER';
export const SET_SAVED_SEARCH = 'auth/SET_SAVED_SEARCH';

export const UPDATE_SERVICEWORKER = 'UPDATE_SERVICEWORKER';

export const UPDATE_SERVICE_STATUS = 'UPDATE_SERVICE_STATUS';
export const SERVICE_POWERUP_PENDING = 'SERVICE_POWERUP_PENDING';

export const TOGGLE_DEBUG_FLOW = 'TOGGLE_DEBUG_FLOW';
export const FLOW_REQUESTED = 'FLOW_REQUESTED';
export const FLOW_RUNNING = 'FLOW_RUNNING';
export const FLOW_FINISHED = 'FLOW_FINISHED';
export const FLOW_STOPPED = 'FLOW_STOPPED';
export const FLOW_PROGRESS = 'FLOW_PROGRESS';
export const FLOW_SUBSCRIBED = 'FLOW_SUBSCRIBED';
export const INSTRUCTION_LOG_RECEIVED = 'INSTRUCTION_LOG_RECEIVED';
export const FLOW_INIT = 'FLOW_INIT';
export const FLOW_EDIT_DISABLED = 'FLOW_EDIT_DISABLED';

export const FETCH_LISTVIEWS = 'FETCH_LISTVIEWS';
export const RECEIVE_LISTVIEWS = 'RECEIVE_LISTVIEWS';
export const ADD_LISTVIEW = 'ADD_LISTVIEW';
export const REMOVE_LISTVIEW = 'REMOVE_LISTVIEW';
export const INVALIDATE_LISTVIEWS = 'INVALIDATE_LISTVIEWS';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
