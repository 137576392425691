import { diff, applyChange } from 'deep-diff';
import {
  REGULATION_ACRU,
  REGULATION_KU,
  REGULATION_RCEV,
  REGULATION_VBRA,
  REGULATION_VUBZK
} from '../constants/regulations';

const ACRUFieldsList = [
  'regulationSpecifics.country',
  'regulationSpecifics.functionLevel',
  'regulationSpecifics.jobTitle',
  'regulationSpecifics.residence',
  'regulationSpecifics.residenceFurnished',
  'regulationSpecifics.car',
  'regulationSpecifics.residenceEmbassy',
  'regulationSpecifics.domesticStaffSA',
  'regulationSpecifics.monthlyRent',
  'regulationSpecifics.forcedMoveDate',
  'regulationSpecifics.stationTemporary',
  'regulationSpecifics.functionLevelTemporary',
  'regulationSpecifics.functionLevelPartner',
];
const KUFieldsList = [];
const RCEVFieldsList = [
  'regulationSpecifics.functionLevel',
  'regulationSpecifics.jobTitle',
  'regulationSpecifics.car',
];
const VBRAFieldsList = [
  'regulationSpecifics.rentCurrency',
  'regulationSpecifics.monthlyRent',
  'regulationSpecifics.rentalAgreementEndDate',
  'regulationSpecifics.currencyLocalSalary',
  'regulationSpecifics.localSalary',
  'regulationSpecifics.preMissionSector',
  'regulationSpecifics.preMissionSalaryScale',
  'regulationSpecifics.preMissionSeniorityDate',
  'regulationSpecifics.preMissionContractHours',
  'regulationSpecifics.preMissionGuaranteeFlightAllowance',
  'regulationSpecifics.preMissionsalaryGross',
  'regulationSpecifics.preMissionsalaryNet',
];
const VUBZKFieldsList = [
  'regulationSpecifics.rentCurrency',
  'regulationSpecifics.monthlyRent',
  'regulationSpecifics.rentalAgreementEndDate',
  'regulationSpecifics.preMissionSector',
  'regulationSpecifics.preMissionSalaryScale',
  'regulationSpecifics.preMissionSeniorityDate',
  'regulationSpecifics.preMissionContractHours',
  'regulationSpecifics.preMissionGuaranteeFlightAllowance',
  'regulationSpecifics.preMissionsalaryGross',
  'regulationSpecifics.preMissionsalaryNet',
];

export const specificFields = {
  [REGULATION_ACRU]: ACRUFieldsList,
  [REGULATION_KU]: KUFieldsList,
  [REGULATION_RCEV]: RCEVFieldsList,
  [REGULATION_VBRA]: VBRAFieldsList,
  [REGULATION_VUBZK]: VUBZKFieldsList
};

/**
 * An array of fields that should be ignored from diff comparison.
 * (internal) fields starting with an underscore are ignored by default.
 */
const excludedFields = [
  'comments', 'sortableLastName', 'lastName', 'conflict', 'lockVersion', 'updateReason',
  'activeApprovedOffset.endDate', 'activeApprovedOffset.active'
];

/**
 * This prepares the object before generating diffs, so that all fields of nested
 * objects are returned separately, instead of as a single property.
 * (e.g. `children[0].firstName` instead of `children`)
 *
 * @param {object} left
 * @param {object} right
 */
const prepare = (left, right) => {
  const obj = { ...left };

  // Set the partner to an empty object if it is not defined on the left,
  // but is defined on the right
  if (!obj.partner && right && right.partner) {
    obj.partner = {};
  }

  // Set the regulationSpecifics to an empty object if it is not defined on the left,
  // but is defined on the right
  if (!obj.regulationSpecifics && right && right.regulationSpecifics) {
    obj.regulationSpecifics = {};
  }

  // Set each child to an empty object if it is not defined on the left or if it's an empty array on the left.
  // while it is defined on the right
  if ((!obj.children || !obj.children.length) && right && right.children) obj.children = right.children.map(() => ({}));

  // If the right side has more children than the left side, add empty placeholder for each missing child
  if (obj.children && right && right.children && right.children.length > obj.children.length) {
    obj.children = obj.children.concat([...Array(right.children.length - obj.children.length)].map(() => ({})));
  }

  return obj;
};

/**
 * Split the propertyPath by dots AND left-square-brackets
 * so we can turn `children[1].birthName` into `['children', '0', 'birthName']`
 *
 * @param {string} field
 */
export const fieldToPath = (field) => field.split(/[[.]+/g).map((prop) => prop.replace(']', ''));

/**
 * Retrieves the value of an object by the given field path
 *
 * @param {object} object
 * @param {string} field
 */
export const getFieldValue = (object, field) => {
  try {
    return fieldToPath(field).reduce((o, i) => o[i], object);
  } catch (err) {
    return undefined;
  }
};

/**
 * check if the specified field belongs to the regulation
 *
 * @param {object} dossier
 * @param {string} field
 */
export const fieldExists = (dossier, field) => (
  dossier
  && dossier.regulation
  && specificFields[dossier.regulation]
  && specificFields[dossier.regulation].includes(field)
);

/**
 * Internal fields are ignored by default,
 * but can be included by passing the 3rd parameter (includeInternals) as true
 */
export const getChangedFields = (newVersion, previous, includeInternals = false) => (diff(prepare(newVersion, previous), prepare(previous, newVersion)) || [])
  // This changes the difference object to a dot-notated string
  // And replaces array values from `children.0.birthName` to `children[0].birthName`
  .map((difference) => difference.path.join('.').replace(/\.([0-9]+)\./, (match, p1) => `[${p1}].`))
  // The `diff` utility returns arrays in a reverse order (See https://github.com/flitbit/diff/issues/156)
  // So we manually sort any children fields to give them back in the original order
  .sort((a, b) => {
    if (!a.includes('children') || !b.includes('children')) return 0;

    const fieldA = fieldToPath(a);
    const fieldB = fieldToPath(b);
    if (fieldA[0] === 'children' && fieldB[0] === 'children') return fieldA[1] - fieldB[1];
    return 0;
  })
  // The field should exist in the target version or don't exist in the previous one (specific to the target version or common field, e.g. name)
  .filter((field) => (fieldExists(newVersion, field) || !fieldExists(previous, field)))
  // Keep or strip internal fields
  .filter((field) => includeInternals || !(field.startsWith('_') || field.includes('._') || excludedFields.includes(field)))
  // Filter out any fields that have an "empty" (null, false, undefined) value on both newVersion and previous side
  .filter((field) => !(!getFieldValue(newVersion, field) && !getFieldValue(previous, field)));

/**
 * Applies a value on the object on a given field path.
 *
 * @param {object} target
 * @param {string} field
 * @param {any} value
 */
export const applyValue = (target, field, value) => {
  const preparedTarget = { ...target };
  // The children property could be `null`, which would result in issues with applying values to a child.
  // Therefor we prepare the object with an empty child.
  if (target.children === null && field.startsWith('children')) preparedTarget.children = [{}];
  if (target.partner === null && field.startsWith('partner')) preparedTarget.partner = {};
  if (target.regulationSpecifics === null && field.startsWith('regulationSpecifics')) preparedTarget.regulationSpecifics = {};

  applyChange(preparedTarget, {
    kind: 'N',
    path: fieldToPath(field),
    rhs: value
  });

  return preparedTarget;
};

/**
 * Extract the index number from an array in dot paths
 * E.g. a field `children[1].birthName` would return '1'
 *
 * @param {string} field
 */
export const getArrayKeyFromField = (field) => field.match(/\[([0-9]+)\]/)[1];

export const hasNestedAttribute = (object, nestedAttr) => {
  if (nestedAttr.indexOf('[') >= 0) {
    const splitArrAttribute = nestedAttr.split(/[[\]]/g);
    const arrayAttr = splitArrAttribute[0];
    const index = splitArrAttribute[1];
    return object[arrayAttr] && object[arrayAttr][index];
  }
  return object[nestedAttr];
}

/**
 * Merge two versions to propose a propagated version and only for the given fields
 *
 * @param {object} currentVersion
 * @param {object} previousVersion
 * @param {array} fields
 */
export const getPropagationVersion = (baseline, changedVersion, fields) => {
  const reducer = (propagationVersion, field) => {
    // If the current field is a member of a nested object and that object itself was not added,
    // do not propagate if currentVersion does not have the object.
    // For example: if currentVersion has no partner object, skip propagation in case
    // partner wasn't added as a whole to begin with
    if (field.indexOf('.') >= 0) {
      const nestedAttr = field.substr(0, field.indexOf('.'));
      if (fields.indexOf(nestedAttr) < 0 && !hasNestedAttribute(propagationVersion, nestedAttr)) {
        return propagationVersion;
      }
    }

    const proposedValue = applyValue(propagationVersion, field, getFieldValue(changedVersion, field));

    return proposedValue;
  }

  const initial = {
    children: [],
    partner: null,
    ...JSON.parse(JSON.stringify(baseline))
  };

  return fields.reduce(reducer, initial);
}

export const removeNullChildren = (version) => ({
  ...version,
  children: version.children ? version.children.filter((child) => !!child) : []
});
