import axios from 'axios';

export const USER_TOKEN_KEY = 'vdbs_auth_token';

export const repositoryApi = `${process.env.REACT_APP_API_REPO || process.env.REACT_APP_API_URL}/jflow/repository-api/v0.0.1`;
export const testApi = `${process.env.REACT_APP_API_TEST || process.env.REACT_APP_API_URL}/jflow/test-api/v0.0.1`;
export const vdbsApi = `${process.env.REACT_APP_API_VDBS || process.env.REACT_APP_API_URL}/vdbs/v1`;
export const websocketUrl = `${process.env.REACT_APP_API_TEST || process.env.REACT_APP_API_URL}/jflow/eventbus`;

export const authUrl = `${process.env.REACT_APP_API_VDBS || process.env.REACT_APP_API_URL}/vdbs/saml/login`;

export const getToken = () => {
  const token = localStorage.getItem(USER_TOKEN_KEY);

  return token;
};

export const createCancellationToken = () => axios.CancelToken.source();

export const api = axios.create({
  // baseURL: ,
});

export const getServiceStatus = async () => {
  if (!process.env.REACT_APP_GREEN_URL || process.env.REACT_APP_GREEN_URL === '') return 'RUNNING';

  const response = await axios.get(`${process.env.REACT_APP_GREEN_URL}/status`);

  return response.data;
};

export const isNetworkError = (err) => !!err.isAxiosError && !err.response;

const getHeaders = (config) => {
  if (!getToken()) {
    return config.headers;
  }
  return {
    ...config.headers,
    Authorization: `Bearer ${getToken()}`
  };
};

export const get = (config) => api({
  method: 'GET',
  withCredentials: true,
  ...config,
  headers: getHeaders(config)
});

export const put = (config) => api({
  method: 'PUT',
  ...config,
  headers: getHeaders(config)
});

export const post = (config) => api({
  method: 'POST',
  ...config,
  headers: getHeaders(config)
});

export const remove = (config) => api({
  method: 'DELETE',
  ...config,
  headers: getHeaders(config)
});

const fetchMap = {};

/**
 * Keeps track of api calls to single-fetch endpoints to see if they're already triggered.
 * This can be used to avoid calling the same endpoints multiple times
 *
 * @param {string} resource
 * @param {string} id
 */
export const isCalled = (resource, id) => {
  if (fetchMap[resource] && fetchMap[resource].includes(id)) {
    return true;
  }

  if (!fetchMap[resource]) {
    fetchMap[resource] = [id];
  } else {
    fetchMap[resource].push(id);
  }

  return false;
};

export default {
  get,
  put,
  post,
  remove
};
