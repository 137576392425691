import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import NavBar from './NavBar';

const HeaderBox = styled('div')`
  min-width: ${(props) => (props.collapsed ? 64 : 324)}px;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0px;
  z-index: 90;
  height: 100vh;
  transition: height 0.25s ease-in-out 0s;
`;

const Header = ({ children, collapsed }) => (
  <HeaderBox collapsed={collapsed}>
    <Wrapper>
      <NavBar />
      {children}
    </Wrapper>
  </HeaderBox>
);

Header.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.object,
};

export default Header;
