import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Input, Label, FormFeedback, FormText, FormError } from '.';
import FieldTooltip from '../tooltip/FieldTooltip';

const TextField = (props) => {
  const {
    required,
    label,
    id,
    placeholder,
    type,
    feedback,
    helptext,
    input,
    pattern,
    tooltip,
    error: fieldError,
    meta: { touched, error },
  } = props;

  return (
    <>
      {label && <Label htmlFor={input && input.name} required={required}>{label}</Label>}
      {tooltip && <FieldTooltip placement="top" content={tooltip} iconColor="gray" iconSize="sm" />}
      <Input
        {...input}
        {...props}
        type={type}
        id={id}
        pattern={pattern}
        placeholder={placeholder}
        error={error}
        touched={touched}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {touched && (error || fieldError) && <FormError data-testid="text-field-error"><FormattedMessage id={(error || fieldError)} /></FormError>}
    </>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  field: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  input: PropTypes.object,
  tooltip: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  autosize: PropTypes.bool,
};

export default TextField;
