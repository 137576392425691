import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label, FormFeedback, FormText, FormError } from '../form';
import Select from './Select';

const emptyObject = {};

const SelectField = (props) => {
  const {
    disabled,
    label,
    placeholder,
    type,
    feedback,
    helptext,
    field,
    options,
    isMulti,
    required,
    onChangeSelect,
    form: {
      setFieldTouched,
      setFieldValue,
      touched,
      errors,
      submitCount,
      isSubmitting
    },
    ...restProps
  } = props;

  let selectValue = '';
  if (isMulti) {
    selectValue = options.filter((opt) => ((field.value && Array.isArray(field.value)) ? field.value.indexOf(opt.value) >= 0 : false));
  } else {
    selectValue = options.find((opt) => opt.value === field.value);
  }

  // Workaround to handle form submission errors
  // as defined in https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600
  const isTouched = touched[field.name] || submitCount > 0;

  return (
    <>
      {label && (
        <Label htmlFor={field.name} required={required}>
          <FormattedMessage id={label} defaultMessage={label} />
        </Label>
      )}
      <Select
        {...restProps}
        {...field}
        id={field.name}
        isMulti={isMulti}
        isDisabled={disabled || isSubmitting}
        type={type}
        placeholder={placeholder || ''}
        options={options}
        value={selectValue || emptyObject}
        // We need to use the manual Formik `setFieldValue` function,
        // since react-select returns the new value instead of a change Event
        // See also https://codesandbox.io/s/jRzE53pqR for the "official" react-select example
        onChange={(opt) => {
          setFieldValue(field.name, isMulti ? Object.values(opt).map((item) => item.value) : (opt && opt.value) || null);
          if (onChangeSelect) onChangeSelect(opt);
        }}
        onBlur={() => setFieldTouched(field.name, true)}
        error={errors[field.name]}
        touched={isTouched}
        isClearable={!required}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {isTouched && errors[field.name] && (
        <FormError data-testid="select-field-error">
          <FormattedMessage id={errors[field.name]} values={{ field: label }} />
        </FormError>
      )}
    </>
  );
};

SelectField.defaultProps = {
  disabled: false,
  isMulti: false,
};

SelectField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func,
    submitCount: PropTypes.number,
  }),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
  // Options should be an array of objects.
  // Each option object should have a `value` and a `label` property
  options: PropTypes.arrayOf(PropTypes.object),
  onChangeSelect: PropTypes.func,
};

export default SelectField;
