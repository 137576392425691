import axios from 'axios';
import { UPDATE_SERVICE_STATUS, SERVICE_POWERUP_PENDING } from '../actionTypes';
import { getServiceStatus } from '../utilities/apiUtilities';

export const updateServiceStatus = (serviceState) => ({
  type: UPDATE_SERVICE_STATUS,
  payload: serviceState
});

export const servicePowerupPending = () => ({
  type: SERVICE_POWERUP_PENDING,
  payload: true
});

export const powerupService = () => async (dispatch) => {
  await axios.post(`${process.env.REACT_APP_GREEN_URL}/powerup`);
  return dispatch(servicePowerupPending());
};

export const serviceHeartbeat = () => async (dispatch) => {
  const status = await getServiceStatus();

  if (status !== 'RUNNING') {
    setTimeout(() => {
      dispatch(serviceHeartbeat());
    }, 30000);
  }

  return dispatch(updateServiceStatus(status));
};
