import styled from 'styled-components/macro';

export default styled('div')`
  font-size: 17.2px;
  font-weight: bold;
  /* background: ${(props) => props.theme.backgroundColors.lightgray}; */
  color: ${(props) => props.theme.textColors.default};
  text-transform: uppercase;
  padding: 0;

  i {
    margin-right: .75rem;
    margin-top: -6px;
    color: ${(props) => props.theme.backgroundColors.primary};
  }
`;
