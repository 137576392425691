import React, { useEffect, useState } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, MomentTypeProvider, CurrencyTypeProvider } from '../grid'
import { Button, ListView, TableSkeleton } from '../layout'
import ListHeader from '../layout/ListHeader'
import { COLLECTION_PAYMENTS } from '../../constants/collections'
import { DISPLAY_DATETIME } from '../../constants/dateFormats'
import { getSortableFullName } from '../../utilities/employeeUtilities'
import Dialog from '../layout/Dialog'
import { PDirektSentAtTypeProvider, PDirektStatusProvider } from '../grid/Formatters'
import PollingProgressStatus from '../status/PollingProgressStatus'
import {
  getPaymentProgress,
  getReimbursementPaymentProgress,
  searchByPaymentId,
  sendPayments,
  sendReimbursementPayments,
} from '../../api/pdirektApi'
import { FIELD_ENUM } from '../../constants/fields'

export const viewType = {
  REIMBURSEMENT: 'REIMBURSEMENT',
  ALLOWANCE: 'ALLOWANCE',
}

export const paymentType = {
  REIMBURSEMENT: 'REIMBURSEMENT',
  INSTALMENT: 'INSTALMENT',
  ALLOWANCE: 'ALLOWANCE',
}

const columns = [
  {
    name: 'type',
    label: 'label.type',
    getCellValue: ({ type }) => <FormattedMessage id={`label.${type.toLowerCase()}`} />,
    width: 120,
  },
  {
    name: 'employee.sortableLastName',
    label: 'label.employee',
    getCellValue: ({ employee }) => getSortableFullName(employee),
  },
  {
    name: 'salaryComponent',
    label: 'label.salary_component',
  },
  {
    name: 'salaryDescription',
    label: 'label.description',
  },
  {
    name: 'pDirektStatus',
    label: 'label.pdirekt_status',
  },
  {
    name: 'sentToPDirektAt',
    label: 'label.sent_to_pdirekt_at',
  },
  {
    name: 'amount',
    label: 'label.amount',
    width: 120,
    align: 'right',
  },
]


const filterFields = [
  {
    value: 'pDirektStatus',
    name: 'pDirektStatus',
    label: 'label.status',
    field: FIELD_ENUM,
    enum: [
      { value: 'NEW', label: 'label.new' },
      { value: 'SENT', label: 'label.sent' },
      { value: 'UNAUTHORIZED', label: 'label.unauthorized' },
      { value: 'BAD_REQUEST', label: 'label.bad_request' },
      { value: 'UNKNOWN', label: 'label.unknown' },
      { value: 'ERROR', label: 'label.error' },
    ],
  },
]

const ConfirmableActionButton = ({ modalMessage, buttonLabel, onConfirm, disabled }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      {modal && (
        <Dialog
          onCancel={() => setModal(false)}
          onConfirm={() => {
            onConfirm()
            setModal(false)
          }}
          header={<FormattedMessage id="title.confirm" />}
        >
          <p>
            <FormattedMessage id={modalMessage} />
          </p>
        </Dialog>
      )}
      <Button key="generate" color="primary" onClick={() => setModal(true)} disabled={disabled}>
        {typeof buttonLabel === 'string' ? <FormattedMessage id={buttonLabel} /> : buttonLabel}
      </Button>
    </>
  )
}

const PDirektList = ({ type }) => {
  const limit = 25
  const [isPosting, setPosting] = useState(false)
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [items, setItems] = useState(null)
  const [remaining, setRemaining] = useState(0)
  const { paymentId } = useParams()
  const navigate = useNavigate()

  const getItems = async (query = {}) => {
    const response = await searchByPaymentId(paymentId, {
      limit,
      offset: 0,
      ...query,
    })

    setOffset(response.data.offset)
    setItems(response.data.results)
    setTotal(response.data.total)
  }

  const handleRowClick = (row) => {
    switch (row.type) {
      case paymentType.ALLOWANCE:
        navigate(`/payments/${row.id}/${row.employeeEntityId}`)
        break
      case paymentType.REIMBURSEMENT:
        navigate(`/reimbursements/${row.id}`)
        break
      case paymentType.INSTALMENT:
        navigate(`/advances/${row.id}`)
        break
      default:
        return null
    }

    return null
  }

  const sendAll = () => {
    if (type === viewType.ALLOWANCE) {
      return sendPayments(paymentId).then(setPosting(true))
    }
    if (type === viewType.REIMBURSEMENT) {
      return sendReimbursementPayments(paymentId).then(setPosting(true))
    }

    return null
  }

  const getStatus = () => {
    if (type === viewType.ALLOWANCE) {
      return getPaymentProgress(paymentId)
    }
    if (type === viewType.REIMBURSEMENT) {
      return getReimbursementPaymentProgress(paymentId)
    }

    return null
  }

  useEffect(() => {
    if (type === viewType.ALLOWANCE) {
      getPaymentProgress(paymentId).then(({ data: { processed, total } }) => setRemaining(total - processed))
    } else if (type === viewType.REIMBURSEMENT) {
      getReimbursementPaymentProgress(paymentId).then(({ data: { processed, total } }) => setRemaining(total - processed))
    }
  }, [paymentId, type])

  return (
    <ListView>
      <ListHeader
        collection={COLLECTION_PAYMENTS}
        title={
          <FormattedMessage
            id={`title.pdirekt_list_${type.toLowerCase()}`}
            values={{ total: <FormattedNumber value={total} />, subject: <FormattedMessage id="label.payments" /> }}
          />
        }
        actionButtons={
          <ConfirmableActionButton
            modalMessage="message.confirm_send_to_pdirekt"
            buttonLabel={<FormattedMessage id="button.send_to_pdirekt" values={{ remaining: <FormattedNumber value={remaining} /> }} />}
            disabled={isPosting || remaining === 0}
            onConfirm={() => sendAll()}
          />
        }
        onFilterSubmit={getItems}
        filterFields={filterFields}
      />
      {isPosting && (
        <PollingProgressStatus
          labelProcessed="title.payments_pdirekt_sent"
          labelTotal="title.payments_pdirekt_to_send"
          getStatus={getStatus}
        />
      )}
      {items === null && <TableSkeleton rows={25} />}
      <Grid
        rows={items || []}
        columns={columns}
        columnExtensions={columns}
        onFetchRows={getItems}
        onRowClick={handleRowClick}
        params={{ limit, offset }}
        totalCount={total}
      >
        <MomentTypeProvider dateFormat={DISPLAY_DATETIME} for={['createdAt']} />
        <PDirektStatusProvider />
        <PDirektSentAtTypeProvider />
        <CurrencyTypeProvider currency="EUR" for={['amount']} />
      </Grid>
    </ListView>
  )
}

export default PDirektList
