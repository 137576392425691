export const TYPE_AND = 'and';
export const TYPE_ARRAY = 'array';
export const TYPE_BOOLEAN = 'boolean';
export const TYPE_DATE = 'date';
export const TYPE_DATE_TIME_DAY = 'dateDay';
export const TYPE_DECIMAL = 'decimal';
export const TYPE_NUMBER = 'number';
export const TYPE_OBJECT = 'object';
export const TYPE_OR = 'or';
export const TYPE_TEXT = 'text';
export const TYPE_YEAR_MONTH = 'yearMonth';
