import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import {
  OPERATOR_EQUALS,
  OPERATOR_NOTEQUALS,
  OPERATOR_GREATERTHAN,
  OPERATOR_LESSTHAN,
  OPERATOR_LESSTHANEQUALS,
  OPERATOR_GREATERTHANEQUALS,
} from '../../constants/operators'
import { removeCondition, updateCondition } from '../../actions/expressionActions'
import { withContext } from '../withContext'
import { Input, Label } from '../../../components/form'
import Select from '../../../components/form/Select'
import { ButtonGroup } from '../../../components/layout/Buttons'
import RemoveButton from '../../../components/layout/RemoveButton'
import ConditionItem from '../../layout/ConditionItem'
import LabelSegment from '../../layout/LabelSegment'
import OperatorSegment from '../../layout/OperatorSegment'
import CriteriaSegment from '../../layout/CriteriaSegment'
import InputCriterium from '../../layout/InputCriterium'
import DateInput from '../../../components/form/DateInput'

const RelativeCriterium = styled('div')`
  display: flex;
  flex-direction: row;

  ${Input} {
    width: 60px;
    margin-right: 0.5rem;
  }

  > div {
    flex: 3;
    min-width: 180px;
  }
`

const relativeOptions = [
  { value: '-w', positive: false, unit: 'w', label: 'weken in het verleden' },
  { value: '+w', positive: true, unit: 'w', label: 'weken in de toekomst' },
  { value: '-W', positive: false, unit: 'W', label: 'weken in het verleden - op maandag' },
  { value: '+W', positive: true, unit: 'W', label: 'weken in de toekomst - op maandag' },
  { value: '-m', positive: false, unit: 'm', label: 'maanden in het verleden' },
  { value: '+m', positive: true, unit: 'm', label: 'maanden in de toekomst' },
  { value: '-M', positive: false, unit: 'M', label: 'maanden in het verleden - 1e v/d maand' },
  { value: '+M', positive: true, unit: 'M', label: 'maanden in de toekomst - 1e v/d maand' },
  { value: '-y', positive: false, unit: 'y', label: 'jaren in het verleden' },
  { value: '+y', positive: true, unit: 'y', label: 'jaren in de toekomst' },
  { value: '-Y', positive: false, unit: 'Y', label: 'jaren in het verleden - 1 januari' },
  { value: '+Y', positive: true, unit: 'Y', label: 'jaren in de toekomst - 1 januari' },
]

const operators = [
  { value: OPERATOR_EQUALS, operator: OPERATOR_EQUALS, label: <FormattedMessage id="label.operator_eq" /> },
  { value: `${OPERATOR_EQUALS}_relative`, operator: OPERATOR_EQUALS, label: <FormattedMessage id="label.operator_eq_relative" />, relative: true },
  { value: OPERATOR_NOTEQUALS, operator: OPERATOR_NOTEQUALS, label: <FormattedMessage id="label.operator_neq" /> },
  { value: OPERATOR_GREATERTHAN, operator: OPERATOR_GREATERTHAN, label: <FormattedMessage id="label.operator_gt_date" /> },
  { value: OPERATOR_GREATERTHANEQUALS, operator: OPERATOR_GREATERTHANEQUALS, label: <FormattedMessage id="label.operator_gte_date" /> },
  {
    value: `${OPERATOR_GREATERTHAN}_relative`,
    operator: OPERATOR_GREATERTHANEQUALS,
    label: <FormattedMessage id="label.operator_gt_date_relative" />,
    relative: true,
  },
  // { value: `${OPERATOR_NEXT}`, operator: OPERATOR_NEXT, label: <FormattedMessage id="label.operator_next_dates" />, relative: true },
  { value: OPERATOR_LESSTHAN, operator: OPERATOR_LESSTHAN, label: <FormattedMessage id="label.operator_lt_date" /> },
  { value: OPERATOR_LESSTHANEQUALS, operator: OPERATOR_LESSTHANEQUALS, label: <FormattedMessage id="label.operator_lte_date" /> },
  { value: `${OPERATOR_LESSTHAN}_relative`, operator: OPERATOR_LESSTHAN, label: <FormattedMessage id="label.operator_lt_date_relative" />, relative: true },
]

const DateCondition = ({ condition, context, parent, field }) => {
  const [relative, setRelative] = useState(false)
  const [relativeValue, setRelativeValue] = useState(false)
  const [relativeUnit, setRelativeUnit] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (condition.value && (condition.value.startsWith('+') || condition.value.startsWith('-'))) {
      setRelative(true)
      const value = condition.value.replace(/^[+-]/, '')
      const unit = value.replace(/[0-9]/g, '')
      const number = value.replace(/[a-zA-Z]/g, '')
      const positive = condition.value.startsWith('+');
      setRelativeValue(number)
      setRelativeUnit(relativeOptions.find(option => option.unit === unit && option.positive === positive))
    }
  }, [])

  useEffect(() => {
    // serialize relative value
    if (relativeValue && relativeUnit) {
      const value = `${relativeUnit.positive ? '+' : '-'}${relativeValue}${relativeUnit.unit}`
      dispatch(updateCondition(context.name, { ...condition, value }))
    }
  }, [relativeValue, relativeUnit])

  const onOperatorChange = ({ operator, relative }) => {
    setRelative(!!relative)
    dispatch(updateCondition(context.name, { ...condition, operator }))
  }

  const onValueChange = (event) => {
    const value = event.target ? event.target.value : event
    dispatch(updateCondition(context.name, { ...condition, value }))
  }

  const onRelativeValueChange = (event) => setRelativeValue(event.target.value)
  const onRelativeUnitChange = (event) => setRelativeUnit(relativeOptions.find((o) => o.value === event.value))

  const operatorValue = relative ? `${condition.operator}_relative` : condition.operator

  return (
    <ConditionItem>
      <LabelSegment>
        <Label>
          <FormattedMessage id={field.label} />
        </Label>
      </LabelSegment>
      <OperatorSegment>
        <Select value={operators.find((op) => op.value === operatorValue)} onChange={onOperatorChange} options={operators} />
      </OperatorSegment>
      <CriteriaSegment>
        <InputCriterium>
          {relative === true && (
            <RelativeCriterium>
              <Input type="number" onChange={onRelativeValueChange} value={relativeValue} size={4} />
              <Select value={relativeUnit} onChange={onRelativeUnitChange} options={relativeOptions} />
            </RelativeCriterium>
          )}
          {relative !== true && <DateInput onChange={onValueChange} onChangeDefault={onValueChange} value={condition.value} />}
        </InputCriterium>
        <ButtonGroup>
          <RemoveButton onClick={() => dispatch(removeCondition(context.name, condition, parent, context.onSubmit))} />
        </ButtonGroup>
      </CriteriaSegment>
    </ConditionItem>
  )
}

export default withContext(DateCondition)
