export const ACCOUNT = 'accounts';
export const ACCOUNTREPORT = 'accountreports';
export const ADVANCE = 'advances';
export const ADVANCE_BALANCES = 'advancebalances';
export const ALLOWANCE = 'allowances';
export const EMPLOYEE = 'employees';
export const EMPLOYEETIMELINE = 'employeetimeline'; // TODO: Probaly need to rename this to history based on entity
export const ENTIYTIMELINE = 'entitytimeline'; // TODO: Probaly need to rename this to history based on entity
export const FLOW = 'flows';
export const FLOWVERSION = 'flowversions';
export const INSTRUCTION = 'instructions';
export const MODEL = 'models';
export const PAYMENT = 'payments';
export const PERIOD = 'periods';
export const AUDIT = 'audits';
export const USER = 'users';
export const CORRECTION = 'corrections';
export const CORRECTIONVERSION = 'correctionversions';
export const REIMBURSEMENT = 'reimbursements';
export const REIMBURSEMENT_EMAILS = 'reimbursementemails';
export const REIMBURSEMENT_PAYMENT = 'reimbursementpayments';
export const REIMBURSEMENT_PERIODS = 'reimbursementperiods';
export const LISTVIEW = 'listviews';

// Datatables are separted into two entities
// since the list calls have different responses than single calls
export const DATATABLE = 'datatables';
export const DATATABLELIST = 'datatablelist';
export const DATATABLEVERSION = 'datatableversions';
