import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import debounce from 'lodash.debounce'
import { Input } from '.'
import { Icon } from '../layout'
import { getParams } from '../../selectors/collectionSelectors'

const SearchIcon = styled(Icon)`
  position: absolute;
  left: 10px;
  top: 5px;
  z-index: 2;
  color: ${(props) => props.theme.textColors.lightgray};
`

const Search = styled('div')`
  display: inline-block;
  position: relative;
  margin: 0 1rem 0 0;
  width: 300px;

  ${Input} {
    padding-left: 2rem;
  }
`

const KeywordSearchField = (props) => {
  const { collection, onChange, autoFocus } = props
  const [value, setValue] = useState(null)
  const params = useSelector(state => getParams(state, collection))
  const debouncer = useCallback(debounce(onChange, 200), [params])
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (value !== null) debouncer(value)
  }, [value])

  const handleOnChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <Search>
      <SearchIcon name="Search" size="sm" />
      <Input placeholder={formatMessage({ id: 'placeholder.search' })} onChange={handleOnChange} value={value || ''} autoFocus={autoFocus || false} />
    </Search>
  )
}

export default KeywordSearchField
