import { createGlobalStyle } from 'styled-components/macro';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  html, body {
      height: 100%;
      margin: 0;
      font-family: 'ROsanswebtext', Calibri, sans-serif;
      font-size: 15px;
      line-height: 1.5;
      text-align:left;
      background-color: white;
      overflow-x: hidden;
  }

  #root {
      height: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: black;
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  abbr[title],
  abbr[data-original-title] {
      text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-weight: 900;
  }

  small {
    font-size: 12px;
  }

  sub,
  sup {
    position: relative;
    font-size: 11px;
    line-height: 0;
    vertical-align: baseline;
  }

  sub { bottom: -.25em; }
  sup { top: -.5em; }

  a {
    color: ${() => theme.colors.blue};
    text-decoration: none;
    background-color: transparent;

    :hover {
      color: ${() => theme.colors.blue};
      text-decoration: none;
    }
  }


  a:not([href]) {
    color: inherit;
    text-decoration: none;

    :hover {
      color: inherit;
      text-decoration: none;
    }
  }



  pre,
  code,
  kbd,
  samp {
    font-family: monospace;
    font-size: 15px;
  }

  pre {
      margin-top: 0;
      margin-bottom: 1rem;
      overflow: auto;
  }

  figure {
      margin: 0 0 1rem;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 5px;
    padding-bottom: 5px;
    color: #333;
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: 5px;
  }

  button {
      border-radius: 0;
  }

  button:focus {
    outline: none
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: 15px;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  select {
    word-wrap: normal;
  }

  button,
  [type="button"], [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 15px;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }


  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }

  body .dx-widget {
      font-family: 'ROsanswebtext', Calibri, sans-serif;
      font-size: 15px;
  }

  body .dx-pivotgrid .dx-total,
  body .dx-pivotgrid .dx-grandtotal {
      background-color: #f6f7f9;
      font-weight: bold;
  }

  body .dx-pivotgrid .dx-total,
  body .dx-pivotgrid .dx-data-header,
  body .dx-pivotgrid .dx-column-header,
  body .dx-pivotgrid .dx-area-description-cell {
      background-color: #f6f7f9;
  }

  body .dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td {
      border-color: #D0D2D5;
  }

  body .dx-pivotgrid .dx-pivotgrid-border .dx-data-header,
  body .dx-pivotgrid .dx-pivotgrid-border .dx-filter-header,
  body .dx-pivotgrid .dx-pivotgrid-border .dx-area-description-cell,
  body .dx-pivotgrid .dx-pivotgrid-border .dx-area-row-cell {
      border-color:#D0D2D5;
  }

  body .dx-pivotgrid .dx-pivotgrid-area td {
      color: #7B7E83;
  }

  body .dx-pivotgrid .dx-pivotgrid-area-data tbody td {
      color: #333;
  }

  body .dx-popup-wrapper > .dx-overlay-content {
      border: none;
  }

  body .dx-popup-title {
      border: none;
  }

  .form-group {
      margin-bottom: .75rem;
  }
`;

export default GlobalStyle;
