import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { darken } from 'polished';
import { FormattedMessage } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { StatusIcon } from '../layout/Icon';

export const SmallPrintCell = styled(Table.Cell)`
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => (props.muted ? props.theme.textColors.lightestgray : props.theme.textColors.gray)};
`;

export const BoldCell = styled(Table.Cell)`
  font-weight: bold;
  color: ${(props) => (props.muted ? props.theme.textColors.lightestgray : 'inherit')};
`;

export const ItalicCell = styled(Table.Cell)`
  color: ${(props) => (props.muted ? props.theme.textColors.lightestgray : 'inherit')};
  font-style: italic;
  max-width: ${(props) => (props.tableColumn.width ? `${props.tableColumn.width}px` : 'auto')};
  overflow: ${(props) => (props.attr && props.attr.overflow ? 'hidden' : 'auto')};
  text-overflow: ${(props) => (props.attr && props.attr.overflow ? 'ellipsis' : 'inherit')};
`;

export const StatusIconCell = (props) => (
  <Cell {...props}>
    <StatusIcon status={props.value} selected={props.row.selected} />
  </Cell>
);

StatusIconCell.propTypes = {
  row: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export const StatusCell = (props) => (
  <Table.Cell {...props}>
    <StatusIcon status={props.value} selected={props.row.selected} />
    &nbsp;
    <FormattedMessage id={`label.status_${props.value}`} />
  </Table.Cell>
);

StatusCell.propTypes = {
  row: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export const Cell = styled(Table.Cell)`
  color: ${(props) => (props.muted ? props.theme.textColors.lightestgray : 'inherit')};
  font-weight: ${(props) => (props.attr && props.attr.fontBold ? 'bold' : 'normal')};
  max-width: ${(props) => (props.tableColumn.width ? `${props.tableColumn.width}px` : 'auto')};
  overflow: ${(props) => (props.attr && props.attr.overflow ? 'hidden' : 'auto')};
  text-overflow: ${(props) => (props.attr && props.attr.overflow ? 'ellipsis' : 'inherit')};
`;

export const EmployeeStatusIcon = styled('div')`
  display: inline-block;
  width: 15px;
  height: 15px;
  background: ${(props) => (props.column.statusCode && darken(0.1, props.theme.employeeStatusColors[props.column.statusCode.split('.')[0]] || '#ffffff')) || 'transparent'};
  border-radius: 50%;
  margin: 0 4px -2px 0;
`;

export const EmployeeStatusCell = (props) => (
  <Cell {...props}>
    <EmployeeStatusIcon {...props} />
    &nbsp;
    {props.value}
  </Cell>
);

EmployeeStatusCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.object,
  ]),
};
