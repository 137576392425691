export const APPROVED = 'APPROVED';
export const DRAFT = 'DRAFT';
export const REJECTED = 'REJECTED';
export const REVIEW = 'REVIEW';

export const dataSourceStatus = {
  DRAFT: 'DRAFT',
  REVIEW: 'REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};
