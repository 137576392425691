import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AsyncSelect } from './Select';
import { fetchUser, fetchUsers } from '../../actions/userActions';
import { getUser } from '../../selectors/userSelectors';
import { getFullName } from '../../utilities/userUtilities';
import { sortByProperyAsc } from '../../utilities/arrayUtilities';

const mapToValue = (user) => ({
  label: getFullName(user),
  value: user.id
});

class UserSelect extends Component {
  componentDidMount = () => {
    const { fetchUser, value } = this.props;
    if (value) fetchUser(value);
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { disabled, fetchUsers, filter, label, user, value, ...restProps } = this.props;

    return (
      <AsyncSelect
        isDisabled={disabled}
        cacheOptions
        defaultOptions={!disabled}
        value={user}
        loadOptions={(value) => fetchUsers({ limit: 25, offset: 0, keywords: value })
          .then((response) => {
            const options = response.results
              .filter(filter)
              .map((user) => mapToValue(user))
              // eslint-disable-next-line no-confusing-arrow
              .sort((a, b) => sortByProperyAsc(a, b, 'label'));

            return options;
          })}
        {...restProps}
      />
    );
  }
}

UserSelect.defaultProps = {
  disabled: false,
  isMulti: false,
  filter: () => true,
  required: false,
  user: null
};

UserSelect.propTypes = {
  disabled: PropTypes.bool,
  fetchUser: PropTypes.func,
  fetchUsers: PropTypes.func,
  filter: PropTypes.func,
  isMulti: PropTypes.bool,
  label: PropTypes.object,
  required: PropTypes.bool,
  user: PropTypes.object,
  value: PropTypes.string,
};

const mapState = (state, { value }) => {
  const user = getUser(state, value);
  return {
    user: user ? mapToValue(user) : null
  };
};

const mapDispatch = (dispatch) => ({
  fetchUser: (id) => dispatch(fetchUser(id)),
  fetchUsers: (params) => dispatch(fetchUsers(params))
});

export default connect(mapState, mapDispatch)(UserSelect);
