import React from 'react';
import ReactSelect from 'react-select';
import Async from 'react-select/async';
import { FormattedMessage } from 'react-intl';
import theme from '../layout/theme';

const styles = ({ error, touched, width }) => ({
  control: (style, state) => ({
    ...style,
    border: `1px solid ${touched && error ? theme.input.borderColorError : theme.input.borderColor}`,
    backgroundColor: state.isDisabled ? '#f6f7f9' : 'white',
    height: '34px',
    minHeight: '34px',
    fontSize: '15px',
    width
  }),
  singleValue: (style, state) => ({
    ...style,
    color: state.isDisabled ? '#202124' : '#202124',
  }),
});

export const AsyncSelect = (props) => <Async {...props} styles={styles(props)} />;

export default (props) => (
  <ReactSelect
    noOptionsMessage={() => (
      <FormattedMessage id="message.no_select_options" />
    )}
    {...props}
    styles={props.styles || styles(props)}
  />
);
