import { TYPE_DATE, TYPE_NUMBER, TYPE_TEXT, TYPE_BOOLEAN } from '../constants/types';

/**
 * Get a certain (nested) attribute from a model by dot-notation
 *
 * @param {array} attributes
 * @param {string} name
 */
export const getAttributeFromModel = (model, name) => {
  // In case the field is part of a nested array (e.g. `children[0].name`)
  // Remove the [0] part
  const cleanName = name.replace(/([0-9]+)\./g, '').replace(/\[.*\]/g, '');
  // Devide all parts between dots
  const parts = cleanName.split('.');

  // Get the first part and remove it from the parts array
  const currentPart = parts.shift();
  const attribute = model && model.attributes.find((attr) => attr.name === currentPart);

  if (!attribute) {
    return null;
  }

  // If there are no parts left (after the shift), this must be the last part,
  // so return the found attribute
  if (parts.length === 0) {
    return attribute;
  }

  // If there are still parts left, try it again with the leftover parts
  return getAttributeFromModel(attribute.model, parts.join('.'));
};

const convertType = (datatype) => {
  switch (datatype) {
    case 'BOOLEAN': return TYPE_BOOLEAN;
    case 'DATE': return TYPE_DATE;
    case 'INTEGER': return TYPE_NUMBER;
    default: {
      return TYPE_TEXT;
    }
  }
};

/**
 * Flattens the nested model object to a single-level array where each field
 * is passed in a dot-notation.
 *
 * @param {object} model
 * @param {string} prefix
 */
export const getModelAttributes = (model, prefix = '', labelSuffix = '') => {
  if (!model || !model.attributes) return [];
  return model.attributes
    .reduce((map, attr) => {
      const baseColumn = {
        name: `${prefix}${attr.name}`,
        type: convertType(attr.datatype),
        label: `${attr.label}${labelSuffix}` || '',
        getCellValue: (row) => {
          if (prefix === 'children.') { // eslint-disable-line no-else-return
          // TODO: How to deal with children values?
            return null;
          }

          if (prefix !== '') {
            const parentObject = row[prefix.slice(0, -1)];
            return parentObject ? parentObject[attr.name] : null;
          }
          return row[attr.name];
        }
      };

      // If tha attribute is a nested type, we must pass all child attributes
      // as separate items
      if (['ARRAY', 'OBJECT'].includes(attr.datatype)) {
        map.push({ ...baseColumn, type: attr.datatype.toLowerCase() });
        return [...map, ...getModelAttributes(attr.model, `${attr.name}.`, ` ${attr.label}`)];
      }

      // If the attribute is of type datasource, we must pass the reference data
      if (attr.datasource) {
        return [...map, {
          ...baseColumn,
          reference: attr.datasource.datasourceId,
          labelColumn: attr.datasource.labelColumn,
          valueColumn: attr.datasource.valueColumn,
        }];
      }

      return [...map, baseColumn];
    }, []);
};
