import { schema } from 'normalizr';
import {
  ACCOUNT,
  ACCOUNTREPORT,
  ALLOWANCE,
  ADVANCE,
  ADVANCE_BALANCES,
  EMPLOYEE,
  EMPLOYEETIMELINE,
  FLOW,
  FLOWVERSION,
  INSTRUCTION,
  MODEL,
  USER,
  DATATABLE,
  PAYMENT,
  PERIOD,
  DATATABLELIST,
  DATATABLEVERSION,
  AUDIT,
  CORRECTION,
  CORRECTIONVERSION,
  REIMBURSEMENT,
  REIMBURSEMENT_EMAILS,
  REIMBURSEMENT_PAYMENT,
  REIMBURSEMENT_PERIODS,
  LISTVIEW,
} from '../constants/entities';

const accounts = new schema.Entity(ACCOUNT, {}, { idAttribute: 'id' });
const accountreports = new schema.Entity(ACCOUNTREPORT, {}, { idAttribute: 'id' });
const advances = new schema.Entity(ADVANCE, {}, { idAttribute: '_id' });
const advancebalances = new schema.Entity(ADVANCE_BALANCES, {}, { idAttribute: '_id' });
const audits = new schema.Entity(AUDIT, {}, { idAttribute: '_id' });
const models = new schema.Entity(MODEL, {}, { idAttribute: '_id' });
const allowances = new schema.Entity(ALLOWANCE, { idAttribute: 'id' });
const employees = new schema.Entity(EMPLOYEE, {}, { idAttribute: '_id' });
const employeetimeline = new schema.Entity(EMPLOYEETIMELINE, {}, { idAttribute: '_id' });
const users = new schema.Entity(USER, { idAttribute: 'id' });
const payments = new schema.Entity(PAYMENT, { idAttribute: 'id' });
const periods = new schema.Entity(PERIOD, {}, { idAttribute: 'period' });
const instructions = new schema.Entity(INSTRUCTION, {}, { idAttribute: '_id' });
instructions.define({ instructionsWhenTrue: [instructions], instructionsWhenFalse: [instructions], instructionsInLoop: [instructions] });
const flows = new schema.Entity(FLOW, { content: { instructions: [instructions] } }, { idAttribute: '_id' });
const flowversions = new schema.Entity(FLOWVERSION, { content: { instructions: [instructions] } }, { idAttribute: '_id' });
const datatablelist = new schema.Entity(DATATABLELIST, {}, { idAttribute: 'tableId' });
const datatableversions = new schema.Entity(DATATABLEVERSION, {}, { idAttribute: 'id' });
const datatables = new schema.Entity(DATATABLE, {}, {
  idAttribute: 'id',
  processStrategy: (value) => ({
    ...value,
    headers: value.records && value.records.length ? Object.keys(value.records[0]) : [],
    data: value.records ? value.records.map((row) => Object.values(row)) : [],
    numRows: value.records && value.records.length,
    overSize: value.records && (value.records.length > 200),
  })
});
const corrections = new schema.Entity(CORRECTION, {}, { idAttribute: 'id' });
const correctionversions = new schema.Entity(CORRECTIONVERSION, {}, { idAttribute: 'id' });
const reimbursements = new schema.Entity(REIMBURSEMENT, {}, { idAttribute: 'id' });
const reimbursementemails = new schema.Entity(REIMBURSEMENT_EMAILS, {}, { idAttribute: 'employeeEntityId' });
const reimbursementpayments = new schema.Entity(REIMBURSEMENT_PAYMENT, {}, { idAttribute: 'id' });
const reimbursementperiods = new schema.Entity(REIMBURSEMENT_PERIODS, {}, { idAttribute: 'period' });
const listviews = new schema.Entity(LISTVIEW, {}, { idAttribute: 'id' });

export default {
  accounts,
  accountreports,
  advances,
  advancebalances,
  allowances,
  datatables,
  datatablelist,
  datatableversions,
  employees,
  employeetimeline,
  flows,
  flowversions,
  instructions,
  models,
  payments,
  periods,
  users,
  audits,
  corrections,
  correctionversions,
  reimbursements,
  reimbursementemails,
  reimbursementpayments,
  reimbursementperiods,
  listviews,
};
