import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { components } from 'react-select'
import PropTypes from 'prop-types'
import Select from '../_form/Select'
import { getSelectedListViewOption, getListViewOptions } from '../../selectors/listViewSelectors'
import { setConditions } from '../../expressionbuilder/actions/expressionActions'
import { setColumns, setListView, setParam } from '../../actions/collectionActions'
import { fetchListViewsIfNeeded } from '../../actions/listViewActions'

const Heading = styled('h1')`
  font-size: 24px;
  margin-right: 1rem;
`

const styles = {
  container: (styles) => ({
    ...styles,
    minWidth: '400px',
    zIndex: 20,
  }),
  control: (styles) => ({
    ...styles,
    border: 'none',
    minWidth: '400px',
    boxShadow: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    maxWidth: '400px',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#212529',
    position: 'relative',
    transform: 'none',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    position: isSelected ? 'relative' : styles.position,
    transform: isSelected ? 'none' : styles.transform,
  }),
  input: (styles, { isSelected }) => ({
    ...styles,
    position: isSelected ? 'relative' : styles.position,
    transform: isSelected ? 'none' : styles.transform,
  }),
  singleValue: (styles) => ({
    ...styles,
    position: 'relative',
    transform: 'none',
    maxWidth: 'auto',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
}

const Placeholder = (props) => (
  <components.Placeholder {...props}>
    <Heading>{props.children}</Heading>
  </components.Placeholder>
)

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <Heading>{props.children}</Heading>
  </components.SingleValue>
)

const MenuList = (props) => <components.MenuList {...props}>{props.children}</components.MenuList>

const defaultSearch = { query: null, aggregate: true, keywords: null }

const ListViewSelect = ({ collection, placeholder, placeholderOnly, onChange }) => {
  const dispatch = useDispatch()

  const selected = useSelector((state) => getSelectedListViewOption(state, collection))
  const options = useSelector((state) => getListViewOptions(state, collection))

  useEffect(() => {
    dispatch(fetchListViewsIfNeeded())
  }, [])

  const handleChange = async (value) => {
    const search = value ? value.listView.searchQuery : defaultSearch
    const id = value ? value.listView.id : null

    await onChange(search)
    // We execute the "select" action after the onChange is handled
    // to make sure the query is already executed and we can safely set the "saved" value
    // in the collection reducer. This is necessary since we want to reset the "saved" value
    // on ANY change on the collection reducer.
    dispatch(setListView(collection, id))
    dispatch(setColumns(collection, search.columns))
    dispatch(setParam(collection, 'direction', search.direction))
    dispatch(setParam(collection, 'sort', search.sort))
    return dispatch(setConditions(collection, search.query))
  }

  //  Only display text header when no saved searches are available
  if ((options && options.length === 0) || placeholderOnly) {
    return <Heading data-testid="list-view-select-heading">{placeholder}</Heading>
  }

  return (
    <Select
      components={{ MenuList, Placeholder, SingleValue }}
      isClearable
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      styles={styles}
      value={selected}
    />
  )
}

ListViewSelect.propTypes = {
  // Must return a Promise.
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholderOnly: PropTypes.bool,
  selected: PropTypes.object,
}

export default ListViewSelect
