import { normalize } from 'normalizr';
import { message } from 'react-toastify-redux';
import { addEntities, handleEntityCollection } from './entityActions';
import { MODEL } from '../constants/entities';
import { get, put, vdbsApi } from '../utilities/apiUtilities';
import { handleError } from './toastActions';
import schemas from '../schemas';

export const fetchModels = (params) => (dispatch) => get({
  url: `${vdbsApi}/models`,
  params,
}).then((response) => dispatch(handleEntityCollection(MODEL, response.data, params)))
  .catch((err) => handleError(err)(dispatch));

export const fetchAllmodels = (params) => (dispatch) => get({
  url: `${vdbsApi}/models`,
  params,
}).then((response) => dispatch(handleEntityCollection(MODEL, response.data, params)))
  .catch((err) => handleError(err)(dispatch));

export const fetchModel = (id) => (dispatch) => get({
  url: `${vdbsApi}/models/${id}`
})
  .then((response) => {
    if (!response.data) {
      // The /models/:id endpoint does not return a 404 when the resource was not found.
      // Instead, it returns a 200 with `null` as response body
      const error = new Error(`Model not found with ID ${id}`);
      error.response = { status: 404, data: { message: 'Datatable not found' } };
      throw error;
    }
    const items = normalize(response.data, schemas.models);

    dispatch(addEntities(items.entities));

    return response.data;
  });

export const updateModel = (id, values) => (dispatch) => put({
  url: `${vdbsApi}/models/${id}`,
  data: values,
}).then((response) => {
  const item = response.data;
  const normalizedItems = normalize(item, schemas.models);

  dispatch(addEntities(normalizedItems.entities));
  dispatch(message('toast.save_successful'));

  return {
    status: response.status,
    ...normalizedItems.entities,
  };
}).catch((err) => handleError(err, 'toast.save_failed_reason')(dispatch));
