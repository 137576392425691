import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import { fetchDataTableAsofDate } from '../../actions/dataTableActions'
import SelectField from './SelectField'

import { withReferenceDate } from '../../context/ReferenceDateContext'
import { handleError } from '../../actions/toastActions'
import { sortByProperyAsc } from '../../utilities/arrayUtilities'
import { recordMatchesRegulation } from '../../utilities/dataTableUtilities'

const DataTableSelectField = (props) => {
  const { referenceDate, valueColumn, labelColumn, tableId, filterOptions, onLoadRecords } = props
  const { values } = useFormikContext()
  const [options, setOptions] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    fetchOptions()
  }, [referenceDate])

  const fetchOptions = () => {
    return dispatch(fetchDataTableAsofDate(tableId, referenceDate))
      .then((result) => {
        const options = result.records
          .filter((record) => recordMatchesRegulation(record, values?.regulation))
          .map((record) => ({ value: record[valueColumn], label: typeof labelColumn === 'string' ? record[labelColumn] : labelColumn(record), record }))
          .sort((a, b) => sortByProperyAsc(a, b, 'label'))

        onLoadRecords && onLoadRecords(options)
        return setOptions(options)
      })
      .catch((err) => handleError(err))
  }

  return <SelectField {...props} options={(filterOptions && options.filter(filterOptions)) || options} />
}

export default withReferenceDate(DataTableSelectField)
