import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Label, FormFeedback, FormText, FormError } from '../form'
import { userHasRole } from '../../utilities/userUtilities'
import UserSelect from './UserSelect'

const UserSelectField = ({
  label,
  required,
  disabled,
  name, // eslint-disable-line no-unused-vars
  filter,
  placeholder,
  type,
  feedback,
  helptext,
  field,
  isMulti,
  form: { setFieldTouched, setFieldValue, touched, errors, isSubmitting },
  roles,
  autoFocus,
  ...restProps
}) => (
  <>
    {label && (
      <Label htmlFor={field.name} required={required}>
        <FormattedMessage id={label} />
      </Label>
    )}
    <UserSelect
      // TODO: Handle initial loads if a value is already defined.
      {...restProps}
      {...field}
      id={field.name}
      isMulti={isMulti}
      isDisabled={disabled || isSubmitting}
      type={type}
      placeholder={placeholder}
      // We need to use the manual Formik `setFieldValue` function,
      // since react-select returns the new value instead of a change Event
      // See also https://codesandbox.io/s/jRzE53pqR for the "official" react-select example
      onChange={(opt) => setFieldValue(field.name, isMulti ? Object.values(opt).map((item) => item.value) : opt.value)}
      onBlur={() => setFieldTouched(field.name, true)}
      filter={(user) => user.active && userHasRole(user, ...roles) && filter(user)}
      error={errors[field.name]}
      touched={touched[field.name]}
      autoFocus={autoFocus}
    />
    {feedback && <FormFeedback>{feedback}</FormFeedback>}
    {helptext && <FormText>{helptext}</FormText>}
    {touched[field.name] && errors[field.name] && (
      <FormError data-testid="user-select-error">
        <FormattedMessage id={errors[field.name]} />
      </FormError>
    )}
  </>
)

UserSelectField.defaultProps = {
  disabled: false,
  filter: () => true,
  isMulti: false,
  required: false,
  roles: [],
}

UserSelectField.propTypes = {
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  roles: PropTypes.array,
}

export default UserSelectField
