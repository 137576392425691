import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Switch = styled('label')`
  display: inline-block;
  height: 20px;
  margin: 0;
  position: relative;
  width: 38px;
`;

const ToggleInput = styled('input')`
  height: 0;
  opacity: 0;
  width: 0;

  &:checked + span {
    background-color: ${(props) => props.theme.backgroundColors.green};
  }

 &:focus + span {
    box-shadow: 0 0 1px ${(props) => props.theme.backgroundColors.resolved};
  }

  &:checked + span:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
`;

const RoundSlider = styled('span')`
  background-color: ${(props) => props.theme.backgroundColors.gray};
  border-radius: ${(props) => props.theme.borderRadius.default};
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
  -webkit-transition: .4s;

  &:before {
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius.default};
    bottom: 2px;
    content: "";
    left: 2px;
    height: 16px;
    position: absolute;
    transition: .4s;
    width: 16px;
    -webkit-transition: .4s;
  }
`;

class CheckBoxToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.initialValue
    };
  }

  setChecked = (event) => {
    this.props.handleToggle(event, this.props.regulation);

    this.setState({
      checked: event.target.checked
    });
  }

  render = () => {
    const { input, id } = this.props;
    const { checked } = this.state;

    return (
      <Switch>
        <ToggleInput
          {...input}
          id={id}
          type="checkbox"
          onChange={this.setChecked}
          checked={checked}
        />
        <RoundSlider />
      </Switch>
    );
  }
}

CheckBoxToggle.propTypes = {
  handleToggle: PropTypes.func,
  regulation: PropTypes.string
};

export default CheckBoxToggle;
