import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from 'notistack'
import { IconButton, Button, ButtonGroup } from './Buttons'
import Modal from './Modal'
import FormGroup from './FormGroup'
import { createListView } from '../../actions/listViewActions'
import { getAggregateParam, getColumns, getParams } from '../../selectors/collectionSelectors'
import { getExpressionTree } from '../../expressionbuilder/selectors/expressionSelectors'
import { ICON_ADD } from '../../constants/icons'
import CheckBoxField from '../_form/CheckboxField'
import TextField from '../_form/TextField'
import { initialState } from '../../reducers/collectionReducer'
import RoleSelector from '../../selectors/hasRoleSelectors'

const initialValues = { name: '', public: false }

const SaveListViewAction = ({ collection }) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const canSavePublicListViews = useSelector(RoleSelector.canSavePublicListViews)
  const isAggregate = useSelector((state) => getAggregateParam(state, collection)) || false
  const filters = useSelector((state) => getExpressionTree(state, collection))
  const columns = useSelector((state) => getColumns(state, collection))
  const params = useSelector(state => getParams(state, collection))

  const isFiltersChanged = filters && filters.length
  const isColumnsChanged = initialState[collection].columns != columns
  const isSortChanged = initialState[collection].params?.sort != params?.sort

  const onSave = async (values) => {
    await dispatch(
      createListView({
        name: values.name,
        searchQuery: JSON.stringify({
          aggregate: isAggregate,
          query: filters,
          ...((isColumnsChanged && { columns }) || {}),
          ...((isSortChanged && { sort: params.sort }) || {}),
          ...((isSortChanged && { direction: params.direction }) || {}),
        }),
        screenType: collection.toUpperCase(),
        isPublic: values.public,
      })
    )

    enqueueSnackbar(<FormattedMessage id="toast.save_successful" />, { variant: 'success' })
    setShowModal(false)
  }

  if (!isFiltersChanged && !isColumnsChanged && !isSortChanged) {
    return null
  }

  return (
    <>
      <IconButton iconName={ICON_ADD} type="button" color="lightgray" disabled={showModal} onClick={() => setShowModal(true)}>
        <FormattedMessage id="button.save" />
      </IconButton>

      {showModal && (
        <Modal header={<FormattedMessage id="title.save_list_view" />} onClose={() => setShowModal(false)}>
          <Formik initialValues={initialValues} validate={(values) => (!values.name ? { name: 'message.required' } : {})} onSubmit={onSave}>
            {() => (
              <Form>
                <FormGroup>
                  <Field name="name" component={TextField} type="text" label="label.name" placeholder="placeholder.name" required />
                </FormGroup>
                {canSavePublicListViews && (
                  <FormGroup>
                    <Field name="public" component={CheckBoxField} label="label.public" />
                  </FormGroup>
                )}
                <ButtonGroup>
                  <Button type="submit">
                    <FormattedMessage id="button.confirm" />
                  </Button>
                  <Button type="button" color="lightgray" onClick={() => setShowModal(false)}>
                    <FormattedMessage id="button.cancel" />
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  )
}

export default SaveListViewAction
