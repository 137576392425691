import { normalize } from 'normalizr';
import moment from 'moment';
import schemas from '../schemas';
import { addEntities, setEntities, handleEntityCollection, resetEntities } from './entityActions';
import { ALLOWANCE, EMPLOYEE, EMPLOYEETIMELINE, ENTIYTIMELINE } from '../constants/entities';
import { setLoading } from './collectionActions';
import { handleError } from './toastActions';
import {
  postVersion,
  putVersion,
  putVersionApprove,
  putVersionReview,
  postEmployee,
  postEmployeesExport,
  postEmployeesSearch,
  getEmployeeApproved,
  getEmployeeNotRevoked,
  getEmployeeById,
  getTimeline,
  getEmployeeAllowances,
  putVersionReject,
  putVersionRevoke,
  postVersionResolve,
  postVersionIgnore,
} from '../api/employeeApi';
import { COLLECTION_EMPLOYEES } from '../constants/collections';
import { STATUS_REVOKED } from '../constants/employeeStatus';
import { getPreviousVersion } from '../selectors/employeeSelectors';
import { download } from '../utilities/fileUtilities';
import axios from 'axios';

/**
 * Adds a new employee
 *
 * @param {object} values
 */
export const addEmployee = (values) => (dispatch) => postEmployee(values)
  .then((response) => {
    const item = response.data;
    const normalizedItems = normalize(item, schemas.employees);

    dispatch(addEntities(normalizedItems.entities));

    return response.data;
  })

/**
 * @param {object} params
 */
export const searchEmployees = (params) => async (dispatch) => {
  try {
    const response = await postEmployeesSearch(params)
    dispatch(handleEntityCollection(EMPLOYEE, response.data, params))
  } catch (err) {
    if (!axios.isCancel(err)) {
      dispatch(handleError(err))
    }
  }
}

export const downloadEmployeesExport = (params) => postEmployeesExport(params)
  .then((response) => download(
    response.data,
    `employees_export_${moment().format('DD-MM-YYYY')}.xlsx`,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ));
/**
 * Handles the addition / update of an employee version
 *
 * @param {object} version
 * @param {boolean} handleConflicts
 */
export const handleVersion = (response, handleConflicts = false) => (dispatch) => {
  const version = response.employee || response;
  const normalizedItems = normalize(version, schemas[EMPLOYEETIMELINE]);

  dispatch(addEntities(normalizedItems.entities));

  if (handleConflicts === true && response.conflicts && response.conflicts.length) {
    const entities = {
      [EMPLOYEETIMELINE]: response.conflicts.reduce((prev, cur) => {
        prev[cur._id] = { ...cur };
        return prev;
      }, {})
    };
    dispatch(addEntities(entities));
  }

  return response;
};

export const fetchTimeline = (entityId) => (dispatch) => {
  // TODO: Do not update loading state on employees collection while handling timeline.
  dispatch(setLoading(COLLECTION_EMPLOYEES, true));

  return getTimeline(entityId)
    .then((response) => {
      const normalized = normalize(response.data, [schemas[EMPLOYEETIMELINE]]);

      // TODO: Do not update loading state on employees collection while handling timeline.
      dispatch(setLoading(COLLECTION_EMPLOYEES, false));

      const employee = normalized.entities[EMPLOYEETIMELINE];
      dispatch(setEntities(ENTIYTIMELINE, { [entityId]: employee }));
      return dispatch(setEntities(EMPLOYEETIMELINE, normalized.entities[EMPLOYEETIMELINE]));
    });
};

/**
 * Inserts a new version of the employee dossier
 *
 * @param {string} entityId
 * @param {object} values
 */
export const addVersion = (entityId, values) => (dispatch) => postVersion(entityId, values)
  .then((response) => {
    dispatch(fetchTimeline(entityId));
    dispatch(handleVersion(response.data, true));

    return response.data
  })
  .catch((err) => {
    handleError(err)(dispatch);
    throw err;
  });

/**
 * Updates a specific version of the employee dossier
 *
 * @param {string} entityId
 * @param {object} values
 */
export const updateVersion = (entityId, values) => (dispatch) => putVersion(entityId, values)
  .then((response) => {
    dispatch(fetchTimeline(entityId));
    dispatch(handleVersion(response.data, true));

    return response.data
  })
  .catch((err) => {
    const message = err.response && (err.response.status === 409) ? 'error.employee_version_update_locked' : 'error.employee_version_update_failed';
    handleError(err, message)(dispatch);
    throw err;
  });

/**
 * Transfers the status of a dossier from "DRAFT" to "REVIEW" and adds an assignee
 *
 * @param {string} entityId
 * @param {string} id
 * @param {object} data
 */
export const assignVersion = (entityId, id, data) => (dispatch) => putVersionReview(entityId, id, data)
  .then((response) => {
    dispatch(fetchTimeline(entityId));
    return dispatch(handleVersion(response.data, true));
  })
  .catch((err) => handleError(err)(dispatch));

/**
 * Transfers the status of a dossier from "REVIEW" to "APPROVED"
 *
 * @param {string} entityId
 * @param {string} id
 */
export const approveVersion = (entityId, id) => (dispatch) => putVersionApprove(entityId, id)
  .then((response) => {
    dispatch(fetchTimeline(entityId));
    return dispatch(handleVersion(response.data, true));
  })
  .catch((err) => handleError(err)(dispatch));

/**
 * Transfers the status of a dossier from "REVIEW" to "REJECTED"
 *
 * @param {string} id
 * @param {object} values
 */
export const rejectVersion = (entityId, id, data) => (dispatch) => putVersionReject(entityId, id, data)
  .then((response) => {
    dispatch(fetchTimeline(entityId));
    return dispatch(handleVersion(response.data, true));
  })
  .catch((err) => handleError(err)(dispatch));

/**
 * If a partner of child is passed with ONLY `null` values, we strip it from the employee
 * object here.
 *
 * @param {object} employee
 */
const clearEmptyValues = (employee) => {
  if (employee.partner && !Object.values(employee.partner).find((value) => value)) {
    delete employee.partner;
  }

  if (employee.children) {
    employee.children.forEach((child, index) => {
      if (!Object.values(child).find((value) => value)) {
        employee.children.splice(index, 1);
      }
    });
  }

  return employee;
};

/**
 * Updates a specific version of the employee dossier
 *
 * @param {string} entityId
 * @param {object} values
 */
export const resolveConflict = (entityId, values) => (dispatch) => postVersionResolve(entityId, clearEmptyValues(values))
  .then((response) => dispatch(handleVersion({
    ...response.data,
    // We map a virtual property `wasConflict` to the resolved object,
    // so we can properly identify the cause of a conflict later
    employee: { ...response.data.employee, resolved: true }
  }, true)))
  .catch((err) => handleError(err)(dispatch));

/**
 * Ignore a conflict on the employee version
  *
  * @param {string} versionId
  */
export const ignoreConflict = (versionId) => (dispatch) => postVersionIgnore(versionId)
  .then((response) => dispatch(handleVersion(response.data, true)))
  .catch((err) => handleError(err)(dispatch));

// TODO: remove when selector for employee is fully implemented
export const fetchEmployeeApproved = (entityId) => (dispatch) => getEmployeeApproved(entityId)
  .then((response) => {
    // This GET /employees/:entityId endpoint does not give a proper 404 response, so we simulate this manually
    if (!response.data) {
      const error = new Error(`Employee not found with entityID ${entityId}`);
      error.response = { status: 404, data: { message: 'Employee not found' } };
      throw error;
    }

    const items = normalize(response.data, schemas.employees);

    dispatch(addEntities(items.entities));

    return response.data;
  })
  .catch((err) => handleError(err)(dispatch));

export const fetchEmployeeNotRevoked = (entityId) => (dispatch) => getEmployeeNotRevoked(entityId)
  .then((response) => {
    // This GET /employees/:entityId endpoint does not give a proper 404 response, so we simulate this manually
    if (!response.data) {
      const error = new Error(`Employee not found with entityID ${entityId}`);
      error.response = { status: 404, data: { message: 'Employee not found' } };
      throw error;
    }

    const items = normalize(response.data, schemas.employees);

    dispatch(addEntities(items.entities));

    return response.data;
  })
  .catch((err) => handleError(err)(dispatch));

export const fetchEmployeeById = (id) => (dispatch) => getEmployeeById(id)
  .then((response) => {
    const items = normalize(response.data, schemas.employees);

    dispatch(addEntities(items.entities));

    return response.data;
  })
  .catch((err) => handleError(err)(dispatch));

export const resetTimeline = () => (dispatch) => dispatch(resetEntities(EMPLOYEETIMELINE));

export const fetchEmployeeAllowances = (employeeId) => (dispatch) => getEmployeeAllowances(employeeId)
  .then((response) => dispatch(handleEntityCollection(ALLOWANCE, response.data)))
  .catch((err) => handleError(err)(dispatch));

/**
 * Revokes changes to an existing version and returns the previous version
 *
 * @param {string} id
 * @param {object} values
 */
export const revokeVersion = (entityId, id) => (dispatch, getState) => putVersionRevoke(entityId, id)
  .then((response) => {
    // We find the previous item before we actually mark the revoked version as REVOKED
    const previous = { ...getPreviousVersion(getState(), id) };
    dispatch(handleVersion(response.data));
    return response.data._status === STATUS_REVOKED ? previous : response;
  })
  .then((employee) => {
    if (Object.entries(employee).length === 0 && employee.constructor === Object) {
      return false;
    }
    return dispatch(fetchTimeline(entityId)).then(() => employee);
  })
  .catch((err) => { throw handleError(err)(dispatch) });
