import update from 'immutability-helper';

import {
  INSTRUCTION_LOG_RECEIVED,
  FLOW_REQUESTED,
  FLOW_FINISHED,
  FLOW_STOPPED,
  FLOW_SUBSCRIBED,
  FLOW_EDIT_DISABLED,
  FLOW_INIT,
  FLOW_PROGRESS,
} from '../actionTypes';

const initialState = {
};

const initialFlow = {
  deployments: {},
  status: '',
  isRunning: false,
  startTime: null,
  stopTime: null,
  latestDeployment: null,
  disabled: true,
  progress: {
    current: 0,
    total: 0,
  },
};

const initialDeployment = {
  console: [],
};

const studioReducer = (state = initialState, action) => {
  switch (action.type) {
    case FLOW_REQUESTED: {
      return update(state, {
        [action.payload.flowId]: (flow) => update(flow || initialFlow, {
          status: { $set: FLOW_REQUESTED },
          isRunning: { $set: true },
          startTime: { $set: Date.now() },
          latestDeployment: { $set: null },
        }),
      });
    }
    case FLOW_SUBSCRIBED: {
      return update(state, {
        [action.payload.flowId]: {
          latestDeployment: { $set: action.payload.deployId },
        },
      });
    }
    case FLOW_PROGRESS: {
      return update(state, {
        [action.payload.flowId]: {
          progress: { $merge: action.payload.progress },
        },
      });
    }
    case FLOW_FINISHED: {
      return update(state, {
        [action.payload.flowId]: {
          status: { $set: FLOW_FINISHED },
          isRunning: { $set: false },
          stopTime: { $set: Date.now() },
        },
      });
    }
    case FLOW_STOPPED: {
      return update(state, {
        [action.payload.flowId]: {
          status: { $set: FLOW_STOPPED },
          isRunning: { $set: false },
          stopTime: { $set: Date.now() },
        },
      });
    }
    case INSTRUCTION_LOG_RECEIVED: {
      return update(state, {
        [action.payload.flowId]: {
          deployments: {
            [action.payload.deployId]: (deployment) => update(deployment || initialDeployment, {
              console: { $push: [action.payload.body] },
            }),
          },
        },
      });
    }
    case FLOW_INIT: {
      if (state[action.payload.id]) {
        return state;
      }
      return update(state, {
        [action.payload.id]: { $set: {
          ...initialFlow,
        } }
      });
    }
    case FLOW_EDIT_DISABLED: {
      return update(state, {
        [action.payload.id]: (flow) => update(flow || initialFlow, {
          disabled: { $set: action.payload.disabled },
        })
      });
    }
    default: {
      return state;
    }
  }
};

export default studioReducer;
