import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ConditionSet from './ConditionSet';
import { removeCondition } from '../../actions/expressionActions';
import { withContext } from '../withContext';
import { conditionShape } from '../../shapes';

import Label from '../../../components/form/Label';
import ConditionItem from '../../layout/ConditionItem';
import LabelSegment from '../../layout/LabelSegment';
import CriteriaSegment from '../../layout/CriteriaSegment';
import RemoveButton from '../../../components/layout/RemoveButton';

/**
 * A Condition Group represents a type of condition that groups other conditions
 */
const GroupCondition = ({ condition, remove, intl: { formatMessage } }) => (
  <ConditionItem>
    <LabelSegment>
      <Label>{formatMessage({ id: `label.conditiongroup_${condition.type}` })}</Label>
    </LabelSegment>
    <CriteriaSegment>
      <ConditionSet parent={condition.id} conditions={condition.children} />
      <RemoveButton
        size="sm"
        onClick={() => remove(condition)}
      />
    </CriteriaSegment>
  </ConditionItem>
);

GroupCondition.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  context: PropTypes.object.isRequired,
  condition: PropTypes.shape(conditionShape),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  parent: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch, props) => ({
  remove: (condition) => dispatch(removeCondition(props.context.name, condition, props.parent, props.context.onSubmit)),
});

export default withContext(connect(null, mapDispatch)(injectIntl(GroupCondition)));
