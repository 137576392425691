import { createIntl, createIntlCache } from 'react-intl';
import { toast } from 'react-toastify';
import locales from '../locales/index'
import { isNetworkError } from '../utilities/apiUtilities';
import { serviceHeartbeat } from './serviceActions';

const cache = createIntlCache()
const intl = createIntl({ locale: 'nl', messages: locales.nl }, cache)

const getTranslationCode = (err) => {
  switch (err.response.status) {
    case 400: return 'toast.validation_error';
    case 409: return 'toast.conflict_error';
    default: return 'toast.server_error';
  }
};

export const errorToast = (err, id, reason) => {
  if (err.shouldToast !== false) {
    toast.error(reason);
  }
  return err;
};

export const handleError = (err, customMessage) => async (dispatch) => {
  if (isNetworkError(err)) {
    return dispatch(serviceHeartbeat());
  }
  if (err.response) {
    if (err.response.status === 401 || (err.response.status === 403 && err.response.data.code === 'secu-2')) {
      return err;
    }
    if (err.response.data && err.response.data.code) {
      return errorToast(err, customMessage || getTranslationCode(err), intl.formatMessage({ id: `toast.${err.response.data.code}` }));
    }
    if (err.response.data?.errors) {
      const error = Object.values(err.response.data.errors)[0]
      return errorToast(err, customMessage || getTranslationCode(err), intl.formatMessage({ id: `toast.${error}` }));
    }
    return errorToast(err, customMessage || getTranslationCode(err),
      (err.response.data?.message && intl.formatMessage({ id: `toast.${err.response.data.message}` })) || (customMessage && intl.formatMessage({ id: customMessage })));
  }
  return errorToast(err, customMessage || 'toast.unknown_error', intl.formatMessage({ id: `toast.${err.message}` }));
};
