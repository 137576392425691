import styled from 'styled-components/macro';
import { Input, Label, TextArea } from '../form';
import { CheckBoxContainer } from '../_form/CheckboxField';

interface FormGroupProps {
    row?: boolean
    inline?: boolean
}

const FormGroup = styled('div')`
  display: flex;
  flex-direction: ${(props: FormGroupProps) => ((props.row || props.inline) ? 'row' : 'column')};
  margin-right: ${(props: FormGroupProps) => (props.row ? '-15px' : 0)};
  margin-left: ${(props: FormGroupProps) => (props.row ? '-15px' : 0)};
  margin-bottom: 1rem;

  > ${CheckBoxContainer} {
    flex-direction: ${(props: FormGroupProps) => ((props.row || props.inline) ? 'row' : 'column')};
  }

  ${({ inline }) => inline && `
    > ${CheckBoxContainer} > ${Label},
    > ${Label} {
      padding: 6px 0;
      width: 200px;
    }

    > div {
      flex: 3;
    }

    ${Input} {
      width: auto;
    }

    ${TextArea} {
      width: calc(100% - 200px);
    }
  `}
`;

export default FormGroup;
