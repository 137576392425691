import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';
import BaseIcon from './Icon';
import { RemoveIcon } from './RemoveButton';
import Spinner from './Spinner';
import theme from './theme';

const Icon = styled(BaseIcon)`
  width: 18px;
  text-align: center;
`;

const isWhite = (color) => ['primary', 'secondary', 'danger'].indexOf(color) !== -1;

const Button = styled.button`
  font-family: ${({ theme }) => theme.fonts.sans};
  background-color: ${(props) => props.theme.buttonColors[props.color || 'primary']};
  color: ${(props) => (isWhite(props.color || 'primary') ? 'white' : props.theme.textColors.darkgray)};
  font-size: 90%;
  line-height: 15px;
  white-space: nowrap;
  margin-bottom: 10px;
  font-weight: bold;
  padding: ${({ icon }) => (icon ? '.4rem .8rem' : '0.7rem 1.1rem')};
  height: 34px;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.buttonColors[`${props.color}Hover` || 'primary']};
    color: ${(props) => (isWhite(props.color || 'primary') ? 'white' : props.theme.textColors.darkgray)};
    box-shadow: 0 2px 6px ${(props) => rgba(props.theme.buttonColors[props.color || 'primary'], 0.3)};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: 2px 2px #ffaa00, -2px -2px #ffaa00, 2px -2px #ffaa00, -2px 2px #ffaa00;
  }

  :disabled {
    background-color: ${(props) => props.theme.buttonColors.lightgray};
    color: ${(props) => props.theme.textColors.lightestgray};
    box-shadow: none;
    cursor: default;
  }
`;

Button.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.buttonColors))
};

const ButtonLink = Button.withComponent(Link);
const ButtonHref = Button.withComponent('a');

const ButtonTextLink = styled('div')`
  border: none;
  color: ${(props) => (props.theme.colors[props.color] || 'gray')};
  display: block;
  height: 34px;
  padding: 0.4rem 0;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => (props.theme.colors[props.color] || 'gray')};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonGroup = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.align === 'right' ? 'flex-end' : 'flex-start')};
  justify-self: ${(props) => (props.align === 'right' ? 'end' : 'auto')};
  flex-grow: ${(props) => (props.align === 'right' ? '2' : 'auto')};

  ${Button} {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${ButtonLink} {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${RemoveIcon} {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ButtonContainer = styled('div')`
  align-content: flex-start;
  display: flex;
  justify-content: space-between;
`;

const IconButtonWrapper = styled(Button)`
  padding: .4rem .8rem;
`;

const Inner = styled('span')`
  margin-left: .5rem;
  line-height: 1.6rem;
`;

const IconButton = ({ iconName, iconSize, showSpinner, children, ...restProps }) => (
  <IconButtonWrapper {...restProps}>
    {showSpinner && <Spinner color="white" />}
    {!showSpinner && <Icon name={iconName} size={iconSize || 'sm'} />}
    {children !== undefined && (
      <Inner>
        {children}
      </Inner>
    )}
  </IconButtonWrapper>
);

const ButtonBackWapper = styled.button`
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: none;
  background: ${(props) => props.theme.backgroundColors.lightergray};
  color: ${(props) => props.theme.textColors.lightgray};
  cursor: pointer;
  text-align: center;
  line-height: 31px;
  margin-top: -5px;
  margin-right: 1rem;

  &:hover {
    background: ${(props) => props.theme.backgroundColors.lightgray};
    color: ${(props) => props.theme.textColors.primary};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonBack = (props) => (
  <ButtonBackWapper {...props}>
    <Icon name="ChromeBack" size="sm" />
  </ButtonBackWapper>
);

const ButtonBackLinkWapper = ButtonBackWapper.withComponent(Link);

const ButtonBackLink = (props) => (
  <ButtonBackLinkWapper {...props}>
    <Icon name="ChromeBack" size="sm" />
  </ButtonBackLinkWapper>
);

const ButtonIconLink = ({ iconName, iconSize, children, ...restProps }) => (
  <ButtonLink {...restProps} icon="true">
    <Icon name={iconName} size={iconSize || 'sm'} />
    {children !== undefined && (
      <Inner>
        {children}
      </Inner>
    )}
  </ButtonLink>
);

export {
  ButtonContainer,
  ButtonGroup,
  Button,
  ButtonBack,
  ButtonBackLink,
  ButtonLink,
  ButtonHref,
  IconButton,
  ButtonIconLink,
  ButtonTextLink,
};

export default Button;
