import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLabel } from '../../selectors/dataTableSelectors';

const DataTableFormatter = ({ label }) => label;

DataTableFormatter.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  modelId: PropTypes.string,
};

const mapState = (state, props) => ({
  label: getLabel(state, props) || null,
});

export default connect(mapState)(DataTableFormatter);
