import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Getter } from '@devexpress/dx-react-core'
import { SortingState, PagingState, CustomPaging, DataTypeProvider, EditingState } from '@devexpress/dx-react-grid'
import { Grid as GridContainer, TableEditColumn, TableHeaderRow, Table, PagingPanel } from '@devexpress/dx-react-grid-bootstrap4'
import { getModelsForCollection } from '../../selectors/modelSelectors'
import { TableRow, TableCell, BoldTypeProvider } from '.'
import Icon from './Icon'
import Dialog from '../layout/Dialog'

const GridContainerWrapper = styled('div')`
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.12);
    margin: 0;
    line-height: 18px;
    width: 100%;
    border-collapse: collapse;

    .p-0 {
      padding: 0 !important;
    }

    .border-0 {
      border: 0 !important;
    }

    .d-flex {
      display: flex;
    }

    .w-100 {
      width: 100%;
    }

    .text-right {
      text-align: right;
    }

    .justify-content-end {
      justify-content: flex-end;
    }

    td {
      padding: 0.75rem 5px;
      border-top: none;
      border: none;
      vertical-align: top;

      .btn-link {
        border: 0;
        padding: 0.75rem 5px;
        background: none;
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    th {
      vertical-align: bottom;
    }

    .text-nowrap {
      white-space: nowrap !important;
    }

    thead {
      tr {
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
        padding-top: 9px;
        padding-bottom: 9px;
      }

      td,
      th {
        padding: 8px 5px;
        border: none;
        color: ${(props) => props.theme.textColors.lightgray};
        font-size: 14px;

        .text-primary {
          color: ${(props) => props.theme.textColors.default} !important;
        }
      }
    }

    tbody {
      tr {
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }
  }

  .card-footer {
    background: none;
    padding: 1.25rem 0;

    .pagination {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .page-item {
      height: 32px;
      line-height: 1rem;

      a {
        height: 32px;
      }

      .page-link {
        position: relative;
        display: block;
        margin-left: 1px;
        line-height: 20px;
        padding: 0.45rem 0.75rem;
        color: ${(props) => props.theme.textColors.darkgray};
        background-color: ${(props) => props.theme.buttonColors.lightgray};
        height: 34px;
        font-weight: bold;
      }

      &.disabled .page-link {
        background-color: ${(props) => props.theme.buttonColors.lightgray};
        color: ${(props) => props.theme.textColors.lightestgray};
        pointer-events: none;
        cursor: auto;
      }

      &.active .page-link {
        z-index: 0;
        color: white;
        background-color: ${(props) => props.theme.buttonColors.primary};
      }

      &:first-child {
        .page-link {
          margin-left: 0;
          border-bottom-left-radius: ${(props) => props.theme.borderRadius.medium};
          border-top-left-radius: ${(props) => props.theme.borderRadius.medium};
        }
      }

      &:last-child {
        .page-link {
          border-bottom-right-radius: ${(props) => props.theme.borderRadius.medium};
          border-top-right-radius: ${(props) => props.theme.borderRadius.medium};
        }
      }
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .d-sm-none {
    display: none !important;
  }
`

const SortIcon = styled(Icon)`
  font-size: 14px;
  padding-left: 5px;
`

// eslint-disable-next-line react/prop-types
const SortingIcon = ({ direction }) => <SortIcon name={`${direction === 'asc' ? 'Up' : 'Down'}`} color="gray" />

const ButtonSort = styled('button')`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-weight: bold;
  color: ${(props) => (!props.direction ? props.theme.textColors.lightgray : props.theme.textColors.default)};
  cursor: pointer;
  font-size: 14px;

  :focus {
    outline: none;
  }
`

// eslint-disable-next-line react/prop-types
const SortLabel = ({ onSort, children, direction }) => (
  <ButtonSort type="button" onClick={onSort} direction={direction}>
    {children}
    {direction && <SortingIcon direction={direction} />}
  </ButtonSort>
)

const NoDataCell = styled('td')`
  text-align: center;
  color: ${(props) => props.theme.textColors.lightgray};
`

const ModalBody = styled('div')`
  p {
    color: ${(props) => props.theme.textColors.lightgray};
  }
`

const Grid = ({
  columns,
  deleteHelpText,
  deleteTitle,
  rows,
  models,
  showPagination,
  showSortingControls,
  showHeaders,
  children,
  onRowClick,
  onRowDelete,
  columnsFormatBold,
  cellComponent,
  columnExtensions,
  params,
  totalCount,
  onInitialize,
  onFetchRows,
}) => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  const fetchRowsLocal = (params) => {
    if (!onFetchRows) return

    onFetchRows(params)
  }

  useEffect(() => {
    if (onInitialize) {
      onInitialize()
    } else {
      fetchRowsLocal(params)
    }
  }, [])

  const getColumnsForModel = () => {
    if (models === undefined) return []

    const attributes = models.reduce((atts, model) => atts.concat(model.attributes), [])

    return attributes.map((attribute) => ({
      name: attribute.name,
      title: attribute.name,
      getCellValue: (row) => row[attribute.name],
    }))
  }

  const toggleDeleteModal = (id) => {
    setSelectedRow(id)
    setDeleteModalVisible(!isDeleteModalVisible)
  }

  const commitChanges = ({ deleted }) => {
    if (deleted) {
      toggleDeleteModal(deleted[0])
    }
  }

  const changeSorting = (sorting) => {
    const { columnName, direction } = sorting[0]

    fetchRowsLocal({
      sort: columnName === 'fullName' ? 'sortableLastName' : columnName,
      direction,
    })
  }

  const handleDeleteFlow = () => onRowDelete(selectedRow).then(() => toggleDeleteModal())

  const handlePageChange = (currentPage) => {
    fetchRowsLocal({ ...params, offset: currentPage * params.limit })
  }

  const getRowId = (row) => {
    if (row.key !== undefined) {
      return row.key
    }
    return row._id || row.id || row.tableId
  }

  const cols = columns || getColumnsForModel()
  const TableCellComponent = cellComponent || TableCell
  const extensions = columnExtensions.filter(e => e.width).map((ext) => ({ ...ext, value: ext.value || ext.name, columnName: ext.name }))

  return (
    <GridContainerWrapper>
      <GridContainer
        rows={rows}
        columns={cols.map((col) => ({
          ...col,
          value: col.value || col.name,
          title: col.label ? <FormattedMessage id={col.label} />: col.name,
        }))}
        getRowId={getRowId}
      >
        <EditingState onCommitChanges={commitChanges} />
        <SortingState
          sorting={[{ columnName: params.sort, direction: params.direction }]}
          onSortingChange={changeSorting}
          columnExtensions={extensions}
        />
        <PagingState
          currentPage={(params ? params.offset : params.offset) / params.limit}
          onCurrentPageChange={handlePageChange}
          pageSize={params.limit}
        />
        {columnsFormatBold && <BoldTypeProvider for={columnsFormatBold} />}
        <CustomPaging totalCount={totalCount} />
        <Table
          columnExtensions={extensions}
          rowComponent={(props) => <TableRow onRowClick={onRowClick || null} {...props} />}
          cellComponent={(props) => <TableCellComponent {...props} />}
          noDataRowComponent={(props) => (
            <TableRow {...props}>
              <NoDataCell data-testid="empty-table" colSpan={cols.length || 1}>
                <FormattedMessage id="label.no_data_in_table" />
              </NoDataCell>
            </TableRow>
          )}
        />
        {onRowDelete && <TableEditColumn showDeleteCommand />}
        {showPagination && <PagingPanel />}
        <Getter
          name="tableColumns"
          computed={({ tableColumns }) => {
            // Temporary fix to position the TableEditColumn as last column in the grid.
            // See https://github.com/DevExpress/devextreme-reactive/issues/287
            const result = [...(tableColumns || []).filter((c) => c.type !== TableEditColumn.COLUMN_TYPE)]
            if (onRowDelete) result.push({ key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 140 })
            return result
          }}
        />
        {children}
        {showHeaders && <TableHeaderRow showSortingControls={showSortingControls} sortLabelComponent={SortLabel} />}
      </GridContainer>
      {isDeleteModalVisible && (
        <Dialog onCancel={toggleDeleteModal} onConfirm={handleDeleteFlow} header={<FormattedMessage id={deleteTitle} />}>
          <ModalBody>
            <p>
              <FormattedMessage id={deleteHelpText} />
            </p>
          </ModalBody>
        </Dialog>
      )}
    </GridContainerWrapper>
  )
}

Grid.defaultProps = {
  params: {},
  columnExtensions: [],
  showPagination: true,
  showHeaders: true,
  showSortingControls: true,
}

Grid.propTypes = {
  totalCount: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
  cellComponent: PropTypes.func,
  columnExtensions: PropTypes.array,
  columns: PropTypes.array,
  columnsFormatBold: PropTypes.array,
  deleteTitle: PropTypes.string,
  deleteHelpText: PropTypes.string,
  models: PropTypes.array.isRequired,
  onInitialize: PropTypes.func,
  onFetchRows: PropTypes.func,
  onRowClick: PropTypes.func,
  // If this property is passed, the Grid automatically adds a column with a delete button
  onRowDelete: PropTypes.func,
  showPagination: PropTypes.bool,
  showSortingControls: PropTypes.bool,
  showHeaders: PropTypes.bool,
  // Search params for an initial load
  params: PropTypes.object,
  rowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  rows: PropTypes.array.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  models: getModelsForCollection(state, ownProps.rows),
})

export default connect(mapStateToProps)(Grid)

export { Table, DataTypeProvider }
