import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '.';
import { ICON_CLOSE } from '../../constants/icons';

export const RemoveIcon = styled(Icon)`
  color:  ${(props) => props.theme.textColors.gray};
  cursor: pointer;
  line-height: 34px;

  :hover {
    color:  ${(props) => props.theme.textColors.default};
  }
`;

const RemoveButton = (props) => (
  <RemoveIcon
    size="xs"
    name={ICON_CLOSE}
    {...props}
  />
);

export default RemoveButton;
