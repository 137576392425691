import update from 'immutability-helper';
import { generateId, normalizeExpression } from '../utils/expressionUtils';
import { INIT_EXPRESSION, ADD_CONDITION, UPDATE_CONDITION, REMOVE_CONDITION, SET_CONDITIONS } from '../constants/actionTypes';
import { TYPE_TEXT } from '../../constants/types';

const initialExpressionState = {
  root: [],
  conditions: {}
};

const initialConditionState = {
  children: [],
  type: TYPE_TEXT
};

const expressionSliceReducer = (state = {}, action) => {
  switch (action.type) {
    /**
     * Adds a new condition to the state
     * and adds the id of that condition to the children list of the given parent
     */
    case ADD_CONDITION: {
      // Generate a unique ID for this condition
      // so we can reference it later during updates and deletes
      const id = generateId();
      const condition = {
        id,
        ...initialConditionState,
        ...action.payload.condition,
      };

      const { parent } = action.payload;
      // If there's no parent (meaning, its a root condition),
      // we need to add the id to the root array
      if (!parent) {
        return update(state, {
          root: { $push: [id] },
          conditions: {
            [id]: { $set: condition },
          }
        });
      }

      // If a parent is defined, we need to add it to the conditions
      // and add the id to list of children of the parent
      return update(state, {
        conditions: {
          [id]: { $set: condition },
          [parent]: { children: { $push: [id] } }
        }
      });
    }
    case SET_CONDITIONS: {
      const normalized = normalizeExpression(action.payload);

      return update(state, {
        root: { $set: normalized.result },
        conditions: { $set: normalized.entities.conditions || {} }
      });
    }
    /**
     * Replaces a condition in the state with the new value
     */
    case UPDATE_CONDITION: {
      return update(state, {
        conditions: {
          [action.payload.condition.id]: { $set: action.payload.condition },
        }
      });
    }
    /**
     * Removes a condition from the state
     * and updates the children of the given parent
     */
    case REMOVE_CONDITION: {
      const { parent, condition } = action.payload;

      if (parent) {
        const newParentChildren = state.conditions[parent].children.filter((id) => id !== condition.id);
        return update(state, {
          conditions: {
            $unset: [condition.id],
            [parent]: { children: { $set: newParentChildren } }
          }
        });
      }

      const newRootChildren = state.root.filter((id) => id !== condition.id);
      return update(state, {
        root: { $set: newRootChildren },
        conditions: { $unset: [condition.id] },
      });
    }
    default: {
      return state;
    }
  }
};

const expressionReducer = (state = {}, action) => {
  switch (action.type) {
    case INIT_EXPRESSION: {
      // Avoid initializing if it's already initialized
      if (state[action.payload.name]) {
        return state;
      }
      return update(state, {
        [action.payload.name]: { $set: {
          ...initialExpressionState
        } }
      });
    }
    /**
     * For all action types below, we use a dedicated child reducer
     * that handles the state changes for a specific expression
     */
    case SET_CONDITIONS:
    case ADD_CONDITION:
    case UPDATE_CONDITION:
    case REMOVE_CONDITION: {
      return update(state, {
        [action.meta.name]: { $set: expressionSliceReducer(state[action.meta.name], action) }
      });
    }
    default: {
      return state;
    }
  }
};

export default expressionReducer;
