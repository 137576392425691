import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import HeadingFieldset from '../../layout/HeadingFieldset';
import Icon from '../../layout/Icon';

export const RegulationHeading = memo(() => (
  <HeadingFieldset>
    <Icon name="Airplane" size="lg" color="gray" />
    <FormattedMessage id="title.regulation" />
  </HeadingFieldset>
));

export default RegulationHeading;
