import { createSelector } from 'reselect';
import { STATUS_REVIEW, STATUS_REVOKED, STATUS_REJECTED } from '../constants/employeeStatus';
import { getClosestTimelineItem, sortByDateAndOffsetDesc, sortByDateAsc, getYoungestTimelineItem } from '../utilities/timelineUtilities';
import { COLLECTION_EMPLOYEES } from '../constants/collections';
import { getChangedFields } from '../utilities/diffUtilities';
import { EMPLOYEE, EMPLOYEETIMELINE, ENTIYTIMELINE } from '../constants/entities';
import { getModelAttributes } from '../utilities/modelUtilities';
import { getEmployeeModel } from './modelSelectors';
import { employeeListColumns } from '../utilities/employeeUtilities';
import { getColumns } from './collectionSelectors';
import { getCurrentUser } from './authenticationSelectors';

const empty = {};
export const getEmployees = (state) => state.entities[EMPLOYEE];
export const getEmployeeItems = (state) => state.collection[COLLECTION_EMPLOYEES].items;
export const getTimeline = (state) => (state.entities[EMPLOYEETIMELINE] || empty);
export const getEntityTimeline = (state, entityId) => (state.entities[ENTIYTIMELINE][entityId] || empty);

export const getEmployee = (state, id) => state.entities.employees[id];
export const getEmployeesFromCollection = createSelector(
  getEmployees,
  getEmployeeItems,
  (employees, dates) => dates.map((date) => employees[date])
);

export const getEmployeeByEntityId = createSelector(
  getEmployees,
  (_state, entityId) => entityId,
  (employees, entityId) => Object.values(employees).find((employee) => employee._entityId === entityId)
);

export const getVersionFromTimeline = (state, id) => state.entities[EMPLOYEETIMELINE][id];

/**
 * Get only the latest versions in the timeline.
 * This makes sure only one version per startDate is returned. That one version is the one with the highest offset
 */
export const getLatestVersions = createSelector(
  getTimeline,
  (timeline) => Object.values(timeline)
    .sort(sortByDateAndOffsetDesc)
    // Only keep the first item of a start date to avoid any duplicates. We can simply take
    // the first item, since we already sorted the array before correctly.
    .filter((item, pos, self) => self.findIndex((i) => i._startDate === item._startDate) === pos)
);

/**
 * Get only the latest versions in the timeline.
 * This makes sure only one version per startDate is returned. That one version is the one with the highest offset
 */
export const getEntityLatestVersions = createSelector(
  getEntityTimeline,
  (timeline) => Object.values(timeline || [])
    .sort(sortByDateAndOffsetDesc)
    // Only keep the first item of a start date to avoid any duplicates. We can simply take
    // the first item, since we already sorted the array before correctly.
    .filter((item, pos, self) => self.findIndex((i) => i._startDate === item._startDate) === pos)
);

/**
 * Checks if the given version is part of the latest versions
 */
export const isLatestVersionForDate = createSelector(
  getLatestVersions,
  (_state, _id) => _id,
  (versions, _id) => _id && versions.findIndex((v) => v._id === _id) !== -1
);

const getLatestVersionIds = createSelector(
  getLatestVersions,
  (versions) => versions.map((v) => v._id)
);

export const getSortedTimeline = createSelector(
  getTimeline,
  (timeline) => Object.values(timeline)
    .sort(sortByDateAndOffsetDesc)
    .filter((item) => item._status !== STATUS_REVOKED)
);

export const getSortedEntityTimeline = createSelector(
  getEntityTimeline,
  (timeline) => Object.values(timeline || [])
    .sort(sortByDateAndOffsetDesc)
    .filter((item) => item._status !== STATUS_REVOKED)
);

export const getAllVersions = createSelector(
  getSortedTimeline,
  getLatestVersionIds,
  (versions, latestIds) => versions
    // We add a `_outdated` property to each version to know if its outdated or not
    .map((version) => ({ ...version, _outdated: latestIds.indexOf(version._id) === -1 }))
    // Hide any outdated rejected versions from the timeline
    .filter((version) => version._status !== STATUS_REJECTED || version._outdated !== true)
);

export const getAllEntityVersions = createSelector(
  getSortedEntityTimeline,
  getLatestVersionIds,
  (versions, latestIds) => versions
    // We add a `_outdated` property to each version to know if its outdated or not
    .map((version) => ({ ...version, _outdated: latestIds.indexOf(version._id) === -1 }))
    // Hide any outdated rejected versions from the timeline
    .filter((version) => version._status !== STATUS_REJECTED || version._outdated !== true)
);

export const getConflicts = createSelector(
  getLatestVersions,
  (timeline) => timeline
    .filter((version) => version?.conflict?.length > 1)
    .sort(sortByDateAsc)
);

export const getCauseOfConflict = createSelector(
  getSortedTimeline,
  (timeline) => {
    const conflictId = timeline.find((version) => version.conflict)?.conflict;
    return timeline.find((version) => version._id === conflictId);
  }
);

const getConflictBaseline = createSelector(
  getSortedTimeline,
  getCauseOfConflict,
  getLatestVersions,
  (timeline, cause, timelineCollapsed) => {
    const causeIdx = timeline.findIndex((version) => version._id === cause._id)
    if (timeline[causeIdx + 1]) {
      return timeline[causeIdx + 1]
    }

    const causeFilteredIdx = timelineCollapsed.findIndex((version) => version._id === cause._id)
    return timelineCollapsed[causeFilteredIdx - 1]
  }
);

export const hasConflicts = createSelector(
  getConflicts,
  (conflicts) => conflicts && (conflicts.length > 0)
);

export const getInReview = createSelector(
  getLatestVersions,
  getCurrentUser,
  (timeline, currentUser) => Object.values(timeline)
    .filter((version) => version._status === STATUS_REVIEW && version._updatedBy !== currentUser.id)
    .sort(sortByDateAsc)
);

export const getOpenAndResolvedConflicts = createSelector(
  getLatestVersions,
  (timeline) => timeline
    .filter((version) => version.conflict)
    .sort(sortByDateAsc)
);

/**
 * Gets the previous version within the list of all versions, ordered by date and offset descending
 *
 * @param {string} id
 */
export const getPreviousVersion = createSelector(
  getSortedTimeline,
  (_state, id) => id,
  (timeline, id) => {
    const curIndex = timeline.findIndex((v) => v._id === id);
    if (curIndex === -1 || curIndex === (timeline.length - 1)) return null;
    return timeline[curIndex + 1];
  }
);

const getConflictsFieldsFromVersions = (cause, version) => {
  const fields = getChangedFields(cause, version);

  // Add specific properties if a whole partner object has been added
  if ((version && !version.partner && cause.partner) || (!cause.partner && version && version.partner)) {
    fields.push('partner');
  }
  // Add specific properties if a whole child object has been deleted
  if (cause.children && (!version.children || version.children.length < cause.children.length)) {
    cause.children.forEach((child, i) => {
      if (!version.children || !version.children[i] || version.children[i]._id !== child._id) {
        fields.push(`children[${i}]`);
      }
    })
  }
  // Add specific properties if a whole child object has been added
  if (version.children && (!cause.children || cause.children.length < version.children.length)) {
    version.children.forEach((child, i) => {
      if (!cause.children || !cause.children[i] || cause.children[i]._id !== child._id) {
        fields.push(`children[${i}]`);
      }
    })
  }
  return fields;
};

/**
 * This compares the cause of the conflict with the version before that, to find out what
 * fields have actually changed.
 */
export const getConflictedFields = createSelector(
  getCauseOfConflict,
  getConflictBaseline,
  (cause, conflict) => getConflictsFieldsFromVersions(cause, conflict)
);

export const getActiveEntityVersion = createSelector(
  getSortedEntityTimeline,
  (timeline) => getClosestTimelineItem(timeline)
);

export const getLastEntityTimelineItem = createSelector(
  getEntityTimeline,
  (timeline) => getYoungestTimelineItem(timeline || [])
);

export const getEmployeeAttributes = createSelector(
  getEmployeeModel,
  (model) => getModelAttributes(model)
);

export const getVisibleColumns = createSelector(
  () => employeeListColumns,
  getColumns,
  (allAvailableColumns, savedColumns) => savedColumns
    .map((saved) => {
      const col = allAvailableColumns.find((attribute) => attribute.value === saved);
      if (col) {
        return { ...col, title: col.label, name: saved };
      }

      return { name: saved, label: saved };
    })
);

export const getVisibleAssociationFields = createSelector(
  () => employeeListColumns,
  getColumns,
  (attributes, visible) => attributes.filter((attr) => attr.reference && visible.indexOf(attr.value) >= 0)
);

export const getVisibleAssociationFieldNames = createSelector(
  getVisibleAssociationFields,
  (fields) => fields.map((field) => field.value)
);
