import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ButtonBack } from '../layout/Buttons'
import DetailView from '../layout/DetailView'
import DetailHeaderRow, { Heading } from '../layout/DetailHeaderRow'
import Alert from '../layout/Alert'
import { useNavigate } from 'react-router-dom'

const NotFound = ({ subject, warning }) => {
  const navigate = useNavigate()
  return (
    <DetailView>
      <DetailHeaderRow>
        <Heading>
          <ButtonBack onClick={() => navigate(-1)} />
          <FormattedMessage id="message.not_found" values={{ subject: subject ? <FormattedMessage id={subject} /> : '' }} />
        </Heading>
        {warning && (
          <Alert type="error">
            <FormattedMessage id={warning} />
          </Alert>
        )}
      </DetailHeaderRow>
    </DetailView>
  )
}

NotFound.propTypes = {
  warning: PropTypes.string,
  subject: PropTypes.string,
}

export default NotFound
