import React, { Component } from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

interface BarProps {
  processed: number;
  total: number;
  [propName: string]: any;
}

export const RunProgress = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  width: 100%;
  border-radius: ${(props: any) => props.theme.borderRadius.default};
  box-shadow: ${(props: any) => props.theme.shadows.default};
  padding: 10px 20px;
`;

export const BarContainer = styled('div')`
  width: 100%;
  height: 5px;
  margin-top: 12px;
  border-radius: ${(props: any) => props.theme.borderRadius.default};
  background:  ${(props: any) => props.theme.backgroundColors.lightgray};
  margin-bottom: 1rem;
`;

export const Bar = styled.div.attrs<BarProps>(({ sent, total }) => ({
  style: {
    width: (sent > total) ? '100' : `${(sent / total) * 100}%`,
  }
}))`
  height: 8px;
  width: ${(props: BarProps) => ((props.sent >= props.total) ? '100' : (props.sent / props.total) * 100)}%;
  background: ${(props: BarProps) => props.theme.backgroundColors.blue};
  border-radius: ${(props: BarProps) => props.theme.borderRadius.default};
`;

export const ProgressText = styled('div')`
  width: 150px;
  text-align: center;
  display: flex;
  flex-direction: row;
`;

export const ProgressMetric = styled('span')`
  display: inline-block;
  margin-right: 1rem;
  white-space: nowrap;
`;

interface ProgressStatusProps {
  getStatus: () => Promise<any>;
  labelProcessed: string;
  labelTotal: string;
}

interface ProgressStatusState {
  total: number;
  processed: number;
}

class PollingProgressStatus extends Component<ProgressStatusProps, ProgressStatusState> {
  timer: any;

  constructor(props: ProgressStatusProps) {
    super(props);

    this.state = {
      processed: 0,
      total: 0,
    };
  }

  componentDidMount(): void {
    const { getStatus } = this.props;
    this.timer = setInterval(() => {
      getStatus().then(({ data: { processed, total } }) => this.setState({ processed, total }));
    }, 1000);
  }

  componentWillUnmount(): void {
    clearInterval(this.timer);
  }

  render = () => {
    const { labelProcessed, labelTotal } = this.props;
    const { processed, total } = this.state;

    return (
      <RunProgress>
        <BarContainer className="barContainer">
          <Bar sent={processed} total={total} />
        </BarContainer>
        <ProgressText className="progressText">
          <ProgressMetric className="progressMetric">
            <FormattedMessage id={labelProcessed} />
            :
            {' '}
            <FormattedNumber value={processed} />
          </ProgressMetric>
        </ProgressText>
        <ProgressText>
          <ProgressMetric>
            <FormattedMessage id={labelTotal} />
            :
            {' '}
            <FormattedNumber value={total} />
          </ProgressMetric>
        </ProgressText>
      </RunProgress>
    );
  }
}

export default PollingProgressStatus;
