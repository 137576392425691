import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import Tooltip from '../tooltip/Tooltip';
import Icon from './Icon';

const NavLink = styled(Link)`
  border-radius: ${(props) => props.theme.borderRadius.default};
  color: white;
  display: block;
  height: 48px;
  margin: 0 8px;
  padding: 6px 12px;
  width: 48px;

  &:hover {
    color: white;
    background: rgba(255,255,255,.15);
  }
`;

const LinkText = styled('span')`
  display: none;
`;

const NavItem = ({
  label,
  iconName,
  linkTo,
  onClick,
}) => (
  <Tooltip content={<FormattedMessage id={label} defaultMessage={label} />} placement="right" allowHTML>
    <NavLink to={linkTo}>
      <Icon
        name={iconName}
        size="lg"
        onClick={onClick}
      />
      <LinkText><FormattedMessage id={label} defaultMessage={label} /></LinkText>
    </NavLink>
  </Tooltip>
);

NavItem.propTypes = {
  iconName: PropTypes.string,
  linkTo: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default React.memo(NavItem);
