import styled from 'styled-components/macro';
import background from '../../assets/images/MinBuZaBackground.jpg';

export default styled('div')`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-size: cover;
  background-image: url('${background}');
`;
