import Cleave from 'cleave.js/react';
import styled from 'styled-components/macro';

const CleaveInput = styled(Cleave)`
  &:disabled {
    background-color: ${(props) => props.theme.backgroundColors.lightergray};
  }
  border: 1px solid ${(props) => (props.error ? '#D52B1E' : props.theme.input.borderColor)};
  border-radius: 3px;
  padding: 6px 0 6px 10px;
  height: 34px;
`;

export default CleaveInput;
