import React from 'react';
import { components } from 'react-select';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

/* eslint-disable no-irregular-whitespace */

const SingleValueContainer = styled('div')`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.backgroundColors.gray)};
  border-radius: ${(props) => props.theme.borderRadius.large};
  color: ${(props) => props.theme.textColors.white};
  display: inline-block;

  a, a:hover {
    color: ${(props) => (props.backgroundColor === 'transparent' ? props.theme.colors.secondary : props.theme.backgroundColors.default)};
    margin-left: 10px;
  }
`;

const OptionCard = styled('div')`
  align-items: center;
  color: ${(props) => (props.textColor ? props.textColor : props.theme.textColors.default)};
  display: flex;
  justify-content: space-between;
`;

const EmployeeLabel = (props) => {
  const {
    data: { displayName, paymentCode }
  } = props;

  return (
    <components.SingleValue {...props}>
      {displayName && (
        <SingleValueContainer backgroundColor="transparent">
          <OptionCard>{`${displayName} – (${paymentCode})`}</OptionCard>
        </SingleValueContainer>
      )}
      {!displayName && <FormattedMessage id="placeholder.enter_employee" />}
    </components.SingleValue>
  );
};

EmployeeLabel.propTypes = {
  data: PropTypes.object
};

export default EmployeeLabel;
