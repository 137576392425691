import { Icon as FabricIcon } from 'office-ui-fabric-react/lib/Icon'
import React from 'react'
import styled from 'styled-components/macro'
import { STATUS_APPROVED, STATUS_CONFLICT, STATUS_DRAFT, STATUS_REJECTED, STATUS_REVIEW } from '../../constants/employeeStatus'
import { ICON_APPROVED, ICON_CONFLICT, ICON_DRAFT, ICON_REJECTED, ICON_REVIEW } from '../../constants/icons'

type IconProps = {
  name?: string
  className?: string
  color?: string
  size?: string
  onClick?: () => void
  [key: string]: any
}

interface IconWrapperProps {
  size?: string
  color?: string
}

const sizes = {
  lg: 24,
  md: 20,
  sm: 18,
  xl: 32,
  xs: 12,
}

export const IconWrapper = styled(FabricIcon)<IconWrapperProps>`
  font-size: ${(props) => sizes[props.size || 'sm']}px;
  vertical-align: middle;
  color: ${(props) => (props.color ? props.theme.textColors[props.color] : 'inherit')};
`

const Icon = ({ name, className, size, onClick, ...restProps }: IconProps) => (
  <IconWrapper iconName={name} size={size || 'md'} className={className} onClick={onClick} {...restProps} />
)

export const StatusIcon = ({ status, size }) => {
  switch (status) {
    case STATUS_DRAFT: {
      return <Icon name={ICON_DRAFT} size={size} />
    }
    case STATUS_CONFLICT: {
      return <Icon name={ICON_CONFLICT} size={size} color="primary" />
    }
    case STATUS_APPROVED: {
      return <Icon name={ICON_APPROVED} size={size} />
    }
    case STATUS_REJECTED: {
      return <Icon name={ICON_REJECTED} size={size} color="primary" />
    }
    case STATUS_REVIEW: {
      return <Icon name={ICON_REVIEW} size={size} />
    }
    default: {
      return <Icon name={ICON_DRAFT} size={size} />
    }
  }
}

export default Icon
