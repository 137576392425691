import React from 'react'
import PropTypes from 'prop-types'
import Label from './Label'
import FormFeedback from './FormFeedback'
import FormText from './FormText'
import FormError from './FormError'
import Select from './Select'
import FieldTooltip from '../tooltip/FieldTooltip'

/**
 * TODO: Refactor the way options are passed,
 *       since they will probably need separate keys and values.
 *
 * @param {object} props
 */
const SelectField = (props) => {
  const {
    disabled,
    label,
    id,
    placeholder,
    type,
    feedback,
    helptext,
    input,
    name,
    options,
    isMulti,
    required,
    tooltip,
    onChangeSelect,
    meta: { touched, error },
  } = props

  let selectValue = ''
  if (isMulti) {
    selectValue = options.filter((opt) => ((input.value && Array.isArray(input.value))
      ? input.value.indexOf(opt.value) >= 0 : false
    ))
  } else {
    selectValue = options.find((opt) => opt.value === input.value)
    // In the case of flows, we also pass the correlationId, in order to find the name of the flow
    if (!selectValue && input && input.value) selectValue = options.find((opt) => opt.correlationId === input.value)
    // Delete this correlationId, because we only need it to find the name of the flow
    if (selectValue && selectValue.correlationId) delete selectValue.correlationId
    if (input.value === '') input.value = null
  }

  return (
    <>
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      {tooltip && (
        <FieldTooltip
          placement="top"
          content={tooltip}
          iconColor="gray"
          iconSize="sm"
        />
      )}
      <Select
        {...input}
        isMulti={isMulti}
        isDisabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder || ''}
        options={options}
        value={selectValue}
        onChange={(opt) => {
          input.onChange(
            isMulti && opt
              ? Object.values(opt).map((item) => item.value)
              : (opt && opt.value) || null
          )
          if (onChangeSelect) onChangeSelect(opt)
        }}
        onBlur={() => input.onBlur(input.value)}
        error={error}
        touched={touched}
        isClearable={!required}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {touched && error && (
        <FormError data-testid="select-field-error">{error}</FormError>
      )}
    </>
  )
}

SelectField.defaultProps = {
  disabled: false,
  isMulti: false,
}

SelectField.propTypes = {
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  tooltip: PropTypes.string,
  currentUser: PropTypes.object,
  input: PropTypes.object,
  includeCurrent: PropTypes.bool,
  isMulti: PropTypes.bool,
  filter: PropTypes.func,
  onChangeSelect: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  roles: PropTypes.array,
  required: PropTypes.bool,
}

export default SelectField
