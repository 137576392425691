export const OPERATOR_EQUALS = 'equals';
export const OPERATOR_NOTEQUALS = 'notEquals';
export const OPERATOR_CONTAINS = 'contains';
export const OPERATOR_NOTCONTAINS = 'notContains';
export const OPERATOR_GREATERTHAN = 'gt';
export const OPERATOR_GREATERTHANEQUALS = 'gte';
export const OPERATOR_LESSTHAN = 'lt';
export const OPERATOR_LESSTHANEQUALS = 'lte';
export const OPERATOR_EXISTS = 'exists';
export const OPERATOR_NOTEXISTS = 'notExists';
