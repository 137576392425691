import React from 'react'
import styled from 'styled-components/macro'
import Tippy from '@tippy.js/react'
import theme from '../layout/theme'

const TippyContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { suppressClassNameWarning, a11y, ...otherProps } = props
  return <Tippy {...otherProps} />
}

const StyledTooltip = styled(TippyContainer)`
  background-color: ${theme.backgroundColors.default};
  box-shadow: ${theme.shadows.large};
  color: ${theme.textColors.default};
  font-size: 15px;
  line-height: 18px;
  padding: 0.75rem 1rem;
  text-align: left;

  p:last-child {
    margin-bottom: 0;
  }

  &[x-placement^='top'] .tippy-arrow {
    border-top-color: ${theme.backgroundColors.default};
  }

  &[x-placement^='right'] .tippy-arrow {
    border-right-color: ${theme.backgroundColors.default};
  }

  &[x-placement^='bottom'] .tippy-arrow {
    border-bottom-color: ${theme.backgroundColors.default};
  }

  &[x-placement^='left'] .tippy-arrow {
    border-left-color: ${theme.backgroundColors.default};
  }
`

const Tooltip = (props) => (
  <StyledTooltip {...props} suppressClassNameWarning>
    <span>{props.children}</span>
  </StyledTooltip>
)

Tooltip.defaultProps = {
  animation: 'fade',
  arrow: true,
  delay: 150,
}

export default Tooltip
