import { INIT_EXPRESSION, ADD_CONDITION, UPDATE_CONDITION, REMOVE_CONDITION, SET_CONDITIONS } from '../constants/actionTypes';
import { getExpressionTree } from '../selectors/expressionSelectors';

export const initExpression = (name) => ({
  type: INIT_EXPRESSION,
  payload: {
    name
  }
});

export const setConditions = (name, conditions) => ({
  type: SET_CONDITIONS,
  payload: conditions,
  meta: {
    name
  }
});

export const addCondition = (name, condition, parent) => ({
  type: ADD_CONDITION,
  payload: {
    condition,
    parent
  },
  meta: {
    name
  }
});

export const updateCondition = (name, condition) => ({
  type: UPDATE_CONDITION,
  payload: {
    condition,
  },
  meta: {
    name
  }
});

export const removeSingleCondition = (name, condition, parent) => ({
  type: REMOVE_CONDITION,
  payload: {
    condition,
    parent
  },
  meta: {
    name
  }
});

export const removeCondition = (name, condition, parent, onSubmit) => (dispatch, getState) => {
  dispatch(removeSingleCondition(name, condition, parent));
  //  Submit tree to handleSearch
  onSubmit(getExpressionTree(getState(), name));
};
