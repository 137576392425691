import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import TextField from './TextField';
import Icon from '../layout/Icon'

const OpenInNewWindow = styled(Icon)`
  color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  margin-left: 1rem;
  z-index: 15;
`

const Href = (props) => (
  <a target="_blank" href={props.href} rel="noopener noreferrer">
    <OpenInNewWindow name="OpenInNewWindow" size="md" />
  </a>
)

Href.propTypes = {
  href: PropTypes.string,
}

/**
 * Check if the given url is actually a safe url.
 * This prevents possible XSS attacks where an `href` attribute is used to trigger
 * javascript functions on click.
 *
 * @see https://stackoverflow.com/questions/33644499/what-does-it-mean-when-they-say-react-is-xss-protected/51852579#51852579
 * @see https://medium.com/javascript-security/avoiding-xss-in-react-is-still-hard-d2b5c7ad9412
 *
 * @param {string} url
 */
const isSafe = (url) => {
  if (url.startsWith('https:')) return true;
  if (url.startsWith('http:')) return true;
  return false;
};

const UrlField = ({ href, ...restProps }) => (
  <>
    {href && isSafe(href) && (
      <Href href={href} />
    )}
    <TextField
      type="url"
      pattern="https://.*"
      {...restProps}
    />
  </>
);

UrlField.propTypes = {
  href: PropTypes.string,
  restProps: PropTypes.object,
};

const mapState = (state, props) => ({
  href: props.input.value
});

export default connect(mapState)(UrlField);
