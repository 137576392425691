import React from 'react';
import styled from 'styled-components/macro';
import { Input, Label } from '.';
import FieldTooltip from '../tooltip/FieldTooltip';

const CheckBoxLabel = styled(Label)`
  display: flex;
  flex-direction: column;
`;

const CheckBox = (props) => {
  const {
    id,
    label,
    input,
    tooltip
  } = props;
  return (
    <>
      <CheckBoxLabel htmlFor={input && input.name}>
        {label}
        <Input
          {...input}
          {...props}
          type="checkbox"
          checked={input.value}
          id={id}
        />
      </CheckBoxLabel>
      {tooltip && <FieldTooltip placement="top" content={tooltip} iconColor="gray" iconSize="sm" />}
    </>
  );
};

export default CheckBox;
