import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Input from './Input';
import Label from './Label';
import FormFeedback from './FormFeedback';
import FormText from './FormText';
import FieldTooltip from '../tooltip/FieldTooltip';

const NumberField = (props) => {
  const {
    label,
    id,
    placeholder,
    type,
    feedback,
    helptext,
    tooltip,
    input,
  } = props;

  return (
    <>
      {label && <Label htmlFor={input && input.name}>{label}</Label>}
      {tooltip && <FieldTooltip placement="top" content={tooltip} iconColor="gray" iconSize="sm" />}
      <Input
        {...input}
        {...props}
        type={type || 'number'}
        id={id}
        placeholder={placeholder}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText><FormattedMessage id={helptext} /></FormText>}
    </>
  );
};

NumberField.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  helptext: PropTypes.string,
  feedback: PropTypes.string,
  tooltip: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  required: PropTypes.bool,
};

export default NumberField;
