import styled from 'styled-components/macro';

export default styled('label')`
  display: block;
  color: ${(props) => props.theme.textColors.gray};
  font-weight: bold;
  margin-bottom: 4px;

  &::after {
    content: ${(props) => (props.required ? '" *"' : '')};
    color: ${(props) => props.theme.textColors.red};
  }
`;
