import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { OPERATOR_EQUALS, OPERATOR_NOTEQUALS, OPERATOR_EXISTS, OPERATOR_NOTEXISTS } from '../../constants/operators';
import { removeCondition, updateCondition } from '../../actions/expressionActions';
import { withContext } from '../withContext';
import { conditionShape } from '../../shapes';
import Select from '../../../components/form/Select';
import Label from '../../../components/form/Label';
import EmployeeSelect from '../../../components/_form/EmployeeSelect';
import { ButtonGroup } from '../../../components/layout/Buttons';
import RemoveButton from '../../../components/layout/RemoveButton';
import ConditionItem from '../../layout/ConditionItem';
import LabelSegment from '../../layout/LabelSegment';
import OperatorSegment from '../../layout/OperatorSegment';
import CriteriaSegment from '../../layout/CriteriaSegment';
import InputCriterium from '../../layout/InputCriterium';

class EmployeeCondition extends Component {
  onOperatorChange = ({ value }) => this.props.update({ ...this.props.condition, operator: value });

  onValueChange = ({ value }) => this.props.update({ ...this.props.condition, value });

  getOperators = () => {
    const operators = [
      { value: OPERATOR_EQUALS, label: <FormattedMessage id="label.operator_eq" /> },
      { value: OPERATOR_NOTEQUALS, label: <FormattedMessage id="label.operator_neq" /> }
    ];

    return operators;
  }

  render = () => {
    const { condition, field, remove } = this.props;

    return (
      <ConditionItem>
        <LabelSegment>
          <Label><FormattedMessage id={field.label} /></Label>
        </LabelSegment>
        <OperatorSegment>
          <Select
            value={this.getOperators().find((op) => op.value === (condition.operator || OPERATOR_EQUALS))}
            onChange={this.onOperatorChange}
            options={this.getOperators(condition.field)}
          />
        </OperatorSegment>
        <CriteriaSegment>
          <InputCriterium>
            {![OPERATOR_EXISTS, OPERATOR_NOTEXISTS].includes(condition.operator) && (
              <EmployeeSelect
                value={condition.value}
                onChange={this.onValueChange}
              />
            )}
          </InputCriterium>
          <ButtonGroup>
            <RemoveButton onClick={() => remove(condition)} />
          </ButtonGroup>
        </CriteriaSegment>
      </ConditionItem>
    );
  }
}

EmployeeCondition.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  context: PropTypes.object.isRequired,
  condition: PropTypes.shape(conditionShape),
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  parent: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch, { context: { name, onSubmit }, parent }) => ({
  remove: (condition) => dispatch(removeCondition(name, condition, parent, onSubmit)),
  update: (condition) => dispatch(updateCondition(name, condition)),
});

export default withContext(connect(null, mapDispatch)(EmployeeCondition));
