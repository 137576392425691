export const STATUS_APPROVED = 'APPROVED';
export const STATUS_CONFLICT = 'CONFLICT';
export const STATUS_DRAFT = 'DRAFT';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_REVIEW = 'REVIEW';
export const STATUS_UPDATE = 'UPDATE'; // Status update? Does this actually exist on employees?
export const STATUS_REVOKED = 'REVOKED';

/** @deprecated Use STATUS_DRAFT instead */
export const STATUS_INITIAL = 'INITIAL';
