import { normalize } from 'normalizr';
import schemas from '../schemas';
import {
  DELETE_ENTITY,
  MERGE_ENTITIES,
  SET_ENTITIES,
  RESET_ENTITIES,
  SET_ENTITY_COLLECTION,
  UPDATE_ENTITY
} from '../actionTypes';

export const addEntities = (entities) => ({
  type: MERGE_ENTITIES,
  payload: entities
});

export const setEntities = (entity, items) => ({
  type: SET_ENTITIES,
  payload: {
    entity,
    items,
  }
});

export const updateEntity = ({ entity, entityId, newData }) => ({
  type: UPDATE_ENTITY,
  payload: { entity, entityId, newData }
});

export const deleteEntity = ({ entity, entityId }) => ({
  type: DELETE_ENTITY,
  payload: { entity, entityId }
});

export const resetEntities = (entity) => ({
  type: RESET_ENTITIES,
  payload: { entity }
});

export const addEntityCollection = ({
  collection,
  entities,
  entityIds,
  entityType,
  params,
  total,
  isNew,
  loading
}) => ({
  type: SET_ENTITY_COLLECTION,
  payload: {
    collection,
    entities,
    entityIds,
    entityType,
    params,
    total,
    isNew,
    loading
  }
});

export const handleEntity = (entity, response) => (dispatch) => {
  const { data } = response;

  const schema = Array.isArray(data) ? [schemas[entity]] : schemas[entity];
  const normalizedItems = normalize(data, schema);

  return dispatch(addEntities(normalizedItems.entities));
};

export const handleEntityCollection = (entity, response, params, collection) => (dispatch) => {
  if (!response) throw new Error('Unable to handle collection when response is empty');
  let results;
  let total = 0;
  // Hacky way to allow parsing both legacy responses and new employee search response
  if (response.data && response.data.results) {
    ({ results, total } = response.data);
  } else if (response.data) {
    results = response.data;
    total = Array.isArray(response.data) && response.data.length;
  } else {
    results = response._embedded ? Object.values(response._embedded || {})[0] : [];
    total = response.page.totalElements;
  }

  const normalized = normalize(results, [schemas[entity]]);

  dispatch(addEntityCollection({
    collection,
    entityType: entity,
    entities: normalized.entities,
    entityIds: normalized.result,
    params,
    total,
    isNew: true,
  }));

  return response.data ? response.data : response;
};
