import styled from 'styled-components/macro';

export default styled('textarea')`
  padding: ${(props) => props.theme.input.padding};
  height: ${(props) => (props.autosize ? '36px' : '100px')};
  max-height: 300px;
  width: 100%;
  border: ${(props) => props.theme.input.borderWidth} solid ${(props) => ((props.touched && (props.error !== undefined)) ? props.theme.input.borderColorError : props.theme.input.borderColor)};
  border-radius: ${(props) => props.theme.input.borderRadius};
  font-size: 15px;
  line-height: 20px;
  min-height: ${(props) => `${props.minHeight}px` || 'auto'};
  color: ${(props) => props.theme.textColors.default};

  :focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.input.borderColorFocus};
  }

  :disabled {
    background-color: ${(props) => props.theme.backgroundColors.lightergray};
    color: ${(props) => props.theme.textColors.default};
  }

  ::placeholder {
    color: ${(props) => props.theme.input.placeholderColor};
  }
`;
