import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { AsyncSelect } from './Select';
import { postEmployeesSearch } from '../../api/employeeApi';
import EmployeeOption from '../_form/select/EmployeeOption';
import EmployeeLabel from '../_form/select/EmployeeLabel';
import { sortByProperyAsc } from '../../utilities/arrayUtilities';
import { employeeVersionsRoute } from '../../utilities/routeUtilities';
import Icon from '../layout/Icon';

const ExternalLink = styled(Link)`
  color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  position: absolute;
  right: 60px;
  top: 28px;
  z-index: 10;
`;

const getOptions = (employees) => employees
  .map((option) => ({
    ...option,
    label: option.displayName,
    value: option._entityId,
    link: employeeVersionsRoute(option._entityId)
  }))
  .sort((a, b) => sortByProperyAsc(a, b, 'label'));

class EmployeeSelect extends Component {
  search = (inputValue) => postEmployeesSearch({
    limit: 10,
    offset: 0,
    sort: 'initials',
    direction: 'asc',
    keywords: inputValue !== '' ? inputValue : undefined,
    referenceDate: this.props.referenceDate || undefined
  })
    .then((response) => getOptions(response.data._embedded.employeeExtendedViews));

  render() {
    const { disabled, employee, input } = this.props;

    return (
      <>
        {employee && employee._entityId && <ExternalLink to={employeeVersionsRoute(employee._entityId)} target="_blank"><Icon name="OpenInNewWindow" /></ExternalLink>}
        <AsyncSelect
          components={{
            Option: EmployeeOption,
            SingleValue: EmployeeLabel
          }}
          isDisabled={disabled}
          cacheOptions
          defaultOptions
          value={employee}
          defaultValue={employee}
          loadOptions={this.search}
          onChange={(opt) => input.onChange(opt.value)}
          onBlur={() => input.onBlur(input.value)}
        />
      </>
    );
  }
}

EmployeeSelect.propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  employee: PropTypes.object,
  referenceDate: PropTypes.string
};

export default EmployeeSelect;
