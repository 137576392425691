import axios from 'axios';
import { get, post, vdbsApi } from '../utilities/apiUtilities';

const paymentCancelToken = axios.CancelToken;
let searchCallCancel = null;

export const postPaymentsSearch = (data) => {
  searchCallCancel = paymentCancelToken.source();

  return post({
    url: `${vdbsApi}/payments/search`,
    data,
    cancelToken: searchCallCancel.token
  });
};

export const getPayments = () => get({
  url: `${vdbsApi}/payments`
});

export const getProformaPayment = (referenceDate, entityId, useApprovedDossiers) => post({
  url: `${vdbsApi}/payments/proforma/${referenceDate}/employees/${entityId}`,
  data: { useApprovedFlows: true, useApprovedDossiers }
});

export const getPayment = (id) => {
  if (searchCallCancel) {
    searchCallCancel.cancel();
    searchCallCancel = null;
  }
  return get({
    url: `${vdbsApi}/payments/${id}`
  });
};

export const getPeriods = () => get({
  url: `${vdbsApi}/payments/periods`
});

export const getUniversalPaymentPeriods = (entityId) => get({
  url: `${vdbsApi}/payments/universalperiods/${entityId}`
});

export const downloadProforma = (payment, useApprovedDossiers, printerType) => post({
  url: `${vdbsApi}/payments/proforma-specification?useApprovedDossiers=${useApprovedDossiers}&printerType=${printerType}`,
  headers: {
    Accept: '*/*',
  },
  responseType: 'blob',
  data: payment
});

export const downloadPayment = (paymentId, printerType) => get({
  url: `${vdbsApi}/payments/${paymentId}/specification?printerType=${printerType}`,
  headers: {
    Accept: '*/*',
  },
  responseType: 'blob',
});

export const getPaymentExport = (period) => post({
  url: `${vdbsApi}/payments/${period}/export`,
  headers: {
    Accept: '*/*',
  },
  responseType: 'blob',
});

export const getEmployerPaymentExport = (period) => post({
  url: `${vdbsApi}/payments/${period}/employer-export`,
  headers: {
    Accept: '*/*',
  },
  responseType: 'blob',
});

export const generatePaymentsByMonth = (date) => post({
  url: `${vdbsApi}/payments/${date}`,
  timeout: 180000
});

/**
 * @returns status 202 Accepted
 */
export const sendEmails = (period, data) => post({
  url: `${vdbsApi}/email/${period}/send`,
  data
});

export const getEmailStatus = (period, data) => post({
  url: `${vdbsApi}/email/${period}/status`,
  data
});

/**
 * @returns 201 No Content
 */
export const excludePayment = (id) => post({
  url: `${vdbsApi}/email/${id}/exclude`
});

/**
 * @returns 201 No Content
 */
export const includePayment = (id) => post({
  url: `${vdbsApi}/email/${id}/include`
});

export const postEmailRecipientForRegulation = (period, regulation, included) => post({
  url: `${vdbsApi}/email/recipient/regulation`,
  data: { period, regulation, included }
});

export const runProforma = (employee, date) => post({
  url: `${vdbsApi}/payments/proforma/${date}/anonymous`,
  responseType: 'blob',
  data: employee
})

export const uploadPaymentToPdirekt = (period) => post({
  url: `${vdbsApi}/pdirekt/send-payments/${period}`
});
