import Cleave from 'cleave.js/react';
import styled from 'styled-components/macro';

export default styled(Cleave)`
  padding: ${(props) => props.theme.input.padding};
  padding-right: 0;
  height: 34px;
  width: 100%;
  font-size: 15px;
  border: ${(props) => props.theme.input.borderWidth} solid ${(props) => ((props.touched && (props.error !== undefined)) ? props.theme.input.borderColorError : props.theme.input.borderColor)};
  border-radius: ${(props) => props.theme.input.borderRadius};

  :focus {
    outline: none;
    border: 2px solid ${(props) => ((props.touched && (props.error !== undefined)) ? props.theme.input.borderColorError : props.theme.input.borderColorFocus)};
  }

  :disabled {
    background-color: ${(props) => props.theme.backgroundColors.lightergray};
    color: ${(props) => props.theme.textColors.default};
  }

  ::placeholder {
    color: ${(props) => props.theme.input.placeholderColor};
  }
`;
