import styled from 'styled-components/macro';

export default styled('input')`
  border-radius: ${(props) => props.theme.input.borderRadius};
  border: ${(props) => props.theme.input.borderWidth} solid ${(props) => ((props.touched && (props.error !== undefined)) ? props.theme.input.borderColorError : props.theme.input.borderColor)};
  font-size: 15px;
  height: ${(props) => (props.type === 'checkbox' ? '18px' : '34px')};
  padding-right: 0;
  padding: ${(props) => props.theme.input.padding};
  width:  ${(props) => (props.type === 'checkbox' ? '18px' : '100%')};

  :focus {
    outline: none;
    border: 2px solid ${(props) => ((props.touched && (props.error !== undefined)) ? props.theme.input.borderColorError : props.theme.input.borderColorFocus)};
  }

  :disabled {
    background-color: ${(props) => props.theme.backgroundColors.lightergray};
    color: ${(props) => props.theme.textColors.default};
  }

  ::placeholder {
    color: ${(props) => props.theme.input.placeholderColor};
  }
`;
