import { UPDATE_SERVICEWORKER } from '../actionTypes';

const initialState = {
  serviceWorkerUpdated: false,
};

const serviceWorkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SERVICEWORKER: {
      return {
        ...state,
        serviceWorkerUpdated: true
      };
    }
    default: {
      return state;
    }
  }
};

export default serviceWorkerReducer;
