import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

export const AlertWrap = styled('div')`
  margin: 0 0 ${(props) => props.theme.margin.lg} 0;
  padding: 20px 25px;
  border-left: 5px solid ${(props) => props.theme.infoColors[props.type]};
  background-color: ${(props) => props.theme.infoColors[`${props.type}Light`]};
`;

export const AlertHeader = styled('h5')``;

export const AlertBody = styled('p')`
  margin-bottom: 0;
  color: ${(props) => props.theme.textColors.gray};
`;

const Alert = ({ children, header, type }) => (
  <AlertWrap type={type}>
    <AlertHeader>{header}</AlertHeader>
    {children && (
      <AlertBody>
        {children}
      </AlertBody>
    )}
  </AlertWrap>
);

Alert.propTypes = {
  children: PropTypes.any,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

export default Alert;
