import { UPDATE_SERVICE_STATUS, SERVICE_POWERUP_PENDING } from '../actionTypes';

const initialState = {
  status: null,
  isPowerupPending: false,
};

const serviceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SERVICE_STATUS: {
      return {
        ...state,
        status: payload,
        isPowerupPending: payload === 'RUNNING' ? state.isPowerupPending : false,
      };
    }
    case SERVICE_POWERUP_PENDING: {
      return {
        ...state,
        isPowerupPending: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default serviceReducer;
