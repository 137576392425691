import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const composeEnhancers = composeWithDevTools({
  name: 'Redux',
  realtime: true,
  trace: true,
  traceLimit: 25
});

const composers = (env) => {
  if (env === 'production') {
    return compose(
      applyMiddleware(
        thunkMiddleware
      )
    );
  }
  return composeEnhancers(
    applyMiddleware(
      thunkMiddleware
    )
  );
};



const store = createStore(
  rootReducer,
  {
    authentication: {
      // Note; we use the key as a string, instead of a imported constant here
      // since somehow the import is not resolved on an initial load.
      isAuthenticated: (
        localStorage.getItem('vdbs_auth_token') !== undefined
        && localStorage.getItem('vdbs_auth_token') !== null
      ),
    }
  },
  composers(process.env.REACT_APP_ENV)
);

export default store;
