import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { FormError, Label } from '../form';
import FieldTooltip from '../tooltip/FieldTooltip';

const RadioButtonContainer = styled('div')`
  align-content: center;
  display: flex;
  flex-direction: column;
`;

const RadioOptionsContainer = styled('div')`
  align-content: center;
  display: flex;
  flex-direction: row;
`;

const RadioLabel = styled(Label)`
  cursor: pointer;
  padding: 0 2rem 0 0;
  align-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    cursor: pointer;
    margin-left: .25rem;
    margin-right: .5rem;
  }
`;

const RadioButtonField = ({ label, errors, field, options, tooltipLabel, intl: { formatMessage }, ...restProps }) => (
  <RadioButtonContainer>
    {label && (
      <Label htmlFor={field && field.name}>
        <FormattedMessage id={label} />
      </Label>
    )}
    <RadioOptionsContainer>
      {options.map((option) => (
        <>
          <RadioLabel key={option.value}>
            <input
              {...field}
              {...restProps}
              type="radio"
              value={option.value}
              name={field.name}
              checked={option.value === field.value}
            />
            <FormattedMessage id={option.label} />
          </RadioLabel>
          {tooltipLabel && (
            <FieldTooltip
              placement="right"
              content={formatMessage({ id: tooltipLabel })}
              iconColor="gray"
              iconSize="sm"
              right="-112px"
              minWidth="150px"
            />
          )}
        </>
      ))}
    </RadioOptionsContainer>
    {errors && errors[field.name] && (
      <FormError>
        <FormattedMessage id={errors[field.name]} />
      </FormError>
    )}
  </RadioButtonContainer>
);

RadioButtonField.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.array,
  field: PropTypes.object,
  options: PropTypes.array,
  tooltipLabel: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  })
}

export default injectIntl(RadioButtonField);
