import moment from 'moment';

export const DISPLAY_DATE = 'DD-MM-YYYY';
export const DISPLAY_DATETIME = 'DD-MM-YYYY HH:mm';
export const DISPLAY_YEARMONTH = 'MMMM YYYY';
export const DISPLAY_YEARMONTH_NUMERICAL = 'MM-YYYY';
export const INTERNAL_DATE = 'YYYY-MM-DD';
export const INTERNAL_DATETIME = moment.ISO_8601;
export const INTERNAL_YEARMONTH = 'YYYY-MM';
export const P_DIRECT_DATEFORMAT = 'DD_MM_YY';
