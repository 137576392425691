import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import DateInput from '../form/DateInput';
import { INTERNAL_DATE } from '../../constants/dateFormats';

const DateWrapper = styled('div')`
  align-items: center;
  display: flex;
  height: 36px;
`;

const ReferenceDateField = ({ handleDate, value }) => (
  <DateWrapper>
    <DateInput
      value={moment(value).format(INTERNAL_DATE)}
      onChange={(date) => handleDate(`${date}T00:00:00.000Z`)}
    />
  </DateWrapper>
);

ReferenceDateField.propTypes = {
  handleDate: PropTypes.func,
  value: PropTypes.string,
};

export default ReferenceDateField;
