import { get, post, put, vdbsApi } from '../utilities/apiUtilities';
import { NONE, NORMAL } from '../constants/partnerTypes';
import { STATUS_DRAFT } from '../constants/employeeStatus';

/**
 * TODO: This should be handled by backend and is currently a hack to resolve conflicts
 * @param {object} values
 */
const clearData = (values) => {
  if (values.conflict) {
    // Dirty hack to make sure the backend does not keep `CONFLICT` as the status
    // after actually resolving the conflict
    values._status = STATUS_DRAFT;
  }
  delete values._offset;

  if (values && values.partner && values.partnerType && (values.partnerType === NONE)) {
    delete values.partner;
  }
  //  reset partnerTandem value
  if (
    values
    && values.partnerType
    && ((values.partnerType === NONE) || (values.partnerType === NORMAL))
  ) {
    values.partnerTandem = null;
  }

  // This holds the populated tandem partner, which should be passed to the backend entirely
  delete values.tandemPartnerEntity;

  return values;
};

export const getEmployeeApproved = (entityId) => get({
  url: `${vdbsApi}/employees/${entityId}/approved`
});

export const getEmployeeNotRevoked = (entityId) => get({
  url: `${vdbsApi}/employees/${entityId}`
});

export const getEmployeeById = (id) => get({
  url: `${vdbsApi}/employees/versions/${id}`
});

export const postEmployee = (data) => post({
  url: `${vdbsApi}/employees`,
  data
});

export let employeeAbortController
export const postEmployeesSearch = (params) => {
  if (employeeAbortController) {
    employeeAbortController.abort();
  }
  employeeAbortController = new AbortController();
  return post({
    url: `${vdbsApi}/employees/search`,
    data: params,
    signal: employeeAbortController.signal,
  });
}

export const postEmployeesExport = (searchParams) => post({
  url: `${vdbsApi}/employees/export`,
  headers: {
    Accept: '*/*'
  },
  data: searchParams,
  responseType: 'blob'
});

export const getTimeline = (entityId) => get({
  url: `${vdbsApi}/employees/${entityId}/versions`
});

export const postVersion = (entityId, values) => post({
  url: `${vdbsApi}/employees/${entityId}`,
  data: clearData(values)
});

export const putVersion = (entityId, values) => put({
  url: `${vdbsApi}/employees/${entityId}`,
  data: clearData(values)
});

export const postVersionResolve = (entityId, values) => post({
  url: `${vdbsApi}/employees/${entityId}/resolve`,
  data: clearData(values)
});

export const postVersionIgnore = (versionId) => post({
  url: `${vdbsApi}/employees/${versionId}/ignore`
});

export const putVersionApprove = (entityId, id) => post({
  url: `${vdbsApi}/employees/${entityId}/versions/${id}/approve`,
});

export const putVersionReject = (entityId, id, data) => post({
  url: `${vdbsApi}/employees/${entityId}/versions/${id}/reject`,
  data
});

export const putVersionReview = (entityId, id, data) => put({
  url: `${vdbsApi}/employees/${entityId}/versions/${id}/review`,
  data
});

export const getEmployeeAllowances = (employeeId) => get({
  url: `${vdbsApi}/employees/${employeeId}/allowances`,
});

export const putVersionRevoke = (entityId, id) => put({
  url: `${vdbsApi}/employees/${entityId}/versions/${id}/revoke`,
});
