import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components/macro'
import { Form, Field, Formik } from 'formik'
// import { useSnackbar } from 'notistack'
import { Col } from 'styled-bootstrap-grid'
import { Button, ButtonGroup } from '../layout/Buttons'
import Modal from '../layout/Modal'
import FormGroup from '../layout/FormGroup'
import UserSelectField from '../_form/UserSelectField'
import { ROLE_ADMIN, ROLE_PAYER, ROLE_USER, ROLE_VIEWER } from '../../constants/roles'
import { post, vdbsApi } from '../../utilities/apiUtilities'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../selectors/authenticationSelectors'
import { useLocation } from 'react-router-dom'

const Warning = styled('div')`
  position: fixed;
  top: 5px;
  left: 40%;
  max-width: 350px;
  padding: 8px 40px;
  box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px, rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
  border-radius: 20px;
  background-color: ${({ theme, environment }) => theme.navbarBackgrounds[environment] || '#D52B1E'};
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.2px;
  z-index: 99;
`

const EnvironmentBanner = () => {
  const [showImpersonateModal, setShowImpersonateModal] = useState(false)
  const user = useSelector(getCurrentUser)
  const { pathname } = useLocation()

  if (process.env.REACT_APP_ENV === 'production') {
    return null
  }

  const handleSubmit = async (values) => {
    const { userId } = values
    const { data } = await post({ url: `${vdbsApi}/login/impersonate?username=${userId}` })
    window.location = `${data}&returnUrl=${encodeURIComponent(pathname)}`
  }

  const handleValidate = (values) => {
    const errors = {}
    if (!values.userId) {
      errors.userId = 'message.required'
    }
    return errors
  }

  const handleClick = () => user && user.roles?.indexOf('ROLE_SYSTEM') >= 0 && setShowImpersonateModal(true)

  return (
    <>
      <Warning environment={process.env.REACT_APP_ENV} onClick={handleClick}>
        <FormattedMessage id={`message.environment_${process.env.REACT_APP_ENV}`} defaultMessage={`message.environment_${process.env.REACT_APP_ENV}`} />
      </Warning>
      {showImpersonateModal && (
        <Modal header={<FormattedMessage id="title.impersonate" defaultMessage="title.impersonate" />} onClose={() => setShowImpersonateModal(false)}>
          <Formik initialValues={{ userId: '' }} validate={handleValidate} onSubmit={handleSubmit}>
            {() => (
              <Form>
                <FormGroup row>
                  <Col>
                    <Field
                      name="userId"
                      label="label.user"
                      autoFocus
                      required
                      component={UserSelectField}
                      filter={(option) => option.id !== user.id}
                      roles={[ROLE_USER, ROLE_ADMIN, ROLE_PAYER, ROLE_VIEWER]}
                    />
                  </Col>
                </FormGroup>
                <ButtonGroup>
                  <Button type="submit" color="primary">
                    <FormattedMessage id="button.confirm" />
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  )
}

export default EnvironmentBanner
