import { UPDATE_SETTINGS } from '../actionTypes';

const initialState = {};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SETTINGS: {
      return {
        ...state,
        [action.key]: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default settingsReducer;
