import React from 'react';
import { ToastContainer, Flip } from 'react-toastify';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Icon from './Icon';
import 'react-toastify/dist/ReactToastify.css';
import { ICON_CLOSE } from '../../constants/icons';

const MyToastContainer = styled('div')`
  .Toastify__toast {
    border-radius: ${(props) => props.theme.borderRadius.default};
    padding: 10px;
  }

  .Toastify__toast--default  {
    color: ${(props) => props.theme.textColors.gray};
  }

  .Toastify__toast-container--top-center {
    margin-left: -270px;
  }

  .Toastify__toast-container {
    width: 540px;
  }
`;

const ToasterWrapper = (props) => <MyToastContainer><ToastContainer {...props} /></MyToastContainer>;

const CloseButton = ({ closeToast }) => (<Icon name={ICON_CLOSE} size="sm" onClick={closeToast} />);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

const Toaster = () => (
  <ToasterWrapper
    position="top-center"
    autoClose={5000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover
    transition={Flip}
    closeButton={<CloseButton />}
  />
);

export default Toaster;
