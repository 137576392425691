export const STRING = 'STRING';
export const BOOLEAN = 'BOOLEAN';
export const DOUBLE = 'DOUBLE';
export const CURRENCY = 'CURRENCY';
export const INTEGER = 'INTEGER';
export const DATE = 'DATE';
export const DATE_STRING = 'DATE_STRING';
export const TEXT = 'TEXT';
export const OBJECT = 'OBJECT';
export const ARRAY = 'ARRAY';
export const RELATION = 'RELATION';
export const URL = 'URL';
