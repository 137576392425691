import React, { PureComponent, lazy } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchUser } from '../actions/authenticationActions'
import { isAuthenticated } from '../selectors/authenticationSelectors'
import RoleSelector from '../selectors/hasRoleSelectors'
const EmployeeDetail = lazy(() => import('./EmployeeDetail'))
const Allowances = lazy(() => import('../components/employee/Allowances'))
const EmployeePayments = lazy(() => import('../components/employee/EmployeePayments'))
const EmployeePaymentReport = lazy(() => import('../components/employee/EmployeePaymentReport'))
const EmployeeCorrections = lazy(() => import('../components/employee/EmployeeCorrections'))
const EmployeeReimbursements = lazy(() => import('../components/employee/EmployeeReimbursements'))
const PaymentDetail = lazy(() => import('../components/payment/PaymentDetail'))
const AccountList = lazy(() => import('./AccountList'))
const AccountReport = lazy(() => import('./AccountReport'))
const AllowanceList = lazy(() => import('./AllowanceList'))
const FlowList = lazy(() => import('./FlowList'))
const FlowDetail = lazy(() => import('./FlowDetail'))
const AllowanceDetail = lazy(() => import('../components/allowance/AllowanceDetail'))
const DataTableList = lazy(() => import('./DataTableList'))
const DataTableDetail = lazy(() => import('./DataTableDetail'))
const EmployeeList = lazy(() => import('./EmployeeList'))
const ModelsList = lazy(() => import('./ModelsList'))
const ModelsDetail = lazy(() => import('./ModelDetail'))
const PeriodList = lazy(() => import('../components/payment/PeriodList'))
const PaymentList = lazy(() => import('./PaymentList'))
const EmailPayments = lazy(() => import('../components/payment/EmailPayments'))
const ProcessPayments = lazy(() => import('../components/payment/ProcessPayments'))
const UserList = lazy(() => import('./UserList'))
const UserDetail = lazy(() => import('./UserDetail'))
const MonthRun = lazy(() => import('./MonthRun'))
const AuditList = lazy(() => import('./AuditList'))
const CorrectionList = lazy(() => import('./CorrectionList'))
const CorrectionDetail = lazy(() => import('./CorrectionDetail'))
const AdvanceList = lazy(() => import('./AdvanceList'))
const AdvanceBalancesList = lazy(() => import('./AdvanceBalancesList'))
const AdvanceDetail = lazy(() => import('./AdvanceDetail'))
const InstalmentDetail = lazy(() => import('./InstalmentDetail'))
const ReimbursementList = lazy(() => import('./ReimbursementList'))
const ReimbursementDetail = lazy(() => import('./ReimbursementDetail'))
const ReimbursementEmailStatus = lazy(() => import('../components/reimbursement/ReimbursementEmailStatus'))
const ReimbursementPayments = lazy(() => import('../components/reimbursement/ReimbursementPayments'))
const ReimbursementPaymentExport = lazy(() => import('../components/reimbursement/ReimbursementPaymentExport'))
const ReimbursementPeriodList = lazy(() => import('../components/reimbursement/ReimbursementPeriodList'))
const EmailReimbursements = lazy(() => import('../components/reimbursement/EmailReimbursements'))
import Token from './Token'
import Login from './Login'
import Logout from './Logout'
import AuthFailed from './AuthFailed'
import ServiceStandby from './ServiceStandby'
import NotFound from '../components/route/NotFound'
import { serviceHeartbeat } from '../actions/serviceActions'
const PaymentEmailStatus = lazy(() => import('../components/payment/PaymentEmailStatus'))
const ProformaForm = lazy(() => import('../components/payment/ProformaForm'))
import AnonymousProformaForm from  '../components/form/AnonymousProformaForm'
import PDirektList, { viewType } from '../components/pdirekt/PDirektList'
const UniversalPaymentPeriodList = lazy(() => import('../components/payment/UniversalPaymentPeriodList'))
const UniversalPaymentList = lazy(() => import('../components/employee/UniversalPaymentList'))
const SnapshotList = lazy(() => import('../components/backups/SnapshotList'))
import Layout from './Layout'
import LayoutNavigation from './LayoutNavigation'
import PrivateRoute from '../components/login/PrivateRoute'
import EmployeeNav from '../components/employee/EmployeeNav'
import AdvanceNav from './AdvanceNav'
import AccountNav from './AccountNav'
import 'react-toastify/dist/ReactToastify.css'

class Root extends PureComponent {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      // If we already have an auth token at mount, we need to fetch the current user
      this.props.fetchUser()
    } else {
      this.props.serviceHeartbeat()
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route key="logout" path="/logout" element={<Logout />} />
          <Route key="authFailed" path="/auth/failed" element={<AuthFailed />} />
          <Route key="token" path="/auth" element={<Token />} />
          <Route key="green" path="/standby" element={<ServiceStandby />} />
          <Route key="login" path="/login" element={<Login redirect="/proforma" />} />
          <Route
            key="proforma"
            path="/proforma"
            element={
              <PrivateRoute redirect="/login" hasAccess={RoleSelector.canViewAnonymousProforma}>
                <AnonymousProformaForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/employees/*"
            element={
              <PrivateRoute>
                <LayoutNavigation navigation={<EmployeeNav />} />
              </PrivateRoute>
            }
          >
            <Route path=":entityId" element={<Navigate to="employees/:entityId/versions" />} />
            <Route
              key="employeeDetail"
              path=":entityId/versions"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewEmployees}>
                  <EmployeeDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeDetailVersion"
              path=":entityId/versions/:id"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewEmployees}>
                  <EmployeeDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeAllowances"
              path=":entityId/allowances/:allowanceId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAllowances}>
                  <Allowances />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeAllowances"
              path=":entityId/allowances"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAllowances}>
                  <Allowances />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeSpecificationsPeriod"
              path=":entityId/specifications/:period"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <UniversalPaymentList />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeSpecifications"
              path=":entityId/specifications"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <UniversalPaymentPeriodList />
                </PrivateRoute>
              }
            />
            <Route
              key="employeePaymentDetail"
              path=":entityId/payments/:paymentId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <PaymentDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="employeePayments"
              path=":entityId/payments"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <EmployeePayments />
                </PrivateRoute>
              }
            />
            <Route
              key="employeePaymentReport"
              path=":entityId/paymentreport"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <EmployeePaymentReport />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeCorrectionDetail"
              path=":entityId/corrections/:correctionId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewCorrections}>
                  <CorrectionDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeCorrectionDetailVersion"
              path=":entityId/corrections/:correctionId/:id"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewCorrections}>
                  <CorrectionDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeCorrections"
              path=":entityId/corrections"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewCorrections}>
                  <EmployeeCorrections />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeReimbursementDetail"
              path=":entityId/reimbursements/:reimbursementId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewReimbursements}>
                  <ReimbursementDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeReimbursements"
              path=":entityId/reimbursements"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewReimbursements}>
                  <EmployeeReimbursements />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeProforma"
              path=":entityId/proforma"
              element={
                <PrivateRoute hasAccess={RoleSelector.canRunProforma}>
                  <ProformaForm />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeProforma"
              path=":entityId/proforma/:paymentId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canRunProforma}>
                  <ProformaForm />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeAdvanceDetail"
              path=":entityId/advances/:advanceId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAdvances}>
                  <AdvanceDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeAdvances"
              path=":entityId/advances"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAdvances}>
                  <AdvanceList />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/advances/*"
            element={
              <PrivateRoute>
                <LayoutNavigation navigation={<AdvanceNav />} />
              </PrivateRoute>
            }
          >
            <Route
              key="advances"
              index
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAdvances}>
                  <AdvanceList />
                </PrivateRoute>
              }
            />
            <Route
              key="advanceBalances"
              path="balances"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAdvances}>
                  <AdvanceBalancesList />
                </PrivateRoute>
              }
            />
            <Route
              key="AdvanceDetail"
              path=":advanceId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAdvances}>
                  <AdvanceDetail />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/accounts/*"
            element={
              <PrivateRoute>
                <LayoutNavigation navigation={<AccountNav />} />
              </PrivateRoute>
            }
          >
            <Route
              key="accountsAll"
              index
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAccounts}>
                  <AccountList />
                </PrivateRoute>
              }
            />
            <Route
              key="accountsReport"
              path="report"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAccounts}>
                  <AccountReport />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route
              key="employees"
              path="employees"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewEmployees}>
                  <EmployeeList />
                </PrivateRoute>
              }
            />
            <Route
              key="employeeAdd"
              path="employees/new"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewEmployees}>
                  <EmployeeDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="allowances"
              path="allowances"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAllowances}>
                  <AllowanceList />
                </PrivateRoute>
              }
            />
            <Route
              key="monthrun"
              path="monthrun"
              element={
                <PrivateRoute hasAccess={RoleSelector.canExecuteMonthrun}>
                  <MonthRun />
                </PrivateRoute>
              }
            />
            <Route
              key="allowanceDetailView"
              path="allowances/:allowanceId/:entityId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAllowances}>
                  <AllowanceDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="flows"
              path="studio/flows"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewFlows}>
                  <FlowList />
                </PrivateRoute>
              }
            />
            <Route
              key="flowsDetailView"
              path="studio/flows/:flowId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewFlows}>
                  <FlowDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="flowsDetailView"
              path="studio/flows/:flowId/:id"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewFlows}>
                  <FlowDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="datatables"
              path="studio/datatables"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewDataTables}>
                  <DataTableList />
                </PrivateRoute>
              }
            />
            <Route
              key="datatablesDetail"
              path="studio/datatables/:tableId/:id"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewDataTables}>
                  <DataTableDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="datatablesDetail"
              path="studio/datatables/:tableId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewDataTables}>
                  <DataTableDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="models"
              path="studio/models"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewModels}>
                  <ModelsList />
                </PrivateRoute>
              }
            />
            <Route
              key="modelsDetail"
              path="studio/models/:id"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewModel}>
                  <ModelsDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="payments"
              path="periods"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <PeriodList />
                </PrivateRoute>
              }
            />
            <Route
              key="reimbursementPeriods"
              path="reimbursements/periods"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewReimbursements}>
                  <ReimbursementPeriodList />
                </PrivateRoute>
              }
            />
            <Route
              key="emailReimbursementsStatus"
              path="reimbursements/email/:period/status"
              element={
                <PrivateRoute hasAccess={RoleSelector.canEmailReimbursements}>
                  <ReimbursementEmailStatus />
                </PrivateRoute>
              }
            />
            <Route
              key="periodDetail"
              path="payments/period/:period"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <PaymentList />
                </PrivateRoute>
              }
            />
            <Route
              key="paymentsPDirektList"
              path="payments/pdirekt/:paymentId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canExportPayments}>
                  <PDirektList type={viewType.ALLOWANCE} />
                </PrivateRoute>
              }
            />
            <Route
              key="reimbursementsPDirektList"
              path="reimbursements/pdirekt/:paymentId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canExportReimbursements}>
                  <PDirektList type={viewType.REIMBURSEMENT} />
                </PrivateRoute>
              }
            />
            <Route
              key="processPayments"
              path="payments/process"
              element={
                <PrivateRoute hasAccess={RoleSelector.canGeneratePayments}>
                  <ProcessPayments />
                </PrivateRoute>
              }
            />
            <Route
              key="emailPayments"
              path="payments/email/:period"
              element={
                <PrivateRoute hasAccess={RoleSelector.canEmailPayments}>
                  <EmailPayments />
                </PrivateRoute>
              }
            />
            <Route
              key="emailReimbursements"
              path="reimbursements/email/:period"
              element={
                <PrivateRoute hasAccess={RoleSelector.canEmailReimbursements}>
                  <EmailReimbursements />
                </PrivateRoute>
              }
            />
            <Route
              key="emailPaymentsStatus"
              path="payments/email/:period/status"
              element={
                <PrivateRoute hasAccess={RoleSelector.canEmailPayments}>
                  <PaymentEmailStatus />
                </PrivateRoute>
              }
            />
            <Route
              key="paymentDetail"
              path="payments/:paymentId/:entityId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <PaymentDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="audits"
              path="audits"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewAudits}>
                  <AuditList />
                </PrivateRoute>
              }
            />
            <Route
              key="corrections"
              path="corrections"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewCorrections}>
                  <CorrectionList />
                </PrivateRoute>
              }
            />
            <Route
              key="correctionDetail"
              path="corrections/:correctionId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewCorrections}>
                  <CorrectionDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="correctionDetail"
              path="corrections/:correctionId/:id"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewCorrections}>
                  <CorrectionDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="reimbursements"
              path="reimbursements"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewReimbursements}>
                  <ReimbursementList />
                </PrivateRoute>
              }
            />
            <Route
              key="reimbursementDetail"
              path="reimbursements/:reimbursementId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewReimbursements}>
                  <ReimbursementDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="instalmentDetail"
              path="instalments/:instalmentId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewPayments}>
                  <InstalmentDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="reimbursementPayments"
              path="reimbursement/payments"
              element={
                <PrivateRoute hasAccess={RoleSelector.canExportReimbursements && RoleSelector.canViewReimbursements}>
                  <ReimbursementPayments />
                </PrivateRoute>
              }
            />
            <Route
              key="backups"
              path="backups"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewBackups}>
                  <SnapshotList />
                </PrivateRoute>
              }
            />
            <Route
              key="reimbursementPaymentsExport"
              path="reimbursement/payments/export"
              element={
                <PrivateRoute hasAccess={RoleSelector.canExportReimbursements && RoleSelector.canViewReimbursements}>
                  <ReimbursementPaymentExport />
                </PrivateRoute>
              }
            />
            <Route
              key="users"
              path="users"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewUsers}>
                  <UserList />
                </PrivateRoute>
              }
            />
            <Route
              key="userAdd"
              path="users/new/:modelId"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewUser}>
                  <UserDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="userDetail"
              path="users/:id"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewUser}>
                  <UserDetail />
                </PrivateRoute>
              }
            />
            <Route
              key="userDetailEdit"
              path="users/:entityId/:id/:action"
              element={
                <PrivateRoute hasAccess={RoleSelector.canViewUser}>
                  <UserDetail />
                </PrivateRoute>
              }
            />
            <Route path="" element={<Navigate to="/employees" />} />
            <Route path="*" element={<NotFound />} subject="title.page" />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

Root.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}

const mapState = (state) => ({
  isAuthenticated: isAuthenticated(state),
})

const mapDispatch = (dispatch) => ({
  fetchUser: () => dispatch(fetchUser()),
  serviceHeartbeat: () => dispatch(serviceHeartbeat()),
})

export default connect(mapState, mapDispatch)(Root)
