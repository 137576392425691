import React from 'react';
import ReactSelect from 'react-select';
import Async from 'react-select/async';
import theme from '../layout/theme';

const styles = ({ error, touched }) => ({
  control: (style, state) => ({
    ...style,
    border: `1px solid ${touched && error ? theme.input.borderColorError : theme.input.borderColor}`,
    backgroundColor: state.isDisabled ? '#f6f7f9' : 'white',
    height: 'auto',
    minHeight: '34px',
    fontSize: '15px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '1px 8px',
  }),
  singleValue: (style, state) => ({
    ...style,
    color: state.isDisabled ? '#202124' : '#202124',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '6px',
  }),
  menu: (styles) => ({
    ...styles,
    minWidth: '300px',
  }),
});

export const AsyncSelect = (props) => <Async {...props} styles={styles(props)} />;

export default (props) => <ReactSelect {...props} styles={styles(props)} />;
