import styled from 'styled-components/macro';
import Label from '../../components/form/Label';

const LabelSegment = styled('div')`
  width: 200px;
  padding-right: 20px;

  ${Label} {
    padding: 6px 0;
  }
`;

export default LabelSegment;
