import React from 'react';
import styled from 'styled-components/macro';
import { Table } from '@devexpress/dx-react-grid-bootstrap4';

const getBackgroundColor = (row, theme) => (row.selected ? theme.backgroundColors.rowHighlight : null);

export const Row = styled('tr')`
  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,.12);
  padding-top: 9px;
  padding-bottom: 9px;
  background: ${(props) => getBackgroundColor(props.row || {}, props.theme)};

  :hover {
    box-shadow: ${(props) => (props.onRowClick ? 'inset 0 -1px 0 0 rgba(0,0,0,.22), 0 0 5px rgba(0,0,0,.2)' : 'inset 0 -1px 0 0 rgba(0,0,0,.12)')};
  }
`;

export const TableRow = Row.withComponent(({ row, onRowClick, ...restProps }) => (
  <Table.Row
    {...restProps}
    onClick={() => (onRowClick ? onRowClick(row) : null)}
    style={{ cursor: onRowClick ? 'pointer' : 'default' }}
  />
));

export const TableCell = (props) => <Table.Cell {...props} />;
