import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { FormattedMessage, useIntl } from 'react-intl'
import CheckboxOption from '../_form/select/CheckBoxOption'
import Select from '../_form/Select'
import { setColumns } from '../../actions/collectionActions'
import { getColumns } from '../../selectors/collectionSelectors'
import { sortByProperyAsc } from '../../utilities/arrayUtilities'
import theme from '../layout/theme'

const MultiSelectWrapper = styled('div')`
  position: relative;
  z-index: 25;
  display: inline-block;
  margin: 0 1rem 0 0;
  width: 300px;
`

const MultiSelectStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    height: '34x',
    width: '120px',
    boxShadow: 'none',
  }),
  input: (styles) => ({
    ...styles,
    color: theme.textColors.lightergray,
  }),
  multiValue: (styles) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    maxWidth: '300px',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: theme.textColors.lightergray,
  }),
}

const ColumnSelector = ({ collection, availableColumns }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')

  const columns = useSelector((state) => getColumns(state, collection))

  const columnOptions = availableColumns
    .sort((a, b) => sortByProperyAsc(a, b, 'label'))
    .map((attribute) => ({
      value: attribute.name || attribute.value,
      label: attribute.label,
    }))

  const selectedColumns = availableColumns
    .filter((attribute) => columns.includes(attribute.name || attribute.value))
    .map((attribute) => ({
      value: attribute.name || attribute.value,
      label: attribute.label,
    }))

  const changeColumns = (selectedColumns) => {
    //  set array of all selected columns with values only
    const newColumns = selectedColumns
      .map((column) => column.value)
      //  filter additional selected columns
      .sort((a, b) => {
        // If the column is not part of the previous columns, moved it to the end
        if (columns.indexOf(a) === -1) return 1
        return columns.indexOf(a) > columns.indexOf(b) ? 1 : -1
      })

    //  set original columns with additional columns
    dispatch(setColumns(collection, newColumns))
  }

  return (
    <MultiSelectWrapper>
      <Select
        isMulti
        getOptionLabel={(option) => formatMessage({ id: option.label })}
        components={{ Option: CheckboxOption }}
        value={selectedColumns}
        onChange={changeColumns}
        onInputChange={(value, { action }) => {
          // This allows keeping the input value after selecting a column, so it's
          // a lot easier to select multiple columns
          if (action !== 'set-value') setInputValue(value)
        }}
        inputValue={inputValue}
        options={columnOptions}
        placeholder={<FormattedMessage id="placeholder.columns" />}
        styles={MultiSelectStyles}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
      />
    </MultiSelectWrapper>
  )
}

ColumnSelector.propTypes = {
  availableColumns: PropTypes.array,
  collection: PropTypes.string.isRequired,
}

export default ColumnSelector
