import queryString from 'query-string';
import { get, post, remove, vdbsApi } from '../utilities/apiUtilities';

export const postDataTablesSearch = (params) => post({
  url: `${vdbsApi}/masterdata/search`,
  data: params
});

export const getDataTables = (params) => get({
  url: `${vdbsApi}/masterdata?${queryString.stringify({ limit: 25, offset: 0, ...params })}`
});

export const getDataTable = (tableId) => get({
  url: `${vdbsApi}/masterdata/${tableId}`
});

export const getDataTableVersions = (tableId) => get({
  url: `${vdbsApi}/masterdata/${tableId}/versions/active`
});

export const getDataTableVersion = (tableId, id) => get({
  url: `${vdbsApi}/masterdata/${tableId}/versions/${id}`
});

export const getDataTableAsofDate = (tableId, date) => get({
  url: `${vdbsApi}/masterdata/${tableId}/${date}`
});

export const postDataTable = (data) => post({
  url: `${vdbsApi}/masterdata`,
  data
});

export const postDataTableVersion = (tableId, data) => post({
  url: `${vdbsApi}/masterdata/${tableId}`,
  data
});

export const deleteDataTable = (tableId) => remove({
  url: `${vdbsApi}/masterdata/${tableId}`
});

export const postDataTableVersionApprove = (tableId, id) => post({
  url: `${vdbsApi}/masterdata/${tableId}/versions/${id}/approve`,
});

export const postDataTableVersionReject = (tableId, id, data) => post({
  url: `${vdbsApi}/masterdata/${tableId}/versions/${id}/reject`,
  data
});

export const postDataTableVersionRevoke = (tableId, id) => post({
  url: `${vdbsApi}/masterdata/${tableId}/versions/${id}/revoke`
});

export const postDataTableVersionAssign = (tableId, id, data) => post({
  url: `${vdbsApi}/masterdata/${tableId}/versions/${id}/review`,
  data
});
