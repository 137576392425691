/**
 * Based on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
 *
 * @param {object} data
 * @param {string} filename
 * @param {string} mime
 */
export const download = (data, filename, mime) => {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export const getFilenameFromHeaders = (response) => {
  const contentDisposition = response.headers['content-disposition'];
  let filename = 'download';
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename=(.+)/);
    if (filenameMatch && filenameMatch.length === 2) {
      const [, file] = filenameMatch;
      filename = file;
    }
  }

  return filename;
};

export const downloadAsPdf = (response) => download(response.data, getFilenameFromHeaders(response), 'application/pdf')
export const downloadAsWord = (response) => download(response.data, getFilenameFromHeaders(response), 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
