import { get, post, put, repositoryApi } from '../utilities/apiUtilities';

export const postUser = (data) => post({
  url: `${repositoryApi}/users`,
  data,
});

export const putUser = (data) => put({
  url: `${repositoryApi}/users`,
  data,
});

export const getUsers = (params) => get({
  url: `${repositoryApi}/users`,
  params,
});

export const getUser = (id) => get({
  url: `${repositoryApi}/users/${id}`,
});

export const getCurrentUser = () => get({
  url: `${repositoryApi}/user`,
});
