import csv from 'papaparse';
import { download } from './fileUtilities';
import { HAS_REGULATION } from '../constants/regulations';

export const csvOptions = {
  delimiter: ';',
  skipEmptyLines: true
};

/**
 * Adds a new column to the headers and all rows in a records
 * @param {object} records
 */
export const addColumn = (records) => {
  const { headers, data } = records;

  return {
    ...records,
    headers: [...headers, ''],
    data: data.map((row) => {
      row.push('');
      return row;
    })
  };
};

export const removeColumn = (records, index) => {
  const { headers, data } = records;

  return {
    ...records,
    headers: headers.filter((_header, i) => index !== i),
    data: data.map((rows) => rows.filter((_row, i) => index !== i))
  };
};

/**
 * Generates a CSV file for the given data table and triggers a file download
 * @param {object} dataTable
 */
export const downloadCsv = (dataTable) => {
  const data = [...dataTable.data];
  data.unshift(dataTable.headers);

  const content = csv.unparse(data, csvOptions);
  const filename = (dataTable.name || 'datatable').replace(/ /g, '').toLowerCase();

  download(content, `${filename}.csv`);
};

export const matchString = (value, searchString) => (
  typeof value === 'string'
  && value !== ''
  && searchString !== ''
  && value.toLowerCase().match(searchString) !== null
);

export const recordMatchesRegulation = (record, regulation) => (
  regulation && record[regulation] ? record[regulation].toUpperCase() === HAS_REGULATION : true
);
