import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { OPERATOR_EQUALS, OPERATOR_NOTEQUALS, OPERATOR_EXISTS, OPERATOR_NOTEXISTS } from '../../constants/operators';
import { removeCondition, updateCondition } from '../../actions/expressionActions';
import { withContext } from '../withContext';
import { conditionShape } from '../../shapes';
import Select from '../../../components/form/Select';
import Label from '../../../components/form/Label';
import { ButtonGroup } from '../../../components/layout/Buttons';
import RemoveButton from '../../../components/layout/RemoveButton';
import ConditionItem from '../../layout/ConditionItem';
import LabelSegment from '../../layout/LabelSegment';
import OperatorSegment from '../../layout/OperatorSegment';
import CriteriaSegment from '../../layout/CriteriaSegment';
import InputCriterium from '../../layout/InputCriterium';
import DataTableSelect from '../../../components/form/DataTableSelect';
import { INTERNAL_DATE } from '../../../constants/dateFormats';

class DataTableCondition extends Component {
  onOperatorChange = ({ value }) => {
    this.props.update({ ...this.props.condition, operator: value });
  }

  onValueChange = (event) => {
    this.props.update({ ...this.props.condition, value: event.value });
  }

  getOperators = () => [
    { value: OPERATOR_EQUALS, label: <FormattedMessage id="label.operator_eq" /> },
    { value: OPERATOR_NOTEQUALS, label: <FormattedMessage id="label.operator_neq" /> },
    { value: OPERATOR_EXISTS, label: <FormattedMessage id="label.operator_exists" /> },
    { value: OPERATOR_NOTEXISTS, label: <FormattedMessage id="label.operator_notexists" /> },
  ]

  render = () => {
    const { condition, field, remove } = this.props;

    return (
      <ConditionItem>
        <LabelSegment>
          <Label><FormattedMessage id={field.label} /></Label>
        </LabelSegment>
        <OperatorSegment>
          <Select
            value={this.getOperators().find((op) => op.value === (condition.operator || OPERATOR_EQUALS))}
            onChange={this.onOperatorChange}
            options={this.getOperators(condition.field)}
          />
        </OperatorSegment>
        <CriteriaSegment>
          <InputCriterium>
            {![OPERATOR_EXISTS, OPERATOR_NOTEXISTS].includes(condition.operator) && (
              <DataTableSelect
                tableId={field.reference}
                valueColumn={field.valueColumn}
                labelColumn={field.labelColumn}
                referenceDate={moment().format(INTERNAL_DATE)}
                onChange={this.onValueChange}
                value={condition.value}
              />
            )}
          </InputCriterium>
          <ButtonGroup>
            <RemoveButton onClick={() => remove(condition)} />
          </ButtonGroup>
        </CriteriaSegment>
      </ConditionItem>
    );
  }
}

DataTableCondition.propTypes = {
  condition: PropTypes.shape(conditionShape),
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  parent: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch, props) => ({
  remove: (condition) => dispatch(removeCondition(props.context.name, condition, props.parent, props.context.onSubmit)),
  update: (condition) => dispatch(updateCondition(props.context.name, condition)),
});

export default withContext(connect(null, mapDispatch)(DataTableCondition));
